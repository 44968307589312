import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './auth'
import error from './error'
import medicines from './medicines'

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    medicines,
    error,
  })

export default createRootReducer
