import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export default function RedirectDashboard() {
  const history = useHistory()

  const auth = useSelector(state => state.auth)
  let redirectTo

  useEffect(() => {
    const redirectPage = async () => {
      let redirectTo = '/'
      if (
        auth.session.roles[0] === 'patient' ||
        auth.session.roles[0] === 'responsible'
      )
        redirectTo = '/paciente'
      else if (auth.session.roles[0] === 'physician') redirectTo = '/medico'
      else if (auth.session.roles[0] === 'administrator') redirectTo = '/admin'
      history.push(redirectTo)
    }
    redirectPage()
  }, [])

  return null
}
