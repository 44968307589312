import * as Yup from 'yup'
import moment from 'moment'

function parseDateFromFormats(formats) {
  return this.transform(function(value, originalValue) {
    if (this.isType(value)) return value
    value = moment(originalValue, formats)

    return value.isValid() ? value.toDate() : null
  })
}

function removeSpecialCharacteres() {
  return this.transform(function(value, originalValue) {
    value = originalValue.replace(/[^a-zA-Z0-9]/gi, '')
    return value
  })
}

Yup.addMethod(Yup.date, 'format', parseDateFromFormats)
Yup.addMethod(Yup.mixed, 'removeSpecials', removeSpecialCharacteres)

export default Yup
