import React from 'react'

import { Container } from './styles'
import Button from '~/components/Button'

export default function Done({ next, data }) {
  return (
    <Container>
      <h5 className="mb-3">Paciente: {data.patient?.profile.full_name}</h5>
      <p className="text--rr text-center">
        Pronto! Agora a Dr. Cannabis cuidará do restante e fará o acolhimento do
        seu paciente. Fique tranquilo! Você poderá acompanhar o status desta
        prescrição aqui.
      </p>
    </Container>
  )
}
