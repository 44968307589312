import React, { useState } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom'

import { Container } from './styles'
import { PhysicianCard } from '~/pages/Admin/Physicians/PhysicianCard'

export default function PhysiciansList({ list, handlePageChange }) {
  const [loading] = useState(false)

  return (
    <Container>
      {loading && (
        <Col className="d-flex justify-content-center">
          <Spinner animation="grow" />
        </Col>
      )}
      {!loading &&
        list.data.map(physician => (
          <Link to={`/admin/medicos/${physician.id}`}>
            <PhysicianCard
              className="mt-3"
              phone
              email
              data={physician}
              shadow={true}
            />
          </Link>
        ))}
      <Row>
        <Col className="d-flex justify-content-center mt-3">
          <Pagination
            activePage={list.page}
            itemsCountPerPage={list.perPage}
            totalItemsCount={list.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </Col>
      </Row>
    </Container>
  )
}
