import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { Container, Info } from './styles'
import ProfileCard from '~/components/ProfileCard'
import Button from '~/components/Button'

export default function Profile() {
  const { session } = useSelector(state => state.auth)

  moment.locale('pt')

  return (
    <Container>
      <Row>
        <Col md={8}>
          <ProfileCard data={session} />
        </Col>
        <Col md={4}>
          <div className="d-flex h-100">
            <Link to="/medico/perfil/editar">
              <Button className="outline-primary sm align-self-center m-auto">
                Editar
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="my-5">
          <h2 className="text--rb">Informações pessoais</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Info>
            <p className="label">Nome completo *</p>
            <p className="data">{session.profile?.full_name}</p>
          </Info>
        </Col>
        {session.profile?.birthdate && (
          <Col md={6}>
            <Info>
              <p className="label">Data de Nascimento *</p>
              <p className="data">
                {moment(session.profile?.birthdate).format('DD/MM/YYYY')}
              </p>
            </Info>
          </Col>
        )}
        {session.profile?.gender && (
          <Col md={12}>
            <Info>
              <p className="label">Gênero</p>
              <p className="data">
                {session.profile?.gender === 'm' ? 'Masculino' : 'Feminino'}
              </p>
            </Info>
          </Col>
        )}
        {session.addresses.length > 0 && (
          <Col lg={12} className="my-5">
            <h2 className="text--rb">Endereço de Atendimento</h2>
          </Col>
        )}
        {session.addresses?.map(address => (
          <>
            <Col md={6}>
              <Info>
                <p className="label">UF *</p>
                <p className="data">{address.state}</p>
              </Info>
            </Col>
            <Col md={6}>
              <Info>
                <p className="label">Cidade *</p>
                <p className="data">{address.city}</p>
              </Info>
            </Col>
            <Col md={6}>
              <Info>
                <p className="label">CEP *</p>
                <p className="data">{address.cep}</p>
              </Info>
            </Col>
            <Col md={6}>
              <Info>
                <p className="label">Endereço</p>
                <p className="data">{address.street}</p>
              </Info>
            </Col>
            <Col md={6}>
              <Info>
                <p className="label">Número *</p>
                <p className="data">{address.number}</p>
              </Info>
            </Col>
            <Col md={6}>
              <Info>
                <p className="label">Complemento </p>
                <p className="data">{address.complement}</p>
              </Info>
            </Col>
          </>
        ))}
        {session.profile?.phone_number && (
          <Col md={6}>
            <Info>
              <p className="label">Tel residencial *</p>
              <p className="data">{session.profile?.phone_number}</p>
            </Info>
          </Col>
        )}
        {session.profile?.mobile_number && (
          <Col md={6}>
            <Info>
              <p className="label">Tel celular *</p>
              <p className="data">{session.profile?.mobile_number}</p>
            </Info>
          </Col>
        )}
        {session.profile?.rg && (
          <Col md={6}>
            <Info>
              <p className="label">RG *</p>
              <p className="data">{session.profile?.rg}</p>
            </Info>
          </Col>
        )}
        {session.profile?.cpf && (
          <Col md={6}>
            <Info>
              <p className="label">CPF *</p>
              <p className="data">{session.profile?.cpf}</p>
            </Info>
          </Col>
        )}
        <Col md={12}>
          <Info>
            <p className="label">Email </p>
            <p className="data">{session.email}</p>
          </Info>
        </Col>
      </Row>
    </Container>
  )
}
