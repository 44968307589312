/* eslint-disable no-undef */
import { createStore, compose, applyMiddleware } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'

import createRootReducer from './ducks'
import sagas from './sagas'

export const history = createBrowserHistory()

const middlewares = []

const sagaMiddleware = createSagaMiddleware()

middlewares.push(sagaMiddleware)
middlewares.push(routerMiddleware(history))

const reducer = createRootReducer(history)

const composer = compose(applyMiddleware(...middlewares))

const store = createStore(reducer, composer)

sagaMiddleware.run(sagas)

export default store
