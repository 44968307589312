import React, { useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import { Creators as ErrorActions } from '~/store/ducks/error'

import api from '~/services/api'
import Button from '~/components/Button'
import { Section } from './styles'
import { Input } from '~/components/Form'

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .min(4, 'A senha parece meio curta...')
    .required('Digite uma senha'),
  passwordConfirm: Yup.string()
    .test('passwords-match', 'As senhas não batem', function(value) {
      return this.parent.password === value
    })
    .required('Confirme sua senha'),
})

export default function ChangePassword({ location }) {
  const { register, handleSubmit, errors, setError } = useForm({
    validationSchema: SignupSchema,
  })
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const params = new URLSearchParams(location.search)
  const history = useHistory()

  const onSubmit = async data => {
    try {
      setLoading(true)

      await api.put(`/forgotpassword`, {
        token: params.get('token'),
        password: data.password,
      })
      toast.success('Senha alterada com sucesso!')
      history.push('/login')
      setLoading(false)
    } catch (e) {
      if (e.response.data.error.message)
        dispatch(ErrorActions.setError(e.response.data.error.message))
      else dispatch(ErrorActions.setError('Não foi possível trocar a senha.'))
      setLoading(false)
    }
  }

  return (
    <Section>
      <Container>
        <Row>
          <Col>
            <h1 className="text-center mb-4">Trocar/Criar senha</h1>
          </Col>
        </Row>
        <hr className="mb-5" />
        <Row className="justify-content-md-center">
          <Col lg={5} className="mb-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <Input
                    type="password"
                    label="Senha"
                    name="password"
                    placeholder="Senha"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.user?.password}
                    ref={register}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="password"
                    label="Confirma a senha"
                    name="passwordConfirm"
                    placeholder="Confirma a senha"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.passwordConfirm}
                    ref={register}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button className="primary md my-3" type="submit">
                    Enviar
                  </Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}
