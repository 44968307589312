import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  .avatar {
    width: 135px;
    height: 135px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  .desc {
    display: flex;
    align-items: center;
  }
`
