import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;

  @media only screen and (max-width: 767px) {
    padding: 2rem;
  }

  .text {
    line-height: 1.4;
    @media only screen and (max-width: 575px) {
      font-size: 0.9rem;
      line-height: 1.7;
    }
  }
`
