import axios from 'axios'
import { getToken } from './auth'

const url = process.env.REACT_APP_API
const debug = process.env.REACT_APP_API_DEBUG

const api = axios.create({
  baseURL: url,
})


const a = api.interceptors.request.use(async config => {
  let b = process.env.API_DEBUG
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  if (debug==1) {
    if (config.params) {
      config.params.XDEBUG_TRIGGER=1;
    } else {
      config.params={ XDEBUG_TRIGGER: 1 }
    }
  }
  return config
})

export default api
