import styled from 'styled-components'
import { Variables as V } from '~/styles/global'

export const Container = styled.div`
  padding: 2rem;
`

export const Brand = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  .avatar {
    width: 135px;
    height: 135px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  .desc {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .anchor {
    color: ${V.colorPrimary};
    transition: 0.2s;

    &:hover {
      color: darken(${V.colorPrimary}, 15);
      transform: translateX(5%);
      transition: 0.2s;
    }
  }

  .icon {
    width: 0.6rem;
    height: 0.6rem;
    fill: ${V.colorPrimary};
    transition: 0.2s;
    @media only screen and (min-width: 1440px) {
      width: 1rem;
      height: 1rem;
    }

    &:hover {
      fill: darken(${V.colorPrimary}, 15);
      transition: 0.2s;
    }
  }

  .active {
    color: green;
  }

  .inactive {
    color: red;
  }
`
