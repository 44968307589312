import React, { useState } from 'react'
import api from '~/services/api'

const phoneMask = phoneNumber => {
  let mask = phoneNumber.replace(/\D/g, '')
  mask = mask.replace(/^(\d{2})(\d)/g, '($1) $2')
  return mask.replace(/(\d)(\d{4})$/, '$1-$2')
}

const showValidatePassword = (pwd, repeat, Ok, Block) => {
  const numberRegex = /[0-9]/
  const especialRegex = /^(?=.*[@!#$%^&*()/\\])/
  const upperCaracter = /[A-Z]/
  let equality = false
  let number = false
  let passLen = false
  let especial = false
  let upperPwd = false
  if (pwd === repeat) {
    if (pwd.length > 0) {
      equality = true
    }
  }
  if (numberRegex.test(pwd)) {
    number = true
  }
  if (pwd.length >= 8) {
    passLen = true
  }
  if (especialRegex.test(pwd)) {
    especial = true
  }
  if (upperCaracter.test(pwd)) {
    upperPwd = true
  }

  return (
    <>
      <p className={equality ? 'text-info' : 'text-danger'}>
        {equality ? 'As senhas coincidem' : 'As senhas não coincidem'}
        <img
          src={equality ? Ok : Block}
          alt=""
          style={{ height: '17px' }}
          className="ml-1"
        />
      </p>
      <p className={`mt-1 ${number ? 'text-info' : 'text-danger'}`}>
        Senha deve conter ao menos um múmero
        <img
          src={number ? Ok : Block}
          alt=""
          style={{ height: '17px' }}
          className="ml-1"
        />
      </p>
      <p className={`mt-1 ${passLen ? 'text-info' : 'text-danger'}`}>
        A senha não tem 8 caracteres de comprimento
        <img
          src={passLen ? Ok : Block}
          alt=""
          style={{ height: '17px' }}
          className="ml-1"
        />
      </p>
      <p className={`mt-1 ${especial ? 'text-info' : 'text-danger'}`}>
        A senha deve contar ao menos um simbolo como: [ ]{}?/!@#$%*()^
        <img
          src={especial ? Ok : Block}
          alt=""
          style={{ height: '17px' }}
          className="ml-1"
        />
      </p>
      <p className={`mt-1 ${upperPwd ? 'text-info' : 'text-danger'}`}>
        A senha deve conter ao menos um caractere maiúsculo
        <img
          src={upperPwd ? Ok : Block}
          alt=""
          style={{ height: '17px' }}
          className="ml-1"
        />
      </p>
    </>
  )
}

const formatDate = date => {
  if (date) {
    const year = date.slice(0, 4)
    const month = date.slice(5, 7)
    const day = date.slice(8, 10)
    return `${day}/${month}/${year}`
  }
}

const formatText = (text, len) => {
  if (text) {
    return text.substr(0, len)
  }
}

const round = (num, places) => {
  return +parseFloat(num).toFixed(places)
}

const formatValue = (value, currency) => {
  switch (currency) {
    case 'R$':
      return parseFloat(value).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
    case 'US$':
      return parseFloat(value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    case '€':
      return parseFloat(value).toLocaleString('en-GB', {
        style: 'currency',
        currency: 'EUR',
      })
    default:
      return ''
  }
}

const transform = async src => {
  const imageBlob = await api.get(src, { responseType: 'blob' })

  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(imageBlob.data)
  })
}

const paginateItens = (items, pageActual, limitItems) => {
  let result = []
  let totalPage = Math.ceil(items.length / limitItems)
  let count = pageActual * limitItems - limitItems
  let delimiter = count + limitItems

  if (pageActual <= totalPage) {
    for (let i = count; i < delimiter; i++) {
      if (items[i] != null) {
        result.push(items[i])
      }
      count++
    }
  }

  return result
}


export {
  phoneMask,
  showValidatePassword,
  formatDate,
  formatText,
  round,
  formatValue,
  transform,
  paginateItens
}
