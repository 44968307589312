import styled from 'styled-components'

export const Container = styled.div`
  padding: 2.2rem;

  .text {
    font-size: 1.3rem;
    line-height: 1.4;
    @media only screen and (max-width: 575px) {
      font-size: 0.9rem;
      line-height: 1.7;
    }
  }
`
export const Info = styled.div`
  margin-bottom: 2rem;

  .label {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0.4rem;
  }
  .data {
    font-size: 14px;
  }
`
