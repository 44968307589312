import React, { useState, useEffect } from 'react'
import { Row, Col, ProgressBar } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { Creators as ErrorActions } from '~/store/ducks/error'
import { Creators as AuthActions } from '~/store/ducks/auth'

import Yup from '~/helpers/yup'
import api from '~/services/api'
import brasilapi from '~/services/brasilapi'
import UploadAvatar from '~/components/UploadAvatar'
import { Container } from './styles'
import { Input, Select } from '~/components/Form'
import Button from '~/components/Button'
import StatesBrasil from '~/helpers/StatesBrasil.json'

let parseFormats = ['DD/MM/YYYY']

const EditProfileSchema = Yup.object().shape({
  user: Yup.object().shape({
    email: Yup.string()
      .email('Digite um email válido')
      .required('Digite um email'),
  }),
  profile: Yup.object().shape({
    full_name: Yup.string().required('Digite seu nome.'),
    birthdate: Yup.date()
      .format(parseFormats)
      .max(new Date(), 'Você não pode ter nascido no futuro!')
      .typeError('Insira uma data Válida'),
    gender: Yup.string().required('Selecione seu gênero'),
    phone_number: Yup.string().removeSpecials(),
    mobile_number: Yup.string().removeSpecials(),
    rg: Yup.string().required('Digite seu RG'),
    cpf: Yup.string().required('Digite seu CPF'),
  }),
  address: Yup.object().shape({
    cep: Yup.string().required('Digite o CEP do seu endereço'),
    state: Yup.string().required('Digite o estado '),
    city: Yup.string().required('Digite a cidade '),
    street: Yup.string().required('Qual a rua?'),
    number: Yup.string(),
    complement: Yup.string(),
    district: Yup.string().required('Digite o bairro '),
  }),
})

export default function Edit({ history }) {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    validationSchema: EditProfileSchema,
  })
  // const error = useSelector(state => state.error)
  const { session } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const cep = watch('address.cep')

  const getAddressByCep = async () => {
    try{
      setLoading(true)
      const res = await brasilapi.get(`/api/cep/v1/${cep}`)
      if (!res.data.errors) {
        setValue('address.state', res.data.state)
        setValue('address.city', res.data.city)
        setValue('address.street', res.data.street)
        setValue('address.district', res.data.neighborhood)
      }
      setLoading(false)
    } catch(e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (cep && cep.length === 8) {
      getAddressByCep()
    }
  }, [cep])

  useEffect(() => {
    setValue('profile', session.profile)
    if (session.profile?.birthdate)
      setValue(
        'profile.birthdate',
        moment(session.profile?.birthdate).format('DD/MM/YYYY'),
      )
    setValue('address', session.addresses[0])
    setValue('user.email', session.email)
  }, [session.profile])

  const onSubmit = async data => {
    setLoading(true)
    try {
      await api.put(`/users/${session.id}`, data)
      dispatch(AuthActions.getSessionRequest())
      setLoading(false)
      const to = '/medico/perfil/medico/editar'
      history.push(to)
    } catch (e) {
      dispatch(ErrorActions.setError('Erro ao editar perfil.'))
      setLoading(false)
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="text--rb">Termine seu cadastro</h2>
        </Col>
      </Row>
      <div className="separator" />
      <Row>
        <Col>
          <h2 className="text--rb mb-3">Informações Pessoais</h2>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="m-2">
          <Col>
            <Row>
              <Col>
                <p className="text--rb">Adicione uma foto de perfil</p>
                <UploadAvatar />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Input
                  type="text"
                  label="Nome Completo"
                  name="profile.full_name"
                  placeholder="Nome"
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.profile?.full_name}
                  ref={register}
                />
              </Col>
              <Col lg={6}>
                <Input
                  type="text"
                  label="Data de nascimento"
                  name="profile.birthdate"
                  placeholder="__/__/____"
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.profile?.birthdate}
                  ref={register}
                  mask="99/99/9999"
                />
              </Col>
              <Col lg={4}>
                <Select
                  type="text"
                  label="Gênero"
                  name="profile.gender"
                  size="md"
                  icon="icon-chevron-down"
                  className="my-3"
                  error={errors.profile?.gender}
                  ref={register}
                  fluid
                >
                  <option value="m">Masculino</option>
                  <option value="f">Feminino</option>
                  <option value="-">Prefiro não informar</option>
                </Select>
              </Col>

              <Col lg={4}>
                <Input
                  type="text"
                  label="RG"
                  name="profile.rg"
                  placeholder="9999999"
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.profile?.rg}
                  ref={register}
                />
              </Col>

              <Col lg={4}>
                <Input
                  type="text"
                  label="CPF"
                  name="profile.cpf"
                  placeholder="123.456.789-10"
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.profile?.cpf}
                  mask="999.999.999-99"
                  ref={register}
                />
              </Col>

              <Col lg={6}>
                <Input
                  type="text"
                  label="Telefone pessoal"
                  name="profile.mobile_number"
                  placeholder="(11) 99999-9999"
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.profile?.mobile_number}
                  mask="(99) 99999-9999"
                  ref={register}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2 className="text--rb mt-4 mb-3">Informações para atendimento</h2>
          </Col>
        </Row>
        <Row className="m-2">
          <Col>
            <Row>
              <Col lg={6}>
                <Input
                  type="text"
                  label="Telefone para agendamento de consultas"
                  name="profile.phone_number"
                  placeholder="(11) 99999-9999"
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.profile?.phone_number}
                  mask="(99) 99999-9999"
                  ref={register}
                />
              </Col>

              <Col lg={6}>
                <Input
                  type="text"
                  label="CEP"
                  name="address.cep"
                  placeholder="00000-000"
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.adresss?.cep}
                  ref={register}
                />
              </Col>

              <Col lg={6}>
                <Select
                  type="text"
                  label="UF"
                  name="address.state"
                  size="md"
                  className="my-3"
                  error={errors.address?.state}
                  ref={register}
                  fluid
                >
                  {StatesBrasil.map(state => (
                    <option value={state.value}>{state.label}</option>
                  ))}
                </Select>
              </Col>

              <Col lg={6}>
                <Input
                  type="text"
                  label="Cidade"
                  name="address.city"
                  placeholder=""
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.address?.city}
                  ref={register}
                />
              </Col>

              <Col lg={6}>
                <Input
                  type="text"
                  label="Bairro"
                  name="address.district"
                  placeholder=""
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.address?.district}
                  ref={register}
                />
              </Col>

              <Col lg={8}>
                <Input
                  type="text"
                  label="Logradouro"
                  name="address.street"
                  placeholder=""
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.address?.street}
                  ref={register}
                />
              </Col>

              <Col lg={4}>
                <Input
                  type="text"
                  label="Número"
                  name="address.number"
                  placeholder=""
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.address?.number}
                  ref={register}
                />
              </Col>

              <Col lg={6}>
                <Input
                  type="text"
                  label="Complemento"
                  name="address.complement"
                  placeholder=""
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.address?.complement}
                  ref={register}
                />
              </Col>

              <Col lg={6}></Col>

              <Col lg={6}>
                <Input
                  type="text"
                  label="Email"
                  name="user.email"
                  placeholder=""
                  size="md"
                  fluid
                  className="mt-5 my-3"
                  error={errors.user?.email}
                  ref={register}
                  disabled
                />
              </Col>

              <Col xs={12}>
                <Button
                  type="submit"
                  disabled={loading}
                  className="primary md my-3"
                >
                  {loading ? 'Carregando...' : 'Continuar'}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </Container>
  )
}
