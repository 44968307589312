import React, { useState } from 'react'
import { transform } from '~/shared/utils'

const RenderImg = ({
  photo_id = '',
  photo_uuid,
  imgSecond,
  onclickImg,
  isPrivate = false,
  style,
  classNameImg = '',
  alt = '',
}) => {
  const [imgBase, setImgBase] = useState('')
  let url = ''
  url = `/files/${photo_id}`
  transform(url).then(r => setImgBase(r))

  return (
    <img
      src={!isPrivate ? photo_uuid : imgBase ? imgBase : imgSecond}
      className={classNameImg}
      alt={alt}
      style={style}
      onClick={onclickImg}
    />
  )
}

export { RenderImg }
