import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import api from '~/services/api'

const NewPassword = () => {
  const { session } = useSelector(state => state.auth)
  const [disable, setDisable] = useState(true)
  const [form, setForm] = useState({
    password: '',
    confirmPassword: '',
  })

  const changeInput = (e, key) => {
    const { value } = e.currentTarget
    setForm(prevState => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }

  useEffect(() => {
    if (form.password === form.confirmPassword && form.password !== '') {
      setDisable(false)
    }
  }, [form.password, form.confirmPassword])

  const changePwd = () => {
    api.put('/changepassword', {
      email: session.email,
      password: form.password,
    })
  }

  return (
    <div
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
      id="newPasswordModal"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header border-bottom-0 mt-1">
            <h3 className="my-3 text--rb">Alterar senha</h3>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="font-size-35 my-3" aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="card w-100 border-0">
                  <div className="card-body border-0">
                    <div className="form-group row">
                      <label
                        htmlFor="staticEmail"
                        className="col-3 col-form-label"
                      >
                        Email
                      </label>
                      <div className="col-9">
                        <input
                          type="text"
                          readOnly
                          className="form-control-plaintext"
                          id="staticEmail"
                          value={session.email}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="password"
                        className="col-3 col-form-label"
                      >
                        Password
                      </label>
                      <div className="col-9">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder="Password"
                          onChange={e => changeInput(e, 'password')}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="confirmPassword"
                        className="col-3 col-form-label"
                      >
                        Confirm Password
                      </label>
                      <div className="col-9">
                        <input
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          placeholder="Password"
                          onChange={e => changeInput(e, 'confirmPassword')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-center border-0">
                    <button
                      type="button"
                      className="btn btn-success"
                      disabled={disable}
                      onClick={() => changePwd()}
                    >
                      Alterar senha
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { NewPassword }
