import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { Patient, Physician, Admin } from './links'

import { Container } from './styles'
import Sprites from '~/assets/icons/sprites.svg'
import ProfileIcon from '~/assets/icons/profile.svg'
import { RenderImg } from '~/components/RenderImg/RenderImg'
export default function SideMenu() {
  const { session } = useSelector(state => state.auth)
  const [links, setLinks] = useState([])
  const location = useLocation()

  useEffect(() => {
    const role = session.roles[0]
    switch (role) {
      case 'patient':
        setLinks(Patient)
        break
      case 'responsible':
        setLinks(Patient)
        break
      case 'physician':
        setLinks(Physician)
        break
      case 'administrator':
        setLinks(Admin)
        break
    }
  })

  if (location.pathname.split('/').includes('template')) return null

  return (
    <Container className="d-flex flex-column">
      {session && session.profile && session.profile.avatar ? (
        <RenderImg
          isPrivate={session.profile?.avatar.private}
          photo_uuid={session.profile?.avatar.uuidUrl}
          photo_id={session.profile?.avatar.id}
          imgSecond={ProfileIcon}
          alt="Usuário"
          classNameImg="img align-self-center mb-3"
        />
      ) : (
        <img
          className="img align-self-center mb-3"
          src={ProfileIcon}
          alt="Usuário"
        />
      )}

      <h5 className="text text--rm text--black-dark align-self-center w-75 text-center mb-4">
        {session.profile?.full_name}
      </h5>
      <ul className="d-flex flex-column list-unstyled">
        {links.map(link => (
          <Link
            className={`anchor item  text--black-dark text-decoration-none ${location.pathname ===
              link.url && 'active'}`}
            to={link.url}
            key={link.url}
          >
            <li
              className={`d-flex align-items-center pl-5 ${location.pathname ===
                link.url && 'active'}`}
            >
              <i className={`fa fa-2x mr-2 ${link.icon}`} />
              {link.label}
            </li>
          </Link>
        ))}
      </ul>
    </Container>
  )
}
