import styled from 'styled-components'

import { Variables as V } from '~/styles/global'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const MedicineList = styled.div`
  .medicine {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .name {
    margin-left: 1rem;
    width: 400px;
  }

  .price {
    width: 100px;
    justify-content: center;
  }

  .quantity {
    border: 1px solid #000;
    border-radius: 5px;
    .sub {
      border-right: 1px solid #000;
      border-radius: 0;
    }
    .add {
      border-left: 1px solid #000;
      border-radius: 0;
    }
    span {
      margin: 0 1rem;
    }
  }

  .infos {
    flex: 1;
    .info {
      min-height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .quantity {
    display: flex;
    align-items: center;
    margin: 1rem;
  }

  .total {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    .label {
      margin-right: 1rem;
      width: 50px;
    }
    .value {
      width: 110px;
    }
  }
`

export const OrderDetails = styled.div`
  width: 100%;

  .card {
    cursor: pointer;
  }

  .address {
    cursor: pointer;
    height: 125px;

    p {
      height: 16px;
      overflow: hidden;
      word-break: break-all;
    }

    &.active {
      border: 2px solid #72c7b6 !important;
    }

    svg {
      width: 100%;
      height: 25px;
    }

    .anchor {
      color: ${V.colorPrimary};
      transition: 0.2s;

      &:hover {
        color: darken(${V.colorPrimary}, 15);
        transform: translateX(5%);
        transition: 0.2s;
      }
    }
  }
`
