import styled from 'styled-components'

import NotebookImg from '~/assets/images/sobrenos.jpg'
import NotebookMobileImg from '~/assets/images/sobrenos.jpg'
import RemedioImg from '~/assets/images/sobrenos2.jpg'
import RemedioMobileImg from '~/assets/images/sobrenos2.jpg'
import MulherInvertidaImg from '~/assets/images/sobrenos3.jpg'
import MulherInvertidaMobileImg from '~/assets/images/sobrenos3.jpg'
import VivianeImg from '~/assets/images/viviane.jpg'
import DraPaulaImg from '~/assets/images/drapaula.jpeg'

import { Variables as V } from '~/styles/global'

export const SectionPillars = styled.section`
  .title {
    @media only screen and (max-width: 575px) {
      font-size: 2.3rem;
    }
  }

  .text {
    line-height: 1.9;
    text-align: justify;
  }

  .subtitle {
    @media only screen and (max-width: 575px) {
      font-size: 1.2rem;
      color: ${V.colorBlackDark};
    }
  }

  .banner {
    &.notebook {
      padding: 5.7rem 0 6.5rem 0;
      background: linear-gradient(rgba(28, 41, 96, 0.5), rgba(28, 41, 96, 0.5))
          no-repeat,
        url(${NotebookImg}) 100% 50% no-repeat;
      background-size: cover;
      @media only screen and (max-width: 767px) {
        padding: 8rem 0 9rem 0;
        background: linear-gradient(
              rgba(28, 41, 96, 0.5),
              rgba(28, 41, 96, 0.5)
            )
            no-repeat,
          url(${NotebookMobileImg}) no-repeat;
        background-size: cover;
      }
    }

    &.medicine {
      padding: 10rem 0 6.5rem 0;
      background: linear-gradient(
            rgba(113, 170, 116, 0.3),
            rgba(113, 170, 116, 0.3)
          )
          no-repeat,
        url(${RemedioImg}) 100% 30% no-repeat;
      background-size: cover;
      @media only screen and (max-width: 767px) {
        padding: 10rem 0 12rem 0;
        background: linear-gradient(
              rgba(113, 170, 116, 0.3),
              rgba(113, 170, 116, 0.3)
            )
            no-repeat,
          url(${RemedioMobileImg}) 100% 30% no-repeat;
        background-size: cover;
      }
    }

    &.woman {
      padding: 5rem 0 9rem 0;
      background: linear-gradient(rgba(28, 41, 96, 0.5), rgba(28, 41, 96, 0.5))
          no-repeat,
        url(${MulherInvertidaImg}) 100% 22% no-repeat;
      background-size: cover;
      @media only screen and (max-width: 767px) {
        padding: 10rem 0 11rem 0;
        background: linear-gradient(
              rgba(28, 41, 96, 0.5),
              rgba(28, 41, 96, 0.5)
            )
            no-repeat,
          url(${MulherInvertidaMobileImg}) 100% 22% no-repeat;
        background-size: cover;
      }
    }

    .title {
      @media only screen and (min-width: 1440px) {
        font-size: 2.5rem;
      }
      @media only screen and (max-width: 575px) {
        font-size: 2.31rem;
      }
    }

    .text {
      line-height: 2rem;
      @media only screen and (min-width: 1440px) {
        font-size: 1.6rem;
        line-height: 2.5rem;
      }
      @media only screen and (max-width: 575px) {
        font-size: 1rem;
      }
    }
  }
`
export const SectionCreators = styled.section`
  .subtitle {
    @media only screen and (max-width: 575px) {
      font-size: 1.6rem;
      color: ${V.colorBlackDark};
    }
  }

  .card-height {
    height: 560px;
  }

  .card {
    padding: 3.7rem 2.2rem 8.8rem 2.2rem;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    @media only screen and (max-width: 575px) {
      padding: 2.7rem 1.5rem 3.8rem 1.5rem;
    }

    .img {
      width: 8.6rem;
      height: 8.6rem;
      border: 7px solid ${V.colorPrimary};
      border-radius: 5rem;
      @media only screen and (max-width: 575px) {
        width: 6rem;
        height: 6rem;
      }

      &.viviane {
        background: url(${VivianeImg}) 40% 100% no-repeat;
        background-size: cover;
      }

      &.filipe {
        background: url(${DraPaulaImg}) 40% 100% no-repeat;
        background-size: cover;
      }
    }

    .title {
      @media only screen and (min-width: 1440px) {
        font-size: 2rem;
      }
      @media only screen and (max-width: 575px) {
        font-size: 1.1rem;
        color: ${V.colorBlackDark};
      }
    }

    .text {
      font-size: 1.1rem;
      line-height: 2rem;
      @media only screen and (min-width: 1440px) {
        font-size: 1.6rem;
      }
      @media only screen and (max-width: 575px) {
        font-size: 0.9rem;
        color: ${V.colorBlackDark};
      }
    }
  }
`
