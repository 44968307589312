import pubmed from '~/services/pubmed'

export default async function Parser(articlesId) {
  let ids = articlesId.data.map(artid => artid.pubmed_id).join(',')
  const res = await pubmed.get(
    `/entrez/eutils/efetch.fcgi?db=pubmed&retmode=xml&id=${ids}`,
  )

  const oParser = new DOMParser()
  const oDOM = oParser.parseFromString(res.data, 'application/xml')
  let PubMedArticles = oDOM.getElementsByTagName('PubmedArticle')
  PubMedArticles = Array.from(PubMedArticles)
  const parsed = PubMedArticles.map((article, idx) => {
    const data = {}
    data.id = articlesId.data[idx].id
    data.pubmed_id = articlesId.data[idx].pubmed_id
    data.ArticleTitle = article.getElementsByTagName(
      'ArticleTitle',
    )[0]?.innerHTML
    data.AbstractText = article.getElementsByTagName(
      'AbstractText',
    )[0]?.innerHTML
    const ArticleDateEl = article.getElementsByTagName('ArticleDate')
    data.ArticleDate = `${ArticleDateEl[0]?.children[2]?.innerHTML}/${ArticleDateEl[0]?.children[1]?.innerHTML}/${ArticleDateEl[0]?.children[0]?.innerHTML}`
    const authorsEl = Array.from(article.getElementsByTagName('AuthorList'))
    data.Authors = authorsEl.map(author => {
      return `${author.getElementsByTagName('LastName')[0].innerHTML}, ${
        author.getElementsByTagName('Initials')[0].innerHTML
      }`
    })
    return data
  })

  return parsed
}
