import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;

  .file {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    img {
      width: 60px;
    }
    p {
      margin-top: 0.5rem;
      text-align: center;
    }
  }
`

export const Medicine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .thumb {
    margin: 1rem;
  }

  .name {
  }

  .quantity {
    display: flex;
    align-items: center;
    margin: 1rem;
  }
`
