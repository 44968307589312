import React, { useEffect, useState } from 'react'
import { Row, Col, Spinner, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { Container } from './styles'
import Patient from '../Patients/PatientCard'
import api from '~/services/api'
import Article from '~/components/Article'
import ParserPubMed from '~/helpers/ParserPubMed'
import { useSelector } from 'react-redux'

export default function Home() {
  const [loadingArticles, setLoadingArticles] = useState(true)
  const [loadingPatients, setLoadingPatients] = useState(true)
  const [patients, setPatients] = useState([])
  const [articlesId, setArticlesId] = useState()
  const [articles, setArticles] = useState([])
  const { session } = useSelector(state => state.auth)


  const getArticlesId = async () => {
    setLoadingArticles(true)
    const res = await api.get('/articles')
    setArticlesId(res.data)
    setLoadingArticles(false)
  }

  const getArticles = async () => {
    setLoadingArticles(true)

    if (articlesId) {
      const parsed = await ParserPubMed(articlesId)
      setArticles(parsed)
    }
    setLoadingArticles(false)
  }

  const getPatients = async () => {
    setLoadingPatients(true)
    const res = await api.get('/patients/list')
    setPatients(res.data)
    setLoadingPatients(false)
  }

  useEffect(() => {
    getArticlesId()
    getPatients()
  }, [])

  useEffect(() => {
    getArticles()
  }, [articlesId])

  return (
    <Container>
      {patients.length > 0 && (
        <Row>
          <Col>
            <h2 className="text--rm">Últimos Pacientes</h2>
          </Col>
        </Row>
      )}
      <Row>
        {loadingPatients && (
          <Col className="d-flex justify-content-center">
            <Spinner animation="grow" />
          </Col>
        )}
        {patients.length > 0 &&
          patients.slice(0, 3).map(patient => (
            <Col md={4} sm={12} className="mt-2 mb-4" key={patient.id}>
              <Link to={`/medico/pacientes/${patient.id}`}>
                <Patient data={patient} />
              </Link>
            </Col>
          ))}
      </Row>
      <Row>
        <Col>
          <h2 className="text--rm mb-3">Últimos Artigos</h2>
        </Col>
      </Row>
      <Row>
        {loadingArticles && (
          <Col lg={12} className="d-flex justify-content-center">
            <Spinner animation="grow" />
          </Col>
        )}
        <Col>
          {articles.length > 0 &&
            articles
              .slice(0, 2)
              .map(article => <Article article={article} key={article.id} />)}
        </Col>
      </Row>
    </Container>
  )
}
