import React from 'react'
import NumberFormat from 'react-number-format'

import { Container } from './styles'
import PatientImg from '~/assets/images/physician-default.png'
import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function Patient({ data, ...rest }) {
  const profile = data.profile

  return (
    <Container {...rest}>
      <div className="avatar">
        {profile.avatar ? (
          <RenderImg
            photo_id={profile?.avatar?.id}
            photo_uuid={profile?.avatar?.uuidUrl}
            isPrivate={profile?.avatar.private}
            alt="Avatar"
          />
        ) : (
          <img src={PatientImg} alt="Avatar" />
        )}
      </div>
      <div className="desc">
        <div className="d-flex flex-column justify-content-between h-100 p-3">
          <h5 className="text--rm">
            {profile?.full_name}
          </h5>
          {data.ward?.length ? <span class={"text-info"}>Responsável</span> : ''}
          <p>{data.email}</p>
          <p>
            <NumberFormat
              value={profile?.phone_number}
              displayType={'text'}
              format="(##) #####-####"
            />{' '}
            <NumberFormat
              value={profile?.mobile_number}
              displayType={'text'}
              format="(##) #####-####"
            />
          </p>
        </div>
      </div>
    </Container>
  )
}
