import styled from 'styled-components'

export const MedicineList = styled.div`
  .medicine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;
    padding: 0.3rem 0;

    &:first-child {
      border-top-width: 0;
    }

    &:last-child {
      border-bottom-width: 0;
    }
  }

  .name {
    width: 400px;
  }

  .quantity {
    display: flex;
    align-items: center;
    margin: 1rem;

    input {
      width: 35px !important;
      padding-right: 0 !important;
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      border-radius: 0 !important;
    }
  }
`
