import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import api from '~/services/api'
import { Physician, PhysicianList } from './styles'
import Button from '~/components/Button'
import Thumb from '~/components/Thumb'
import { Input, Select } from '~/components/Form'
import PhotoProfile from '~/assets/images/foto_perfil.svg'
import StatesBrasil from '~/helpers/StatesBrasil.json'
import Specialties from '~/helpers/Specialties.json'
import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function SelectPhysician(props) {
  const { prescriptionId, onHide } = props
  const { register, formState, handleSubmit, watch, reset } = useForm()
  const [prescription, setPrescription] = useState({})
  const [formVisible, setFormVisible] = useState(false)
  const [physicians, setPhysicians] = useState([])
  const { uf, crm, name } = watch(['uf', 'crm', 'name'])
  const dirty = formState.dirty

  const fetchPrescriptions = async () => {
    const res = await api.get(`/prescriptions/${prescriptionId}`)
    setPrescription(res.data)
  }

  // useEffect(() => {
  //   fetchPrescriptions()
  // }, [])

  useEffect(() => {
    const fetchPhysicians = async () => {
      const res = await api.get(
        `/physicians?uf=${uf ?? ''}&crm=${crm ?? ''}&name=${name ?? ''}`,
      )
      setPhysicians(res.data)
    }
    if ((uf && crm) || name) fetchPhysicians()
  }, [uf, crm, name])

  useEffect(() => {
    if (prescription.prePhysician) {
      setFormVisible(true)
      reset({ profile: prescription.prePhysician })
    }
  }, [prescription])

  const selectPhysician = async id => {
    const data = {
      physician_id: id,
    }
    await api.put(`/prescriptions/${prescriptionId}`, data)
    await fetchPrescriptions()
    onHide()
  }

  const onSubmit = async data => {
    if (prescription.prePhysician) {
      await api.put(
        `/preprofiles/${prescription.prePhysician.id}`,
        data.profile,
      )
    } else {
      await api.post(`/preprofiles`, {
        prescription_id: prescriptionId,
        ...data.profile,
      })
    }
    fetchPrescriptions()
    onHide()
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Informações do Médico
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div style={{ display: !formVisible ? 'block' : 'none' }}>
            <form>
              <Row>
                <Col md={2}>
                  <Select
                    name="uf"
                    label="UF"
                    icon="icon-chevron-down"
                    size="md"
                    fluid
                    ref={register}
                  >
                    <option value="">UF</option>
                    {StatesBrasil.map(state => (
                      <option value={state.value}>{state.label}</option>
                    ))}
                  </Select>
                </Col>
                <Col md={3}>
                  <Input
                    type="text"
                    name="crm"
                    label="CRM"
                    size="md"
                    fluid
                    ref={register}
                  />
                </Col>
                <Col>
                  <Input
                    type="text"
                    name="name"
                    label="Nome do médico"
                    size="md"
                    fluid
                    ref={register}
                  />
                </Col>
              </Row>
            </form>
            {prescription.physician && (
              <Row>
                <Col className="d-flex justify-content-center">
                  <Physician>
                    <div className="thumb">
                      <Thumb>
                        <div className="inner">
                          {prescription.physician?.profile?.avatar && (
                            <RenderImg
                              photo_id={
                                prescription.physician?.profile?.avatar?.id
                              }
                              photo_uuid={
                                prescription.physician?.profile?.avatar?.uuidUrl
                              }
                              isPrivate={
                                prescription.physician?.profile?.avatar?.private
                              }
                            />
                          )}
                          {!prescription.physician?.profile?.avatar && (
                            <img src={PhotoProfile} />
                          )}
                        </div>
                      </Thumb>
                    </div>
                    <div className="name">
                      {prescription.physician?.profile.full_name}
                    </div>
                  </Physician>
                </Col>
              </Row>
            )}
            <hr className="separator" />
            <PhysicianList>
              <Row>
                {physicians.data?.map(physician => (
                  <Col lg={6}>
                    <div
                      className="physician"
                      key={physician.id}
                      onClick={() => selectPhysician(physician.user.id)}
                    >
                      <div className="thumb">
                        <Thumb>
                          <div className="inner">
                            {physician.user?.profile?.avatar && (
                              <img src={physician.user?.profile?.avatar?.url} />
                            )}
                            {!physician.user?.profile?.avatar && (
                              <img src={PhotoProfile} />
                            )}
                          </div>
                        </Thumb>
                      </div>
                      <div>
                        <div className="name">
                          {physician.user?.profile?.full_name}
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </PhysicianList>
            {!physicians.data?.length && dirty && (
              <Row>
                <Col>
                  <p>Ops... Parece que esse médico não está na Dr. Cannabis.</p>
                  <p>
                    Clique no botão abaixo e informe os dados do profissional
                    para continuar.
                  </p>
                  <Button
                    className="primary mt-2"
                    onClick={() => setFormVisible(true)}
                  >
                    Cadastrar médico
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </>
        <form
          style={{ display: formVisible ? 'block' : 'none' }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col md={2}>
              <Select
                name="profile.uf"
                label="UF"
                className="mb-3"
                icon="icon-chevron-down"
                size="md"
                fluid
                ref={register}
              >
                {StatesBrasil.map(state => (
                  <option value={state.value}>{state.label}</option>
                ))}
              </Select>
            </Col>
            <Col md={3}>
              <Input
                type="text"
                name="profile.crm"
                label="CRM"
                className="mb-3"
                size="md"
                fluid
                ref={register}
              />
            </Col>
            <Col md={7}>
              <Input
                type="text"
                name="profile.full_name"
                label="Nome do médico"
                className="mb-3"
                size="md"
                fluid
                ref={register}
              />
            </Col>
            <Col md={6}>
              <Input
                type="text"
                name="profile.email"
                label="Email do médico"
                className="mb-3"
                size="md"
                fluid
                ref={register}
              />
            </Col>
            <Col md={3}>
              <Select
                name="profile.gender"
                label="Gênero"
                className="mb-3"
                icon="icon-chevron-down"
                size="md"
                fluid
                ref={register}
              >
                <option value="m">Masculino</option>
                <option value="f">Femino</option>
                <option value="-">Prefiro não informar</option>
              </Select>
            </Col>
            <Col md={4}>
              <Input
                type="text"
                name="profile.mobile_number"
                label="Celular"
                className="mb-3"
                size="md"
                fluid
                ref={register}
                mask="(99) 99999-9999"
              />
            </Col>
            <Col md={4}>
              <Input
                type="text"
                name="profile.phone_number"
                label="Telefone Fixo"
                className="mb-3"
                size="md"
                fluid
                ref={register}
                mask="(99) 99999-9999"
              />
            </Col>
            <Col md={4}>
              <Select
                name="profile.specialty"
                label="Especialidade"
                className="mb-3"
                size="md"
                fluid
                ref={register}
              >
                {Specialties.map(specialty => (
                  <option value={specialty}>{specialty}</option>
                ))}
              </Select>
            </Col>
            <Col md={12}>
              <Button className="primary float-right">Enviar</Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  )
}
