import styled from 'styled-components'
import { Variables as V } from '~/styles/global'

export const Container = styled.div`
  padding: 2rem;
`

export const Product = styled.div`
  width: 100%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 575px) {
    width: 60%;
  }

  img {
    width: 100%;
    height: 14rem;
    object-fit: cover;

    @media only screen and (min-width: 1440px) {
      height: 16rem;
    }
    @media only screen and (max-width: 575px) {
      height: auto;
    }
  }

  .anchor {
    color: ${V.colorPrimary};
    transition: 0.2s;

    &:hover {
      color: darken(${V.colorPrimary}, 15);
      transform: translateX(5%);
      transition: 0.2s;
    }
  }

  .icon {
    width: 0.6rem;
    height: 0.6rem;
    fill: ${V.colorPrimary};
    transition: 0.2s;
    @media only screen and (min-width: 1440px) {
      width: 1rem;
      height: 1rem;
    }

    &:hover {
      fill: darken(${V.colorPrimary}, 15);
      transition: 0.2s;
    }
  }
`
