import React from 'react'

import { Container, LoginButton } from './styles'
import { Dropdown } from 'react-bootstrap'
import Button from '~/components/Button'
import { Link } from 'react-router-dom'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <LoginButton
    href=""
    className="primary"
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
  </LoginButton>
))

export default function Login() {
  return (
    <Dropdown alignRight>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        Entrar
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Container>
          <Link to="/login">
            <Button className="outline-primary sm">Entrar</Button>
          </Link>
          <Link to="/cadastro" className="mt-3">
            <Button className="link">É novo por aqui? Cadastre-se</Button>
          </Link>
        </Container>
      </Dropdown.Menu>
    </Dropdown>
  )
}
