import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import { Container } from './styles'
import UploadImage from '../../../../components/UploadImage'
import { Checkbox, Input, Select, TextArea } from '~/components/Form'
import api from '~/services/api'
import Button from '~/components/Button'

export default function NewMedicine() {
  const { register, handleSubmit, errors, setValue } = useForm()
  const [loading, setLoading] = useState(false)
  const [brands, setBrands] = useState([])
  const [imageId, setImageId] = useState()
  const [leafletId, setLeafletId] = useState()
  const { id } = useParams()
  const history = useHistory()

  const getBrands = async () => {
    setLoading(true)
    const res = await api.get('/brands')
    setBrands(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getBrands()
  }, [])

  const getData = async medicineId => {
    setLoading(true)
    await getBrands()
    const res = await api.get(`/medicines/${medicineId}`)
    setValue('medicine', res.data)
    setLoading(false)
  }

  useEffect(() => {
    if (id) getData(id)
  }, [id])

  const onSubmit = async data => {
    setLoading(true)
    const medicine = {
      photo_id: imageId,
      leaflet_id: leafletId,
      ...data.medicine,
      active: data.medicine.active === 'true' ? true : false,
    }
    if (id) await api.put(`/medicines/${id}`, medicine)
    else await api.post('/medicines', medicine)
    setLoading(false)
    history.push('/admin/produtos')
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <h3>Imagem do Produto</h3>
            <UploadImage callback={setImageId} isPrivate={false} />
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Select
              label="Status"
              name="medicine.active"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.active}
              ref={register}
            >
              <option value={true}>Ativo</option>
              <option value={false}>Inativo</option>
            </Select>
          </Col>
          <Col lg={3}>
            <Input
              type="number"
              label="Ordem"
              name="medicine.priority"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.priority}
              ref={register}
            />
          </Col>
          <Col lg={6} className="d-flex align-self-center pt-4">
            <Checkbox
              className=""
              name="medicine.is_controlled"
              label="Controlado"
              ref={register}
            />
            <Checkbox
              className=""
              name="medicine.show_calculator"
              label="Exibir calculadora"
              ref={register}
            />
          </Col>
          <Col lg={6}>
            <Input
              type="text"
              label="Nome do Produto"
              name="medicine.name"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.name}
              ref={register}
            />
          </Col>
          <Col lg={6}>
            <Input
              type="text"
              label="Nome Comercial"
              name="medicine.comercial"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.comercial}
              ref={register}
            />
          </Col>
          <Col lg={6}>
            <Select
              type="text"
              label="Marca"
              name="medicine.brand_id"
              size="md"
              icon="icon-chevron-down"
              className="my-3"
              error={errors.medicine?.brand_id}
              ref={register}
              fluid
            >
              {brands.map(brand => (
                <option value={brand.id}>{brand.name}</option>
              ))}
            </Select>
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="CBD mg"
              name="medicine.cbdmg"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.cbdmg}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="CBD %"
              name="medicine.cbdp"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.cbdp}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="CBC mg"
              name="medicine.cbcmg"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.cbcmg}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="CBC %"
              name="medicine.cbcp"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.cbcp}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="THC mg"
              name="medicine.thcmg"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.thcmg}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="THC %"
              name="medicine.thcp"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.thcp}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="CBN mg"
              name="medicine.cbnmg"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.cbnmg}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="CBN %"
              name="medicine.cbnp"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.cbnp}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="CBG mg"
              name="medicine.cbgmg"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.cbgmg}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="CBG %"
              name="medicine.cbgp"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.cbgp}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Select
              type="text"
              label="Espectro"
              name="medicine.spectrum"
              size="md"
              icon="icon-chevron-down"
              className="my-3"
              error={errors.medicine?.type}
              ref={register}
              fluid
            >
              <option value="Full Spectrum">Full Spectrum</option>
              <option value="Isolado">Isolado</option>
              <option value="Broad Spectrum">Broad Spectrum</option>
            </Select>
          </Col>
          <Col lg={3}>
            <Select
              type="text"
              label="Apresentação / Tipo"
              name="medicine.type"
              size="md"
              icon="icon-chevron-down"
              className="my-3"
              error={errors.medicine?.type}
              ref={register}
              fluid
            >
              <option value="Óleo">Óleo</option>
              <option value="Spray">Spray</option>
              <option value="Cápsula">Cápsula</option>
              <option value="Tópico">Tópico</option>
              <option value="Pasta">Pasta</option>
              <option value="Outro">Outro</option>
            </Select>
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="Conteúdo (ml)"
              name="medicine.content"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.content}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Select
              label="Tipo do conteúdo"
              name="medicine.content_type"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.content_type}
              ref={register}
            >
              <option value="ml">ml</option>
              <option value="capsule">Cápsula</option>
              <option value="g">Gramas</option>
            </Select>
          </Col>
          <Col lg={12}>
            <TextArea
              rows="5"
              label="Composição"
              name="medicine.composition"
              fluid
              className="my-3"
              error={errors.medicine?.composition}
              ref={register}
            />
          </Col>
          <Col lg={12}>
            <TextArea
              rows="5"
              label="Uso"
              name="medicine.use"
              fluid
              className="my-3"
              error={errors.medicine?.use}
              ref={register}
            />
          </Col>
          <Col lg={12}>
            <TextArea
              rows="5"
              label="Descrição"
              name="medicine.description"
              fluid
              className="my-3"
              error={errors.medicine?.description}
              ref={register}
            />
          </Col>
          <Col lg={12}>
            <Input
              type="text"
              label="Apresentação / Texto"
              name="medicine.presentation"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.presentation}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="Preço"
              name="medicine.price"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.price}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="Preço Anterior"
              name="medicine.price_previous"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.price_previous}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="Moeda"
              name="medicine.currency"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.currency}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="Frete"
              name="medicine.shipping"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.shipping}
              ref={register}
            />
          </Col>
          <Col lg={6}>
            <h3>Bula</h3>
            <UploadImage callback={setLeafletId} isPrivate={false} />
          </Col>
          <Col lg={12} className="mt-3">
            <Input
              type="text"
              label="Link personalizado"
              name="medicine.links"
              placeholder="http://www.mylink.com"
              size="md"
              fluid
              className="my-3"
              error={errors.medicine?.link}
              ref={register}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 d-flex flex-row-reverse">
            <Button className="primary md" disabled={loading} type="submit">
              Salvar
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  )
}
