import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'
import { toast } from 'react-toastify'

export const { Types, Creators } = createActions({
  setError: ['message', 'body'],
  hideError: [],
})

const INITIAL_STATE = Immutable({
  visible: false,
  message: '',
  body: {},
})

const setError = (state = INITIAL_STATE, action) => {
  if (action.message)
    toast.error(
      typeof action.message === 'string'
        ? action.message
        : 'Ops, algo deu errado...',
    )
  return {
    ...state,
    visible: true,
    body: action.body,
    message: action.message,
  }
}

const hideError = (state = INITIAL_STATE) => ({
  ...state,
  visible: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.SET_ERROR]: setError,
  [Types.HIDE_ERROR]: hideError,
})
