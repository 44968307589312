import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useParams, Link } from 'react-router-dom'
import moment from 'moment'
import NumberFormat from 'react-number-format'

import { Container } from './styles'
import ProfileCard from '~/components/ProfileCard'
import Button from '~/components/Button/index'
import { useEffect } from 'react'
import api from '~/services/api'
import PrescriptionItem from '../Prescriptions/PrescriptionItem'
import { transform } from '~/shared/utils'
import pdfIcon from '~/assets/icons/pdfIcon.png'

export default function Patient() {
  const { id } = useParams()
  const [patient, setPatient] = useState({})
  const [url, setUrl] = useState('')
  const [type, setType] = useState('')
  const [nameFile, setNameFile] = useState('')
  const [prescriptions, setPrescriptions] = useState([])
  const [loading, setLoading] = useState(false)

  const getPrescriptions = async () => {
    const res = await api.get(`/prescriptions?patient=${id}`)
    setPrescriptions(res.data)
  }

  const getPatient = async patientId => {
    setLoading(true)
    const res = await api.get(`/patients/${patientId}`)
    setPatient(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getPatient(id)
    getPrescriptions()
  }, [id])

  const getUrl = async (fileId, fileType, fileName) => {
    setType(fileType)
    setNameFile(fileName)
    await transform(`/files/${fileId}`).then(r => setUrl(r))
  }

  useEffect(() => {
    if (url) {
      let a = document.createElement('a')
      a.href = url
      a.download = `${nameFile}.${type === 'application' ? 'pdf' : type}`
      a.click()
      setUrl('')
    }
  }, [url])

  const profile = patient.profile
  const ward = patient.wards?.length ? patient.wards[0] : {}

  return (
    <Container>
      <Row>
        <Col>
          <Button>Voltar</Button>
        </Col>
      </Row>
      <Row>
        <Col lg={8} className="my-4">
          <ProfileCard data={patient} />
        </Col>
        <Col>
          <Link to={`/admin/pacientes/editar/${patient.id}`}>
            <Button className="primary">Editar</Button>
          </Link>
        </Col>
      </Row>
      <Row>
        {profile?.birthdate && (
          <Col lg={6} className="mb-4">
            <h5>Data de Nascimento</h5>
            <p>{moment(profile?.birthdate).format('DD/MM/YYYY')}</p>
          </Col>
        )}
        {profile?.rg && (
          <Col lg={6} className="mb-4">
            <h5>RG</h5>
            <p>{profile?.rg}</p>
          </Col>
        )}
        {profile?.cpf && (
          <Col lg={6} className="mb-4">
            <h5>CPF</h5>
            <p>
              <NumberFormat
                value={profile?.cpf}
                displayType={'text'}
                format="###.###.###-##"
              />
            </p>
          </Col>
        )}

        <Col lg={6} className="mb-4">
          <h5>Contato</h5>
          {profile?.mobile_number && (
            <p>
              <NumberFormat
                value={profile?.mobile_number}
                displayType={'text'}
                format="(##) #####-####"
              />
            </p>
          )}
          {profile?.phone_number && (
            <p>
              <NumberFormat
                value={profile?.phone_number}
                displayType={'text'}
                format="(##) ####-####"
              />
            </p>
          )}
          <p className="my-2">{patient.email}</p>
        </Col>
      </Row>
      {ward.profile && (
        <Row>
          <Col className="my-3">
            <h4>Dados do paciente</h4>
          </Col>
          {ward.profile?.full_name && (
            <Col lg={6} className="mb-4">
              <h5>Nome do paciente</h5>
              <p>{ward.profile.full_name}</p>
            </Col>
          )}
          {ward.profile?.birthdate && (
            <Col lg={6} className="mb-4">
              <h5>Data de Nascimento</h5>
              <p>{moment(ward.profile?.birthdate).format('DD/MM/YYYY')}</p>
            </Col>
          )}
          {ward.profile?.rg && (
            <Col lg={6} className="mb-4">
              <h5>RG</h5>
              <p>{ward.profile?.rg}</p>
            </Col>
          )}
          {ward.profile?.cpf && (
            <Col lg={6} className="mb-4">
              <h5>CPF</h5>
              <p>
                <NumberFormat
                  value={ward.profile?.cpf}
                  displayType={'text'}
                  format="###.###.###-##"
                />
              </p>
            </Col>
          )}
          {ward.profile?.mobile_number && (
            <Col lg={6} className="mb-4">
              <h5>Contato</h5>
              <p>
                <NumberFormat
                  value={ward.profile?.mobile_number}
                  displayType={'text'}
                  format="(##) #####-####"
                />
              </p>
            </Col>
          )}
        </Row>
      )}
      {prescriptions?.length > 0 && (
        <Row>
          <Col className="my-3">
            <h4 className="text--rm">Prescrições</h4>
          </Col>
        </Row>
      )}
      {!loading &&
        patient.addresses?.map(address => (
          <Row key={address.id}>
            <Col lg={6} className="mb-4">
              <h5>Estado</h5>
              <p>{address.state}</p>
            </Col>
            <Col lg={6} className="mb-4">
              <h5>Cidade</h5>
              <p>{address.city}</p>
            </Col>
            <Col lg={6} className="mb-4">
              <h5>Logradouro</h5>
              <p>
                {address.street}, {address.number}
              </p>
            </Col>
            <Col lg={6} className="mb-4">
              <h5>CEP</h5>
              <p>{address.cep}</p>
            </Col>
            <Col lg={6} className="mb-4">
              <h5>Complemento</h5>
              <p>{address.complement}</p>
            </Col>
            {address.file && (
              <Col lg={6} className="mb-4 d-flex">
                <a
                  onClick={() =>
                    getUrl(
                      address.file.id,
                      address.file.type,
                      address.file.name,
                    )
                  }
                  className="link"
                  style={{ cursor: 'pointer' }}
                >
                  <div className="file">
                    <img src={pdfIcon} />
                    <p>Comprovante de Endereço</p>
                  </div>
                </a>
              </Col>
            )}
          </Row>
        ))}
      <Row>
        <Col lg={6} className="mb-4 d-flex">
          {!loading &&
            patient.documents?.map(document => (
              <a
                key={document.id}
                onClick={() =>
                  getUrl(
                    document.file.id,
                    document.file.type,
                    document.file.name,
                  )
                }
                className="link"
                style={{ cursor: 'pointer' }}
              >
                <div className="file">
                  <img src={pdfIcon} />
                  <p>Documento {document.id}</p>
                </div>
              </a>
            ))}
        </Col>
      </Row>
      {!loading &&
        prescriptions.data?.map(prescription => (
          <Col lg={12} className="mt-3" key={prescription.id}>
            <Link to={`/admin/prescricoes/${prescription.id}`}>
              <PrescriptionItem prescription={prescription} />
            </Link>
          </Col>
        ))}
    </Container>
  )
}
