import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useForm } from 'react-hook-form'

import { Container } from './styles'
import api from '~/services/api'
import Button from '~/components/Button'
import MedicineImage from '~/assets/images/produto.png'
import Thumb from '~/components/Thumb'
import ModalEditOrder from './ModalEditOrder/index'
import { Input, Select } from '~/components/Form'
import { phoneMask } from '~/shared/utils'
import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function Order() {
  const [order, setOrder] = useState({ medicines: [] })
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [brands, setBrands] = useState([])
  const [tracking, setTracking] = useState('')
  const [link, setLink] = useState('')
  const [carriers, setCarriers] = useState('')
  const [showEditOrder, setShowEditOrder] = useState(false)
  const { register, watch, setValue } = useForm()

  const { status } = watch()

  const getOrder = async () => {
    setLoading(true)
    const res = await api.get(`/orders/${id}`)
    setOrder(res.data)
    setLoading(false)
    setValue('status', res.data.status)
  }

  const submitCode = async (orderId, orderTrackingCode, orderUserEmail, orderLink, orderCarriers) => {
    setLoading(true)
    await api.put(`/orders/${orderId}`, {
      tracking_code: orderTrackingCode,
      status: 'transporting',
      user_email: orderUserEmail,
      link: orderLink,
      carriers: orderCarriers,
    })
    setLoading(false)
  }

  const changeForm = event => {
    if (event.target.name === 'tracking') {
      setTracking(event.target.value)
    }
    if (event.target.name === 'link') {
      setLink(event.target.value)
    }
    if (event.target.name === 'carriers') {
      setCarriers(event.target.value)
    }
  }

  useEffect(() => {
    getOrder()
  }, [id])

  useEffect(() => {
    setBrands(
      order.medicines
        .map(medicine => medicine.brand)
        .filter(
          (brand, index, self) =>
            index === self.findIndex(b => b.id === brand.id),
        ),
    )
  }, [order])

  const putOrders = () => {
    if (!loading) {
      const data = {
        status,
      }
      api.put(`/orders/${id}`, data)
    }
  }

  const handleCloseEditOrder = async () => {
    await getOrder()
    setShowEditOrder(false)
  }

  let total = []

  return (
    <Container>
      {loading && <Spinner />}
      {!loading && (
        <>
          <h2>Pedido #{order.id}</h2>
          <Row>
            <Col className="d-flex">
              <div className="desc pt-2">
                <div className="infos">
                  <div className="my-3">
                    <b>Medico</b>
                    {order.prescription &&
                    order.prescription.physician !== null ? (
                      <>
                        <p>
                          <a
                            href={`/admin/pacientes/${order.prescription.physician.id}`}
                          >
                            {order.prescription.physician
                              ? order.prescription.physician.profile.full_name
                              : order.prescription.prePhysician?.full_name}
                          </a>
                        </p>
                        <p className="mt-3">
                          <b>Telefone:</b>{' '}
                          {phoneMask(
                            order.prescription.physician.profile.phone_number,
                          )}
                        </p>
                        <p>
                          <b>E-mail:</b>{' '}
                          <a
                            href={`mailto:${order.prescription.physician.email}`}
                          >
                            {order.prescription.physician.email}
                          </a>
                        </p>
                      </>
                    ) : (
                      <p>
                        --------------------------------------------------------
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="pl-5 desc pt-2">
                <div className="infos">
                  <div className="my-3">
                    <b>Paciente</b>
                    <p>
                      {order.prescription && (
                        <a
                          href={`/admin/pacientes/${order.prescription.patient.id}`}
                        >
                          {order.prescription.patient.profile.full_name}
                        </a>
                      )}
                    </p>
                    <p className="mt-3">
                      <b>Telefone:</b>{' '}
                      {order.prescription &&
                        phoneMask(
                          order.prescription.patient.profile.phone_number,
                        )}
                    </p>
                    <p>
                      <b>E-mail:</b>{' '}
                      <a href={`mailto:${order.prescription.patient.email}`}>
                        {order.prescription.patient.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="pl-5 desc p-2">
                <div className="infos">
                  <div className="my-3">
                    <b>Data do pedido</b>
                    <p className="mb-3">
                      {moment(order.created_at).format('DD/MM/YYYY')}
                    </p>
                    <b>Endereço da entrega</b>
                    <p>
                      {order.address?.street}, {order.address.number}
                    </p>
                    <p>{order.address?.complement}</p>
                    <p>{order.address?.district}</p>
                    <p>
                      {order.address?.state} - {order.address?.city}
                    </p>
                    <p>{order.address?.cep}</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} className="px-0">
              <form onChange={e => changeForm(e)}>
                <Col lg={12} className="d-flex">
                  <Select
                    ref={register}
                    name="status"
                    label="Status do pedido"
                    size="md"
                    fluid
                    icon="icon-chevron-down"
                    className="my-3"
                  >
                    <option value="pendding">Novo (em análise)</option>
                    <option value="payment">Aguardando pagamento</option>
                    <option value="transport">Aguardando produto</option>
                    <option value="finished">Em tratamento (finalizado)</option>
                    <option value="cancelled">
                      Finalizado perdido (cancelado)
                    </option>
                  </Select>
                </Col>
                <Col lg={12} className="d-flex">
                  <Select
                    name="carriers"
                    label="Transportadoras"
                    size="md"
                    fluid
                    icon="icon-chevron-down"
                    className="my-3"
                    defaultValue={order.carriers}
                  >
                    <option value="UPS">UPS</option>
                    <option value="DHL">DHL</option>
                    <option value="Fedex">Fedex</option>
                  </Select>

                  <Input
                    size="md"
                    type="text"
                    label="Link de rastreio do pedido"
                    name="link"
                    className="my-3 ml-3"
                    placeholder="Link do rastreio"
                    value={order.link}
                  />
                  <Input
                    size="md"
                    type="text"
                    label="Código de rastreio do pedido"
                    name="tracking"
                    className="my-3 ml-3"
                    placeholder="Código de rastreio"
                    value={order.tracking_code}
                  />
                  <button
                    className="btn btn-success ml-3"
                    style={{
                      height: '50px',
                      'margin-top': '40px',
                    }}
                    onClick={() =>
                      submitCode(
                        order.id,
                        tracking,
                        order.owner.email,
                        link,
                        carriers,
                      )
                    }
                  >
                    Salvar código
                  </button>
                </Col>
              </form>
            </Col>
            <Col lg={12} className="mt-4">
              <h4>Produtos</h4>
              {order.medicines.map(medicine => (
                <div className="item" key={medicine.id}>
                  <div className="d-flex justify-content-center">
                    <Thumb className="lg">
                      <div className="inner">
                        {order.medicines[0].photo ? (
                          <RenderImg
                            photo_id={order.medicines[0].photo?.id}
                            photo_uuid={order.medicines[0].photo?.uuidUrl}
                            alt={order.medicines[0].photo?.name}
                            isPrivate={order.medicines[0].photo?.private}
                            imgSecond={MedicineImage}
                          />
                        ) : (
                          <img src={MedicineImage} alt="" />
                        )}
                      </div>
                    </Thumb>
                  </div>
                  <div className="d-flex flex-column justify-content-between p-3 w-100">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h4 className="mb-2">{medicine?.name}</h4>
                        <p>{medicine?.pivot.quantity} unidade(s)</p>
                      </div>
                    </div>
                    <div>
                      <p>Valor vendido:</p>
                      <p>
                        {medicine.brand.currency} {medicine.pivot.price}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column justify-content-end">
                        <p>Vendido por:</p>
                        <p>{medicine?.brand.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {brands.map((brand, brandIdx) => {
                total[brandIdx] = 0
                if (brand.shipping) total[brandIdx] = parseInt(order.shipping)
                return (
                  <div key={brand.id}>
                    <h5 className="mt-3 mb-2">{brand.name}</h5>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <b>Produto</b>
                          </th>
                          <th className="text-right">
                            <b>Quantidade</b>
                          </th>
                          <th className="text-right">
                            <b>Valor</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.medicines.map((medicine, medIdx) => {
                          if (medicine.brand.id === brand.id) {
                            total[brandIdx] =
                              parseInt(total[brandIdx]) +
                              parseInt(medicine.pivot.price) *
                                parseInt(medicine.pivot.quantity)
                            total[brandIdx] = total[brandIdx].toFixed(2)

                            return (
                              <tr key={medicine.id}>
                                <td>{medicine.comercial}</td>
                                <td className="text-right">
                                  {medicine.pivot.quantity}
                                </td>
                                <td className="text-right">
                                  {brand.currency} {medicine.pivot.price}
                                </td>
                              </tr>
                            )
                          }
                        })}
                        <tr>
                          <td className="text-right" colSpan="3">
                            <b>Frete:</b> {brand.currency} {order.shipping}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right" colSpan="3">
                            <b>Total:</b> {brand.currency} {total[brandIdx]}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )
              })}
            </Col>
            <Col lg={12} className="d-flex justify-content-end">
              <Button
                className="primary sm mr-5"
                disabled={loading}
                type="submit"
                onClick={() => putOrders()}
              >
                Salvar
              </Button>
              <Button
                className="primary"
                onClick={() => setShowEditOrder(true)}
              >
                Editar
              </Button>
            </Col>
          </Row>
          <ModalEditOrder
            show={showEditOrder}
            handleClose={handleCloseEditOrder}
            order={order}
          />
        </>
      )}
    </Container>
  )
}
