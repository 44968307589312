import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, useLocation } from 'react-router-dom'

function RouteWrapper({
  redirectTo,
  isPrivate,
  isGuest,
  component: Component,
  ...rest
}) {
  let location = useLocation()
  const authenticated = localStorage.getItem('@DrCannabis:JWT_TOKEN')

  if (!authenticated && isPrivate) {
    localStorage.setItem('@DrCannabis:URL_AFTER_LOGIN', location.pathname)
    return <Redirect to={redirectTo} />
  }
  if (authenticated && isGuest) return <Redirect to={redirectTo} />

  return <Route {...rest} render={props => <Component {...props} />} />
}

RouteWrapper.propTypes = {
  redirectTo: PropTypes.string,
  isPrivate: PropTypes.bool,
  isGuest: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
}

RouteWrapper.defaultProps = {
  redirectTo: '/',
  isPrivate: false,
}

export default RouteWrapper
