import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;
`

export const Order = styled.div`
  display: flex;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  .image {
    width: 165px;
    height: 165px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .desc {
    flex: 1;
    margin: 1rem;
    .title {
      color: #1c2960;
      font-size: 20px;
    }
    .date {
      font-size: 14.3353px;
      line-height: 140.62%;
    }
  }
`
