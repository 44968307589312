import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  .img-box {
    img {
      width: 7rem;
      height: 7rem;
      border-radius: 50%;
    }
  }

  .desc {
    display: flex;
    align-items: center;

    h3 {
      font-size: 30px;
    }

    h5 {
      margin-top: 0.8rem;
      font-size: 18px;
    }
  }
`
