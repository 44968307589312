import React from 'react'
import PropTypes from 'prop-types'

import { Container } from './styles'

const Select = React.forwardRef(
  (
    {
      icon,
      name,
      size,
      children,
      label,
      fluid,
      className,
      error,
      defaultValue,
    },
    ref,
  ) => {
    return (
      <div className={className}>
        {label && <label htmlFor={name}>{label}</label>}
        <Container
          className={`${size} position-relative ${fluid ? 'fluid' : ''}`}
        >
          <select
            className={`dropdown pl-3 pr-3 ${error ? 'error' : ''}`}
            name={name}
            id={name}
            ref={ref}
            defaultValue={defaultValue}
          >
            {children}
          </select>
          {error && <span className="error">{error.message}</span>}
        </Container>
      </div>
    )
  },
)

Select.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  refer: PropTypes.func,
  children: PropTypes.node.isRequired,
  error: PropTypes.object,
}

export default Select
