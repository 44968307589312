import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;

  h4 {
    font-family: sarabunMedium;
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 0.3rem;
    line-height: 1.3;
    text-align: justify;
  }

  .icon {
    width: 25px;
    height: 25px;
  }
`

export const ScheduleConsultation = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin: 1rem;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
`
