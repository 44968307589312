import React from 'react'
import { Switch } from 'react-router-dom'
import Route from './Route'

import Profile from '~/pages/Patient/Profile/Show'
import ProfileEdit from '~/pages/Patient/Profile/Edit'
import WhatNow from '~/pages/Patient/WhatNow'
import Prescriptions from '~/pages/Patient/Prescriptions'
import Orders from '~/pages/Patient/Orders'
import Physicians from '~/pages/Patient/Physicians'
import Physician from '~/pages/Patient/Physician'

export default function Routes() {
  return (
    <Switch>
      <Route
        path="/paciente"
        exact
        component={WhatNow}
        isPrivate
        redirectTo="/login"
      />
      <Route
        path="/paciente/perfil"
        exact
        component={Profile}
        isPrivate
        redirectTo="/login"
      />
      <Route
        path="/paciente/perfil/editar"
        exact
        component={ProfileEdit}
        isPrivate
        redirectTo="/login"
      />
      <Route
        path="/paciente/medicos"
        component={Physicians}
        isPrivate
        exact
        redirectTo="/login"
      />

      <Route
        path="/paciente/medicos/:id"
        component={Physician}
        isPrivate
        exact
        redirectTo="/login"
      />
      <Route
        path="/paciente/eagora"
        component={WhatNow}
        exact
        isPrivate
        redirectTo="/login"
      />
      <Route
        path="/paciente/prescricao"
        component={Prescriptions}
        exact
        isPrivate
        redirectTo="/login"
      />
      <Route
        path="/paciente/pedidos"
        component={Orders}
        exact
        isPrivate
        redirectTo="/login"
      />
    </Switch>
  )
}
