import styled from 'styled-components'

export const BrandStyle = styled.div`
  .store-hero {
    background-position: center center;
    background-size: cover;
    position: relative;
    max-height: 400px;
    .bg {
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: cover;
      filter: opacity(0.5);
    }
    .jumbotron {
      background-color: rgba(0, 0, 0, 0.25);
      color: white;
    }
    img {
      max-height: 150px;
      max-width: 100%;
    }
  }

  .products {
    .title {
      height: 60px;
      overflow: hidden;
    }
    .description {
      height: 50px;
      overflow: hidden;
    }
  }

  .brand-box {
    p {
      height: 3em;
    }
    .photo {
      height: 250px;
      object-fit: contain;
    }
  }
`
