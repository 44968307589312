import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import NumberFormat from 'react-number-format'

import { Container, ScheduleConsultation } from './styles'
import ProfileCard from '~/components/ProfileCard'
import Button from '~/components/Button/index'
import { useEffect } from 'react'
import api from '~/services/api'
import Sprites from '~/assets/icons/sprites.svg'

export default function Physician() {
  const { id } = useParams()
  const [physician, setPhysician] = useState({})
  const [loading, setLoading] = useState(false)

  const getData = async physicianId => {
    setLoading(true)
    const res = await api.get(`/physicians/${physicianId}`)
    setPhysician(res.data)
    setLoading(false)
  }

  const formatToArray = data => {
    return data.split(',')
  }

  useEffect(() => {
    getData(id)
  }, [id])

  const favoritePhysician = async () => {
    if (physician.user?.favoritesPatients.length) {
      await api.delete(`/favorites/${physician.user?.id}`)
    } else {
      await api.post(`/favorites/${physician.user?.id}`)
    }
    await getData(id)
  }

  let address = physician.user?.addresses[0]
  const favorite = physician.user?.favoritesPatients.length ? true : false

  return (
    <Container>
      <Row>
        <Col lg={8} className="my-4">
          <ProfileCard data={physician.user} physician={physician} />
        </Col>
        <Col className="d-flex align-items-center flex-column justify-content-center">
          {physician.online_consultation && (
            <a href={physician.url_online_consultation} target="_blank" rel="noopener">
              <Button className="md mb-2 primary">CONSULTA ONLINE</Button>
            </a>
          )}
          <Button
            className={`md ${favorite ? 'primary' : 'outline-dark'}`}
            onClick={favoritePhysician}
          >
            Favoritar Médico
            <svg
              className="icon ml-1"
              style={{
                fill: favorite ? '#ff0000' : '#fff',
                stroke: favorite ? 'none' : '#000',
              }}
            >
              <use xlinkHref={`${Sprites}#icon-heart`} />
            </svg>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="mb-4">
          <h4>Sobre</h4>
          <p className="text--rr">{physician.about}</p>
          {physician.price && (
            <p className="py-2">Preço da consulta: R$ {physician.price}</p>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col className="mb-4">
              <h4>Especialidade</h4>
              <Button className="outline-primary">{physician.specialty}</Button>
            </Col>
          </Row>
          {physician.diseases?.length > 0 && (
            <Row>
              <Col className="mb-4">
                <h4>Enfermidades</h4>
                {Array.isArray(physician.diseases)
                  ? physician.diseases?.map(disease => (
                      <Button className="outline-primary mr-1 mb-1">
                        {disease}
                      </Button>
                    ))
                  : formatToArray(physician.diseases).map(disease => (
                      <Button className="outline-primary mr-1 mb-1">
                        {disease}
                      </Button>
                    ))}
              </Col>
            </Row>
          )}
          <Row>
            <Col className="mb-4">
              <h4>Contato</h4>
              {physician.user?.profile?.phone_number && (
                <p>
                  <NumberFormat
                    value={physician.user?.profile?.phone_number}
                    displayType={'text'}
                    format="(##) #####-####"
                  />
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              <h4>Endereço</h4>
              {address ? (
                <>
                  {address.street && (
                    <p>
                      {address?.street}, {address?.number} -{' '}
                      {address?.complement}
                    </p>
                  )}
                  <p>{address?.cep}</p>
                  <p>
                    {address?.city} - {address?.state}
                  </p>
                </>
              ) : (
                <p>Endereço não cadastrado</p>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
