import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;

  .item {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    margin: 0.5rem 0;
  }
`
