import styled from 'styled-components'

import { Variables as V } from '~/styles/global'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const PhysicianList = styled.div`
  display: flex;

  .physician {
    display: flex;
    align-items: center;
    padding: 0.3rem 0;
    width: 100%;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 0.5rem;

    &:hover {
      background-color: ${V.colorGreyLighter};
    }
  }

  .thumb {
    margin: 1rem;
  }

  .name {
  }
`

export const Physician = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .thumb {
    margin: 1rem;
  }

  .name {
  }
`
