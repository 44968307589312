import React from 'react'

import { Container } from './styles'
import PatientImg from '~/assets/images/physician-default.png'
import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function Patient({ data, ...rest }) {
  const profile = data.profile

  return (
    <Container {...rest}>
      <div className="avatar">
        {profile && profile.avatar ? (
          <RenderImg
            photo_id={profile.avatar.id}
            photo_uuid={profile.avatar.uuidUrl}
            imgSecond={PatientImg}
            isPrivate={profile.avatar.private}
            alt="Avatar"
          />
        ) : (
          <img
            src={profile?.avatar ? profile?.avatar.url : PatientImg}
            alt="avatar"
          />
        )}
      </div>
      <div className="desc">
        <div className="d-flex flex-column justify-content-center h-100 p-3">
          <h5 className="text--rm">{profile?.full_name}</h5>
        </div>
      </div>
    </Container>
  )
}
