import React from 'react'
import PropTypes from 'prop-types'

import { Container } from './styles'
import Sprites from '~/assets/icons/sprites.svg'

const TextArea = React.forwardRef(
  (
    {
      icon,
      name,
      placeholder,
      type,
      error,
      size,
      label,
      fluid,
      rows,
      className,
      classTextArea,
      children,
      ...rest
    },
    ref,
  ) => {
    return (
      <>
        <div className={className}>
          {label && <label htmlFor={name}>{label}</label>}
          <Container
            className={`${size} position-relative  ${fluid ? 'fluid' : ''}`}
          >
            {icon && (
              <svg className="icon left position-absolute">
                <use xlinkHref={`${Sprites}#${icon}`} />
              </svg>
            )}
            <textarea
              className={`text pr-3 ${classTextArea} ${icon ? 'pl-5' : 'p-2'} ${
                error ? 'error' : ''
              }`}
              type={type}
              rows={rows}
              name={name}
              placeholder={placeholder}
              ref={ref}
              id={name}
              {...rest}
            >
              {children}
            </textarea>
            {error && <span className="error">{error.message}</span>}
          </Container>
        </div>
      </>
    )
  },
)

TextArea.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fluid: PropTypes.bool,
  size: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  error: PropTypes.object,
}

export default TextArea
