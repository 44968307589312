import React from 'react'

import { Container } from './styles'

const CheckBox = React.forwardRef(
  ({ name, label, error, className, ...rest }, ref) => {
    return (
      <Container className={className}>
        <span className="label">{label}</span>
        <input type="checkbox" ref={ref} id={name} name={name} {...rest} />
        <span className={`checkmark  ${error ? 'error' : ''}`}/>
      </Container>
    )
  },
)

export default CheckBox
