import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
`

export const Thumb = styled.div`
  border: 1px solid #eaeaea;
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;

  .inner {
    display: flex;
    min-width: 0px;
    overflow: hidden;
    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }
`
