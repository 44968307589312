import React from 'react'
import moment from 'moment'

import { Container } from './styles'

export default function Analyzing({ data, refresh }) {
  return (
    <Container>
      <h2>Em análise</h2>
      <p className="mt-3">
        Pedido enviado para análise no dia:{' '}
        {moment(data.updated_at).format('DD/MM/YYYY')}
      </p>
    </Container>
  )
}
