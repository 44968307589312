import React, { useState } from 'react'
import moment from 'moment'

import api from '~/services/api'
import { Container, Progress } from './styles'
import AskMedicine from './AskMedicine'
import AskPatient from './AskPatient'
import GetPrescription from './GetPrescription'
import SendPrescription from './SendPrescription'
import Done from './Done'

const Prescription = ({ data, ...rest }) => {
  const [step, setStep] = useState(data?.status ?? 'new')
  const [prescription, setPrescription] = useState(data)
  const [loading, setLoading] = useState(false)

  const getData = async id => {
    setLoading(true)
    const res = await api.get(`/prescriptions/${id}`)
    setPrescription(res.data)
    setLoading(false)
  }

  let Body
  let progressPercert
  switch (step) {
    case 'new':
      Body = AskPatient
      progressPercert = 12
      break
    case 'askMedicine':
      Body = AskMedicine
      progressPercert = 33.5
      break
    case 'getPrescription':
      Body = GetPrescription
      progressPercert = 58
      break
    case 'sendPrescription':
      Body = SendPrescription
      progressPercert = 85
      break
    case 'askAnvisa':
      Body = Done
      progressPercert = 100
      break
    default:
      Body = Done
      progressPercert = 100
      break
  }

  return (
    <Container className="card" {...rest}>
      <div className="header-container">
        {data && (
          <>
            <span>
              Enviado em {moment(data?.created_at).format('DD/MM/YY')}
            </span>
            <span className="float-right">Prescrição #{data?.id}</span>
          </>
        )}
        {(!data || data?.length === 0) && <span>Nova Prescrição</span>}
      </div>
      <div className="body">
        <Body next={setStep} data={prescription} refresh={getData} />
      </div>
      <div className="footer">
        <Progress now={progressPercert} />
        <div className="d-flex steps">
          <span className="m-auto">Paciente</span>
          <span className="m-auto">Medicamento</span>
          <span className="m-auto">Baixar Prescrição</span>
          <span className="m-auto">Subir Prescrição</span>
        </div>
      </div>
    </Container>
  )
}

export default Prescription
