import { all, takeLatest } from 'redux-saga/effects'

import { Types as AuthTypes } from '~/store/ducks/auth'
import { Types as MedicinesTypes } from '~/store/ducks/medicines'

import { login, getSession } from './auth'
import { medicines } from './medicines'

export default function* rootSaga() {
  yield all([takeLatest(AuthTypes.STORE_SESSION_REQUEST, login)])
  yield all([takeLatest(AuthTypes.GET_SESSION_REQUEST, getSession)])
  yield all([takeLatest(MedicinesTypes.MEDICINES_REQUEST, medicines)])
}
