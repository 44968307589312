import React from 'react'
import { useParams } from 'react-router-dom'

const BlogRedirect = () => {
  const { id } = useParams()

  return window.location.assign(`https://blog.drcannabis.com.br/${id}`)
}

export { BlogRedirect }
