import React from 'react'

import { Container } from './styles'

const Radio = React.forwardRef(
  ({ value, name, label, error, className, ...rest }, ref) => {
    return (
      <Container className={className}>
        <input
          type="radio"
          ref={ref}
          id={name}
          name={name}
          value={value}
          {...rest}
        />
        <span className="checkmark" {...rest}/>
        <span className="label" {...rest}>
          {label}
        </span>
      </Container>
    )
  },
)

export default Radio
