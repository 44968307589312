import React, { useState } from 'react'
import moment from 'moment'

import api from '~/services/api'
import { Container, Progress } from './styles'
import NewPrescription from './NewPrescription'
import AskMedicine from './AskMedicine'
import AskPhysician from './AskPhysician'
import AskAnvisa from './AskAnvisa'
import TemplateAnvisa from './TemplateAnvisa'
import Analyzing from './Analyzing'
import SendAnvisa from './SendAnvisa'
import Quotation from './Quotation'
import { useSelector } from 'react-redux'

const Prescription = ({ data, ...rest }) => {
  const [step, setStep] = useState(data?.status ?? 'new')
  const [prescription, setPrescription] = useState(data)
  const [loading, setLoading] = useState(false)
  const { session } = useSelector(state => state.auth)

  const getData = async id => {
    setLoading(true)
    const res = await api.get(`/prescriptions/${id}`)
    setPrescription(res.data)
    setLoading(false)
  }

  let Body
  let progressPercert
  switch (step) {
    case 'askAnvisa':
      Body = AskAnvisa
      progressPercert = 40
      break
    case 'askMedicine':
      Body = AskMedicine
      progressPercert = 40
      break
    case 'askPhysician':
      Body = AskPhysician
      progressPercert = 40
      break
    case 'templateAnvisa':
      Body = TemplateAnvisa
      progressPercert = 40
      break
    case 'sendAnvisa':
      Body = SendAnvisa
      progressPercert = 40
      break
    case 'quotation':
      Body = Quotation
      progressPercert = 62
      break
    case 'analyzing':
      Body = Analyzing
      progressPercert = 85
      break
    default:
      Body = NewPrescription
      progressPercert = 15
      break
  }

  return (
    <Container className="card" {...rest}>
      <div className="header-prescription">
        {data && (
          <>
            <span>
              Prescrição enviada em{' '}
              {moment(data?.created_at).format('DD/MM/YY')}
            </span>
            <span className="float-right">Prescrição #{data?.id}</span>
          </>
        )}
        {(!data || data?.length === 0) && <span>Nova Prescrição</span>}
      </div>
      <div className="body">
        <Body
          next={setStep}
          data={prescription}
          refresh={getData}
          userMail={session.email}
          physicianEmail={
            prescription?.physician?.email ? prescription.physician.email : ''
          }
        />
      </div>
      <div className="footer">
        <Progress now={progressPercert} />
        <div className="d-flex steps">
          <span className="m-auto">Prescrição</span>
          <span className="m-auto">Anvisa</span>
          <span className="m-auto">Cotação</span>
          <span className="m-auto">Análise</span>
        </div>
      </div>
    </Container>
  )
}

export default Prescription
