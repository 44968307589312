import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

import 'jquery/dist/jquery.min';
import 'bootstrap/dist/js/bootstrap.min';
import './assets/custom-css/main.scss';



ReactDOM.render(<App />, document.getElementById('root'))


