import styled from 'styled-components'

export const Container = styled.div`
  padding: 2.2rem;
  height: 100%;
`
export const OptionBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 280px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 1.2rem;

  .title {
    display: flex;
    height: 50px;
    align-items: center;
    text-align: center;
    height: 50px;
    width: 100%;
    h4 {
      width: 100%;
    }

    .icon {
      width: 35px;
      height: 35px;
      margin-right: 0.7rem;
    }
  }

  .desc {
    display: flex;
    flex-wrap: wrap;
    height: 70px;
    margin: 1rem;
    align-content: center;
    p {
      font-size: 1.1rem;
      text-align: center;
      height: fit-content;
    }
  }
`
