import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Button from '~/components/Button'

export default function ModalFinishRegistration({ show, handleClose }) {
  const auth = useSelector(state => state.auth)
  let link
  if (
    auth.session.roles[0] === 'patient' ||
    auth.session.roles[0] === 'responsible'
  )
    link = '/paciente/perfil/editar'
  else if (auth.session.roles[0] === 'physician') link = '/medico/perfil/editar'

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-45w"
    >
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center my-4">
          <h2 className="text-center text--rm my-3">Termine seu cadastro!</h2>
          <p className="text-center m-3">
            Para ter acesso a todas as atividades do nosso site, complete o seu
            cadastro! Com isso você poderá receber agendamento de consultas,
            acesso a artigos e muito mais!
          </p>
          <Link to={link}>
            <div className="d-flex my-4">
              <Button className="primary m-auto">Terminar cadastro</Button>
            </div>
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  )
}
