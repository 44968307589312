import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'

import { Container } from './styles'
import PhysicianList from './PhysiciansList'
import api from '~/services/api'
import { useForm } from 'react-hook-form'
import { Input, Select } from '~/components/Form'
import Specialties from '~/helpers/Specialties.json'
import { useSelector } from 'react-redux'
import TagManager from 'react-gtm-module'

export default function Physicians() {
  const [count, setCount] = useState({ data: [] })
  const [physicians, setPhysicians] = useState({ data: [] })
  const [status, setStatus] = useState('approved')
  const { register, watch } = useForm()
  const search = watch('search')
  const specialty = watch('specialty')
  const contacted = watch('contacted')
  const prescribes = watch('prescribes')
  const onlineConsultation = watch('onlineConsultation')

  const getCount = async () => {
    const res = await api.get('/physicians/count/all')
    setCount(res.data)
  }

  const setTotalPhysicians = () => {
    const { approved, disapproved, pending } = count
    return parseInt(approved) + parseInt(disapproved) + parseInt(pending)
  }

  const getPhysicians = async (
    page,
    searchQuery,
    physicianSpecialty,
    physicianStatus = 'approved',
    physicianPrescribes,
    physicianContacted,
    physicianOnlineConsultation,
  ) => {
    let query = ``
    if (physicianStatus) query += `status=${physicianStatus}&`
    if (page) query += `page=${page}&`
    if (searchQuery) query += `search=${searchQuery}&`
    if (physicianSpecialty) query += `specialty=${physicianSpecialty}&`
    if (physicianPrescribes) query += `prescribes=${(physicianPrescribes === 'sim')}&`
    if (physicianContacted) query += `contacted=${(physicianContacted === 'sim')}&`
    if (physicianOnlineConsultation) query += `onlineConsultation=${(physicianOnlineConsultation === 'sim')}&`
    const res = await api.get(`/physicians?${query}`)
    setPhysicians(res.data)
  }

  // useEffect(() => {
  //   getPhysicians(1, search, specialty, status, prescribes, contacted, onlineConsultation)
  // }, [specialty, status, prescribes, contacted, onlineConsultation])

  const handlePageChange = page => {
    getPhysicians(page, search, specialty, status)
  }

  useEffect(() => {
    getCount()
  }, [])

  const getTitle = itens => {
    if (status === 'approved') {
      return `Todos os Médicos:  ${itens.approved}`
    }
    if (status === 'pending') {
      return `Todos os Médicos: ${itens.pending}`
    }
    if (status === 'disapproved') {
      return `Todos os Médicos: ${itens.disapproved}`
    }
    if (status === '') {
      return `Todos os Médicos: ${setTotalPhysicians()}`
    }
  }

  const { session } = useSelector(state => state.auth)
  const tagManagerArgs = {
    gtmId: 'GTM-59529VR',
    dataLayer: {
      userId: session.profile.id,
      role: session.roles[0],
      name: session.profile.full_name,
      page: '/admin/medicos',
    },
  }
  TagManager.initialize(tagManagerArgs)


  return (
    <Container>
      <Row className="d-flex justify-content-between mt-2">
        <h2 className="text--rm mt-4 ml-2">{getTitle(count)}</h2>
        <div className="count-physicians mr-4">
          <p className="mb-1" onClick={() => setStatus('approved')}>
            Ativos: {count.approved}
          </p>
          <p
            className="count-physicians mb-1"
            onClick={() => setStatus('pending')}
          >
            Em avaliação: {count.pending}
          </p>
          <p
            className="count-physicians mb-1"
            onClick={() => setStatus('disapproved')}
          >
            Inativos: {count.disapproved}
          </p>
          <p
            className="count-physicians"
            onClick={() => {
              getPhysicians(1, '', '', '', '', '')
              setStatus('')
            }}
          >
            Total: {setTotalPhysicians()}
          </p>
        </div>
      </Row>
      <Row className="mt-4">
        <Col xs={8} className="d-flex pr-5">
          <Input
            type="text"
            className="w-100"
            name="search"
            placeholder="Nome ou Email ou CPF"
            size="md"
            classContainer="w-100"
            icon="icon-search"
            ref={register}
          />
        </Col>
        <Col xs={4}>
          <button
            className="btn btn-info search-button"
            onClick={() => getPhysicians(1,
              search,
              specialty,
              status,
              prescribes,
              contacted,
              onlineConsultation)}
          >
            Buscar
          </button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={4} className="d-flex">
          <Select
            ref={register}
            name="specialty"
            size="md"
            icon="icon-chevron-down"
            label="Especialidade"
          >
            <option value="">Todas</option>
            {Specialties.map(item => (
              <option value={item}>{item}</option>
            ))}
          </Select>
        </Col>
        <Col xs={4}>
          <Select
            ref={register}
            name="prescribes"
            size="md"
            icon="icon-chevron-down"
            className=""
            label="Prescreve"
          >
            <option value="">Todos</option>
            <option value="sim">Sim</option>
            <option value="nao">Não</option>
          </Select>
        </Col>
        <Col xs={4}>
          <Select
            ref={register}
            name="contacted"
            size="md"
            icon="icon-chevron-down"
            className=""
            label="Contatado"
          >
            <option value="">Todos</option>
            <option value="sim">Sim</option>
            <option value="nao">Não</option>
          </Select>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={4}>
          <Select
            ref={register}
            name="onlineConsultation"
            size="md"
            icon="icon-chevron-down"
            className=""
            label="Telemed"
          >
            <option value="">Todos</option>
            <option value="sim">Sim</option>
            <option value="nao">Não</option>
          </Select>
        </Col>
      </Row>
      <PhysicianList list={physicians} handlePageChange={handlePageChange}/>
    </Container>
  )
}
