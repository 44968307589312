import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'

import { Card, Text } from './styles'

import ProductImage from '~/assets/images/product-sample.jpg'
import Sprites from '~/assets/icons/sprites.svg'
import { useSelector } from 'react-redux'
import Button from '~/components/Button'
import { formatValue, paginateItens } from '~/shared/utils'
import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function Products({
  products = [],
  showTitle = true,
  showButton = true,
  brands = [],
}) {
  const history = useHistory()
  const { session } = useSelector(state => state.auth)
  const [routeState] = useState('/produtos/')
  const [page, setPage] = useState(1)

  return (
    <>
      <div className="col-12">
        {showTitle && (
          <Text>
            <h1 className="mb-3">Produtos à base de Cannabis</h1>
            {!session ||
            session.roles.length == 0 ||
            session.roles[0] === 'patient' ||
            session.roles[0] === 'responsible' ? (
              <div>
                <p>
                  Quanto custa um tratamento com produtos à base de cannabis?
                </p>
                <br />
                <p className="font-weight-normal text--black-dark mt-3">
                  A resposta curta é: depende. O preço de um tratamento com
                  canabinoides está atrelado a questões como a patologia a ser
                  tratada, a dosagem necessária, a concentração prescrita, a
                  epigenética e a metabolização dos componentes da cannabis pelo
                  metabolismo do paciente.
                </p>
                <p className="font-weight-normal text--black-dark mt-3">
                  Além desses, muitos outros fatores são relevantes para
                  garantir que o tratamento ofereça o maior benefício
                  terapêutico com os menores níveis de efeitos colaterais
                  possíveis. Encontrar o produto e a dosagem ideal são os
                  grandes desafios do tratamento com cannabis. É por isso que a
                  Dr. Cannabis trabalha em parceria as marcas que oferecem
                  produtos à base de cannabis com alta qualidade.
                </p>
              </div>
            ) : (
              ''
            )}

            <h3 className="mt-5">Conheça as marcas parceiras:</h3>

            <div
              id="carouselBrands"
              className="carousel slide bg-gray px-5 my-5"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active d-flex row ml-1">
                  {paginateItens(brands, page, 4).map(brand => (
                    <div className="col-3">
                      {session &&
                      (session.roles[0] === 'administrator' ||
                        session.roles[0] === 'physician') ? (
                        <Link to={`/marca/${brand.slug}`}>
                          <img
                            src={brand.logo?.uuidUrl}
                            alt={brand?.name}
                            className="img-fluid"
                          />
                        </Link>
                      ) : brand.slug === 'proprium-my-cannabis-code' ? (
                        <Link to={`/marca/${brand.slug}`}>
                          <img
                            src={brand.logo?.uuidUrl}
                            alt={brand?.name}
                            className="img-fluid"
                          />
                        </Link>
                      ) : (
                        <img
                          src={brand.logo?.uuidUrl}
                          alt={brand?.name}
                          className="img-fluid"
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <a
                className={
                  'carousel-control-prev d-flex justify-content-start ml-3'
                }
                href="#carouselProducts"
                role="button"
                data-slide="prev"
                onClick={() => page > 1 && setPage(page - 1)}
              >
                <i className="fas fa-chevron-left fa-2x text-black-50" />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next d-flex justify-content-end mr-2"
                href="#carouselProducts"
                role="button"
                data-slide="next"
                onClick={() =>
                  page < Math.ceil(brands.length / 3) && setPage(page + 1)
                }
              >
                <i className="fas fa-chevron-right fa-2x text-black-50" />
                <span className="sr-only">Next</span>
              </a>
            </div>
            <p className="font-weight-normal text--black-dark">
              Os produtos das marcas parceiras são de alta qualidade, têm
              autorização em seus países de origem e possuem o Certificado de
              Análise (CoA) - instrumento de segurança sobre os produtos tanto
              para os médicos quanto para pacientes.
            </p>
            <br />
            <p className="font-weight-normal text--black-dark">
              Para atender a regulamentação da Anvisa sobre produtos à base de
              cannabis, os valores de seu tratamento serão informados de acordo
              com a sua prescrição médica - documento essencial para iniciar o
              seu tratamento com canabinoides. O seu médico pode acessar
              informações mais completas e te orientar sobre as opções de
              produtos à base de cannabis.
            </p>
            <br />
          </Text>
        )}
        {showButton && (
          <>
            <h6 className="mt-3">
              <b>
                Atenção: a compra destes produtos tem como obrigatoriedade a
                prescrição médica e a autorização da Anvisa
              </b>
            </h6>
            <div className="d-flex my-3">
              <div className="d-flex text-center mr-3">
                <Link to="/paciente/prescricao">
                  <Button className="primary m-auto">
                    Já tem prescrição ou autorização?
                  </Button>
                </Link>
              </div>
              <div className="d-flex text-center">
                <Link to="/paciente/medicos">
                  <Button className="primary m-auto">
                    Quer encontrar um médico prescritor?
                  </Button>
                </Link>
              </div>
            </div>
          </>
        )}
        <div className="row mt-3">
          {session &&
            session.roles &&
            (session.roles[0] === 'administrator' ||
              session.roles[0] === 'physician') &&
            products.map(
              product =>
                product.is_controlled && (
                  <div
                    className="d-flex d-lg-block justify-content-center col-sm-4"
                    key={product.id}
                  >
                    <Card
                      className="d-flex flex-column mb-4"
                      style={{
                        'min-height': '535px',
                      }}
                    >
                      <RenderImg
                        photo_id={product.photo?.id}
                        photo_uuid={product.photo?.uuidUrl}
                        imgSecond={ProductImage}
                        isPrivate={product.photo?.private}
                        style={{ cursor: 'pointer' }}
                        onclickImg={() =>
                          history.push(`${routeState}${product.slug}`)
                        }
                      />
                      <div className="d-flex flex-column p-3 pr-4">
                        <h4
                          className="name text--rm text--black-dark mt-2 mt-md-3 mb-4"
                          onClick={() =>
                            history.push(`${routeState}${product.slug}`)
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          {product.name}
                        </h4>
                        <div className="d-flex justify-content-between">
                          <div className="mt-2">
                            <p className="mb-3">
                              <Link to={`/marca/${product.brand?.slug}`}>
                                {product.brand?.name}
                              </Link>
                            </p>
                            <div className="d-flex">
                              <p className="text--rr text--black-dark mb-5">
                                {formatValue(product.price, product.currency)}
                              </p>
                              {product.price_previous &&
                                (
                                  (product.price * 100) /
                                    product.price_previous -
                                  100
                                ).toFixed(2) < 0 && (
                                  <p className="pl-3 text--black-dark text--rm value text-danger">
                                    <strike>
                                      {formatValue(
                                        product.price_previous,
                                        product.currency,
                                      )}
                                    </strike>{' '}
                                    <span className="value value secondary ml-3 text-success">
                                      {(
                                        (product.price * 100) /
                                          product.price_previous -
                                        100
                                      ).toFixed(2)}
                                      %
                                    </span>
                                  </p>
                                )}
                            </div>
                            <p className="pl-3 text--black-dark text--rm value mb-3">
                              {product.presentation}
                            </p>
                          </div>
                        </div>
                        <Link
                          to={`${routeState}${product.slug}`}
                          className="anchor text--sm text-decoration-none d-flex align-items-center justify-content-end"
                        >
                          Ver mais
                          <span className="ml-1">
                            <svg className="icon">
                              <use xlinkHref={`${Sprites}#icon-angle-right`} />
                            </svg>
                          </span>
                        </Link>
                      </div>
                    </Card>
                  </div>
                ),
            )}
        </div>
      </div>
      <div className="col-12 mt-4">
        {showTitle && (
          <>
            <h3 className="mb-3">Produtos de venda livre</h3>
            <span className="font-size-17">
              Na <b>Dr. Cannabis</b> você também encontra opções para
              potencializar a qualidade do seu tratamento. Estes produtos não
              exigem prescrição médica ou autorização da Anvisa para serem
              adquiridos.
            </span>
          </>
        )}
        <div className="row mt-3">
          {products.map(
            product =>
              !product.is_controlled && (
                <div
                  className="d-flex d-lg-block justify-content-center col-sm-4"
                  key={product.id}
                >
                  <Card
                    className="d-flex flex-column mb-4"
                    style={{
                      'min-height': '535px',
                    }}
                  >
                    <RenderImg
                      photo_id={product.photo.id}
                      photo_uuid={product.photo.uuidUrl}
                      imgSecond={ProductImage}
                      isPrivate={product.photo.private}
                      style={{ cursor: 'pointer' }}
                      onclickImg={() =>
                        history.push(`${routeState}${product.slug}`)
                      }
                    />
                    <div className="d-flex flex-column p-3 pr-4">
                      <h4
                        className="name text--rm text--black-dark mt-2 mt-md-3 mb-2"
                        onClick={() =>
                          history.push(`${routeState}${product.slug}`)
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        {product.name}
                      </h4>
                      <div className="d-flex justify-content-between">
                        <div className="mt-2">
                          <p className="mb-3">
                            <Link to={`/marca/${product.brand.slug}`}>
                              {product.brand.name}
                            </Link>
                          </p>
                          <div className="d-flex">
                            <p className="text--rr text--black-dark mb-5">
                              {formatValue(product.price, product.currency)}
                            </p>
                            {product.price_previous &&
                              (
                                (product.price * 100) / product.price_previous -
                                100
                              ).toFixed(2) < 0 && (
                                <p className="pl-3 text--black-dark text--rm value text-danger">
                                  <strike>
                                    {formatValue(
                                      product.price_previous,
                                      product.currency,
                                    )}
                                  </strike>{' '}
                                  <span className="value value secondary ml-3 text-success">
                                    {(
                                      (product.price * 100) /
                                        product.price_previous -
                                      100
                                    ).toFixed(2)}
                                    %
                                  </span>
                                </p>
                              )}
                          </div>
                          <p className="pl-3 text--black-dark text--rm value">
                            {product.presentation}
                          </p>
                        </div>
                      </div>
                      <Link
                        to={`${routeState}${product.slug}`}
                        className="anchor text--sm text-decoration-none d-flex align-items-center justify-content-end"
                      >
                        Ver mais
                        <span className="ml-1">
                          <svg className="icon">
                            <use xlinkHref={`${Sprites}#icon-angle-right`} />
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </Card>
                </div>
              ),
          )}
        </div>
      </div>
    </>
  )
}

Products.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      resume: PropTypes.string,
      price: PropTypes.number.isRequired,
    }),
  ).isRequired,
}
