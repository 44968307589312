import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import Route from './Route'
import Home from '~/pages/Admin/Home'
import Physicians from '~/pages/Admin/Physicians'
import PhysiciansEdit from '~/pages/Admin/Physician/Edit'
import PhysiciansPhysicianEdit from '~/pages/Admin/Physician/EditPhysician'
import Patients from '~/pages/Admin/Patients'
import Patient from '~/pages/Admin/Patient'
import PatientEdit from '~/pages/Admin/Patient/Edit'
import Medicines from '~/pages/Admin/Medicines'
import NewBrand from '~/pages/Admin/Medicines/NewBrand'
import NewMedicine from '~/pages/Admin/Medicines/NewMedicine'
import Brand from '~/pages/Admin/Brand'
import Prescriptions from '~/pages/Admin/Prescriptions'
import NewPrescription from '~/pages/Physician/Prescriptions'
import Prescription from '~/pages/Admin/Prescription'
import Orders from '~/pages/Admin/Orders'
import Order from '~/pages/Admin/Order'
import Articles from '~/pages/Admin/Articles'
import Physician from '~/pages/Admin/Physician'

export default function Routes() {
  if (localStorage.getItem('roles') !== 'administrator') {
    return <Redirect to="/" />
  }

  return (
    <Switch>
      <Route path="/admin" exact component={Home} isPrivate redirectTo="/" />
      <Route
        path="/admin/medicos"
        component={Physicians}
        isPrivate
        exact
        redirectTo="/"
      />
      <Route
        path="/admin/medicos/:id"
        isPrivate
        exact
        redirectTo="/"
        component={Physician}
      />
      <Route
        path="/admin/medico/editar-medico/:id"
        component={PhysiciansPhysicianEdit}
        isPrivate
        exact
        redirectTo="/"
      />
      <Route
        path="/admin/medico/editar/:id"
        component={PhysiciansEdit}
        isPrivate
        exact
        redirectTo="/"
      />
      <Route
        path="/admin/pacientes"
        component={Patients}
        exact
        isPrivate
        redirectTo="/"
      />
      <Route
        path="/admin/pacientes/editar/:id"
        component={PatientEdit}
        isPrivate
        exact
        redirectTo="/"
      />
      <Route
        path="/admin/pacientes/:id"
        component={Patient}
        isPrivate
        exact
        redirectTo="/"
      />
      <Route
        path="/admin/produtos"
        component={Medicines}
        exact
        isPrivate
        redirectTo="/"
      />
      <Route
        path="/admin/produtos/novo"
        component={NewMedicine}
        exact
        isPrivate
        redirectTo="/"
      />
      <Route
        path="/admin/produtos/editar/:id"
        component={NewMedicine}
        exact
        isPrivate
        redirectTo="/"
      />
      <Route
        path="/admin/produtos/marca/novo"
        component={NewBrand}
        exact
        isPrivate
        redirectTo="/"
      />
      <Route
        path="/admin/produtos/marca/editar/:id"
        component={NewBrand}
        exact
        isPrivate
        redirectTo="/"
      />
      <Route
        path="/admin/produtos/marca/:slug"
        component={Brand}
        exact
        isPrivate
        redirectTo="/"
      />
      <Route
        path="/admin/prescricoes"
        component={Prescriptions}
        exact
        isPrivate
        redirectTo="/"
      />
      <Route
        path="/admin/nova/prescricao"
        component={NewPrescription}
        exact
        isPrivate
        redirectTo="/"
      />
      <Route
        path="/admin/prescricoes/:id"
        component={Prescription}
        exact
        isPrivate
        redirectTo="/"
      />
      <Route
        path="/admin/pedidos"
        component={Orders}
        exact
        isPrivate
        redirectTo="/"
      />
      <Route
        path="/admin/pedidos/:id"
        component={Order}
        exact
        isPrivate
        redirectTo="/"
      />
      <Route
        path="/admin/artigos"
        component={Articles}
        exact
        isPrivate
        redirectTo="/"
      />
    </Switch>
  )
}
