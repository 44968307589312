import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'

import api from '~/services/api'
import { Container, Physician } from './styles'
import Button from '~/components/Button'
import Thumb from '~/components/Thumb'
import PhotoProfile from '~/assets/images/foto_perfil.svg'
import ModalSelectPhysician from '~/components/ModalSelectPhysician'

export default function AskPhysician({ next, data, refresh }) {
  const [modalShow, setModalShow] = useState(false)
  const { physician, prePhysician } = data

  const goTemplateAnvisa = async () => {
    const prescription = {
      status: 'templateAnvisa',
    }
    await api.put(`/prescriptions/${data.id}`, prescription)
    next('templateAnvisa')
  }

  const goAskMedicines = async () => {
    const prescription = {
      status: 'askMedicine',
    }
    await api.put(`/prescriptions/${data.id}`, prescription)
    next('askMedicine')
  }

  const closeSelectPhysician = async () => {
    await refresh(data.id)
    setModalShow(false)
  }

  let physicianPreview
  if (physician) {
    physicianPreview = (
      <Physician>
        <div className="thumb">
          <Thumb>
            <div className="inner">
              {physician.avatar && (
                <img src={physician.profile.avatar?.url} alt="" />
              )}
              {!physician.avatar && <img src={PhotoProfile} alt="" />}
            </div>
          </Thumb>
        </div>
        <div className="name">{physician.profile?.full_name}</div>
      </Physician>
    )
  } else if (prePhysician) {
    physicianPreview = (
      <Physician>
        <div className="thumb">
          <Thumb>
            <div className="inner">
              <img src={PhotoProfile} />
            </div>
          </Thumb>
        </div>
        <div className="name">{prePhysician.full_name}</div>
      </Physician>
    )
  } else {
    physicianPreview = <div className="my-3" />
  }

  return (
    <Container>
      <Alert variant="success">
        <p>Ótimo, agora só falta nos dizer quem prescreveu.</p>
      </Alert>

      <h4 className="text-center mb-4">Quem é o médico prescritor?</h4>
      <p></p>

      {physicianPreview}

      <div className="d-flex justify-content-center mt-3 mb-4">
        <Button className="white" onClick={() => setModalShow(true)}>
          Selecionar médico
        </Button>
      </div>
      <div className="d-flex justify-content-between">
        <Button className="primary" onClick={goAskMedicines}>
          Voltar
        </Button>

        <Button className="primary" onClick={goTemplateAnvisa}>
          Próximo
        </Button>
      </div>

      <ModalSelectPhysician
        show={modalShow}
        onHide={closeSelectPhysician}
        prescriptionId={data.id}
      />
    </Container>
  )
}
