import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Specialties from '~/helpers/Specialties.json'
import api from '~/services/api'
import Pagination from 'react-js-pagination'
import ModalFinishRegistration from '~/components/ModalFinishRegistration'
import Physician from '~/components/PhysicianCard'
import { Alert } from 'react-bootstrap'
import Sprites from '~/assets/icons/sprites.svg'

export default function Physicians() {
  const [width, setWidth] = useState(0)
  const size = useWindowSize()

  const [physicians, setPhysicians] = useState([])
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const auth = useSelector(state => state.auth)
  const [form, setForm] = useState({
    search: '',
    specialty: '',
    onlineConsultation: '',
  })

  const setElementValue = e => {
    const { value, name } = e.currentTarget

    setForm(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const getPhysicians = async page => {
    setLoading(true)
    const { search, specialty, onlineConsultation } = form
    let query = ''
    if (search) query += `search=${search}&`
    if (onlineConsultation) query += `onlineConsultation=${onlineConsultation}&`
    if (specialty) query += `specialty=${specialty}&`

    if (page) query += `page=${page}&`
    const res = await api.get(`/physicians/pacient?${query}`)
    setPhysicians(res.data)
    setLoading(false)
  }

  const PhysicianCard = ({ physician, width }) => {
    let withLink
    if (auth.session.profile.cpf && auth.session.profile.birthdate) {
      withLink = (
        <Link to={`/paciente/medicos/${physician.id}`}>
          <Physician data={physician} width={width} />
        </Link>
      )
    } else {
      withLink = (
        <Physician onClick={() => setShowModal(true)} data={physician} />
      )
    }
    return withLink
  }

  const handlePageChange = page => {
    getPhysicians(page)
  }

  useEffect(() => {
    getPhysicians()
  }, [])

  useEffect(() => {
    setWidth(size.width)
  }, [size.width])

  return (
    <>
      <div className="container patient-list__physician">
        <div className="row mb-2">
          <div className="col-12 d-flex mb-2">
            <div className="form-group has-search w-100">
              <span className="fa fa-search form-control-feedback" />
              <input
                type="text"
                className="form-control w-100"
                placeholder="Busque por nome"
                name="search"
                id="search"
                value={form.search}
                onChange={e => setElementValue(e)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="specialty">Selecione especialidade</label>
              <select
                className="form-control"
                id="specialty"
                name="specialty"
                onChange={e => setElementValue(e)}
              >
                <option value="">Especialidade</option>
                {Specialties.map(item => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="onlineConsultation">
                Atende por Telemedicina
              </label>
              <select
                className="form-control"
                id="onlineConsultation"
                name="onlineConsultation"
                onChange={e => setElementValue(e)}
              >
                <option value="">Selecione</option>
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </select>
            </div>
          </div>
          <div className="col-4">
            <button
              type="button"
              id="searchButton"
              className="btn btn-info button-search-button mt-4 border-0"
              onClick={() => getPhysicians()}
            >
              Buscar
            </button>
          </div>
          <div className="col-12">
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          {physicians.data?.length > 0 ? (
            <>
              <div className="col-12">
                <Alert variant="" className="px-0">
                  <p style={{ lineHeight: '1.5', 'font-size': '14px' }}>
                    <b style={{ 'font-size': '20px' }}>
                      Agende e faça a consulta online e conte com todo apoio e
                      suporte da Dr. Cannabis gratuitamente
                    </b>
                    <br />
                    Procure o ícone
                    <svg className="icon ml-2 telemed mr-2">
                      <use xlinkHref={`${Sprites}#icon-cam`} />
                    </svg>
                    clique para ver detalhes sobre o profissional e agendar seu
                    atendimento. A utilização da telemedicina está amparada pelo
                    Ofício n 1.756/20 do Conselho Federal de Medicina e pela
                    portaria n 467/20 do Ministério da Saúde.
                  </p>
                </Alert>
              </div>
              {physicians.data?.map(physician => (
                <div
                  className={`my-3 ${width > 575 ? 'col-md-12' : 'col-12'}`}
                  key={physician.id}
                >
                  <PhysicianCard physician={physician} width={width} />
                </div>
              ))}
              <div className="row w-100">
                <div className="d-flex justify-content-center mt-3 col">
                  {physicians.pages.lastPage > 1 && (
                    <Pagination
                      activePage={physicians.pages.page}
                      itemsCountPerPage={physicians.pages.perPage}
                      totalItemsCount={physicians.pages.total}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              {!loading && (
                <div className="justify-content-center my-3 col">
                  <Alert variant="warning" className="text-center">
                    Não foram encontrados médicos para a sua busca.
                  </Alert>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ModalFinishRegistration
        show={showModal}
        handleClose={() => setShowModal(false)}
      />
    </>
  )
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
