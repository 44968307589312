import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import api from '~/services/api'
import { Container } from './styles'
import Prescription from './Prescription'
import ModalFinishRegistration from '~/components/ModalFinishRegistration/index'

export default function Prescriptions() {
  const [prescriptions, setPrescriptions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const auth = useSelector(state => state.auth)
  const { session } = useSelector(state => state.auth)

  const getPrescriptions = async () => {
    const res = await api.get('/prescriptions')
    setPrescriptions(res.data)
  }

  useEffect(() => {
    auth.session.profile.full_name &&
    ((!auth.session.profile.cpf || !auth.session.profile.birthdate) && session.roles[0] !== 'administrator' )
      ? setShowModal(true)
      : setShowModal(false)
    getPrescriptions()
  }, [])

  return (
    <>
      <Container>
        <Prescription className="mb-3" />

        {session.roles[0] !== 'administrator' && prescriptions.map(prescription => (
          <Prescription
            data={prescription}
            className="mb-3"
            key={prescription.id}
          />
        ))}
      </Container>
      <ModalFinishRegistration
        show={showModal}
        handleClose={() => {}}
        backdrop="static"
      />
    </>
  )
}
