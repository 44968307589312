import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { Creators as AuthActions } from '~/store/ducks/auth'
import Input from '~/components/Form/Input'
import Button from '~/components/Button'
import { Section } from './styles'
import ModalForgotPassword from './ModalForgotPassword/index'

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Digite um email válido')
    .required('Digite um email'),
  password: Yup.string().required('Digite uma senha'),
})

export default function Login() {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: LoginSchema,
  })
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const dispatch = useDispatch()

  const onSubmit = data => {
    dispatch(AuthActions.storeSessionRequest(data))
  }

  return (
    <Section>
      <Container>
        <Row>
          <Col>
            <h1 className="text-center mb-4">Login</h1>
          </Col>
        </Row>
        <hr className="mb-5" />
        <Row className="justify-content-md-center">
          <Col lg={5} className="mb-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col xs={12}>
                  <Input
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="nome@email.com"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.email}
                    ref={register}
                  />
                </Col>
                <Col xs={12}>
                  <Input
                    type="password"
                    name="password"
                    label="Senha"
                    placeholder="******"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.password}
                    ref={register}
                  />
                </Col>
                <Col xs={12}>
                  <div className=" my-3">
                    <a
                      href="#"
                      onClick={() => setShowForgotPassword(true)}
                      className="px-0 color black"
                    >
                      <b>
                        <u>Esqueci a senha</u>
                      </b>
                    </a>
                  </div>
                </Col>
                <Col xs={12}>
                  <Button className="primary md my-3" type="submit">
                    Entrar
                  </Button>
                </Col>
              </Row>
            </form>
            <Row>
              <Col xs={12}>
                <div className=" my-4">
                  <Link to="/cadastro" className="px-0 color black">
                    Ainda não tem uma conta? <b>Cadastre-se</b>
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ModalForgotPassword
        show={showForgotPassword}
        handleClose={() => setShowForgotPassword(false)}
      />
    </Section>
  )
}
