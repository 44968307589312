import React, { useState, useEffect } from 'react'
import { Row, Col, ProgressBar } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Creators as AuthActions } from '~/store/ducks/auth'

import Yup from '~/helpers/yup'
import { Creators as ErrorActions } from '~/store/ducks/error'
import api from '~/services/api'
import { Container } from './styles'
import {
  Input,
  Select,
  TextArea,
  AutoComplete,
  Checkbox,
} from '~/components/Form'
import Button from '~/components/Button'
import StatesBrasil from '~/helpers/StatesBrasil.json'
import Diseases from '~/helpers/Diseases.json'
import Specialties from '~/helpers/Specialties.json'

const EditPhysicianSchema = Yup.object().shape({
  physician: Yup.object().shape({
    crm: Yup.string().required('Digite seu CRM'),
    uf: Yup.string().required('Selecione o UF do seu CRM'),
    specialty: Yup.string().required('Selecione sua especialidade'),
    about: Yup.string(),
  }),
})

export default function Edit({ history }) {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    validationSchema: EditPhysicianSchema,
  })
  // const error = useSelector(state => state.error)
  const { session } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const isResponsible = watch('isResponsible')

  useEffect(() => {
    register({ name: 'physician.diseases' })
  })

  useEffect(() => {
    if (session.physician)
      session.physician.diseases = Array.isArray(session.physician.diseases)
        ? session.physician.diseases.join(', ')
        : session.physician.diseases
    setValue('physician', session.physician)
  }, [session.physician])

  const optionsDiseases = ['Dor Cronica', 'Alzhaimer']

  const onSubmit = async data => {
    setLoading(true)
    try {
      await api.put(`/users/${session.id}`, data)
      dispatch(AuthActions.getSessionRequest())
      setLoading(false)
      const to = '/dashboard'
      history.push(to)
    } catch (e) {
      dispatch(ErrorActions.setError('Erro ao editar perfil.'))
      setLoading(false)
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="text--rb">Termine seu cadastro</h2>
        </Col>
      </Row>
      <div className="separator" />
      <Row>
        <Col>
          <h2 className="text--rb mb-3">Informações médicas</h2>
        </Col>
      </Row>
      <Row className="m-2">
        <Col>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg={8}>
                <Input
                  type="text"
                  label="CRM"
                  name="physician.crm"
                  placeholder="000000"
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.physician?.crm}
                  ref={register}
                />
              </Col>
              <Col lg={4}>
                <Select
                  type="text"
                  label="UF"
                  name="physician.uf"
                  size="md"
                  icon="icon-chevron-down"
                  className="my-3"
                  error={errors.physician?.uf}
                  ref={register}
                  fluid
                >
                  {StatesBrasil.map(state => (
                    <option value={state.value}>{state.value}</option>
                  ))}
                </Select>
              </Col>
              <Col lg={6}>
                <Select
                  type="text"
                  label="Especialidade"
                  name="physician.specialty"
                  size="md"
                  icon="icon-chevron-down"
                  className="my-3"
                  error={errors.physician?.specialty}
                  ref={register}
                  fluid
                >
                  {Specialties.map(specialty => (
                    <option value={specialty}>{specialty}</option>
                  ))}
                </Select>
              </Col>

              <Col span={6} lg={12}>
                <AutoComplete
                  id="diseases"
                  label="Lista de patologias atendidas" // TODO: ADICIONAR OS DEFAULTS
                  multiple={true}
                  options={Diseases}
                  onChange={(e, value) => setValue('physician.diseases', value)}
                  className="my-3"
                />
              </Col>

              <Col lg={12}>
                <TextArea
                  type="text"
                  label="Sobre"
                  name="physician.about"
                  placeholder="Conte um pouco sobre sua vida profissional e a área que atua. Esta informação ficará visível para pacientes quando o seu perfil for aprovado."
                  fluid
                  className="my-3"
                  error={errors.physician?.about}
                  ref={register}
                />
              </Col>

              <Col lg={4}>
                <Input
                  type="text"
                  label="Preço da consulta"
                  name="physician.price"
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.physician?.price}
                  ref={register}
                />
              </Col>

              <Col xs={12}>
                <Checkbox
                  className="my-3"
                  name="physician.prescribes"
                  label="Tenho experiência na prescrição de produtos à base de cannabis."
                  ref={register}
                  error={errors.physician?.prescribes}
                />
              </Col>

              <Col lg={4}>
                <Input
                  type="text"
                  label="Nome do(a) Secretário(a)"
                  name="physician.secretary_name"
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.physician?.secretary_name}
                  ref={register}
                />
              </Col>


              <Col lg={4}>
                <Input
                  type="text"
                  label="Email do(a) Secretário(a)"
                  name="physician.secretary_email"
                  size="md"
                  fluid
                  className="my-3"
                  error={errors.physician?.secretary_email}
                  ref={register}
                />
              </Col>

              <Col lg={4}>
                <Select
                  type="text"
                  label="Opções de email"
                  name="physician.secretary_option"
                  size="md"
                  icon="icon-chevron-down"
                  className="my-3"
                  error={errors.physician?.secretary_option}
                  ref={register}
                  fluid
                >
                  <option value="physician">Enviar apenas para mim</option>
                  <option value="secretary">Enviar apenas para secretária</option>
                  <option value="both">Enviar email para ambos</option>
                </Select>
              </Col>

              <Col xs={12}>
                <Button
                  type="submit"
                  disabled={loading}
                  className="primary md my-3"
                >
                  {loading ? 'Carregando...' : 'Continuar'}
                </Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Container>
  )
}
