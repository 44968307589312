import React, { useState, useEffect } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'
import { useParams, Link, useHistory } from 'react-router-dom'
import { useForm, useFormContext, FormContext } from 'react-hook-form'

import pdfIcon from '~/assets/icons/pdfIcon.png'
import { Container, Medicine } from './styles'
import api from '~/services/api'
import Button from '~/components/Button'
import ModalMedicine from './ModalMedicine'
import Thumb from '~/components/Thumb'
import { Input, Select } from '~/components/Form'
import ModalNewOrder from './ModalNewOrder'
import ModalSelectPhysician from '~/components/ModalSelectPhysician'
import { transform } from '~/shared/utils'
import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function Prescription() {
  const [prescription, setPrescription] = useState({ medicines: [] })
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [modalShow, setModalShow] = useState(false)
  const formMethods = useForm()
  const { register, watch, setValue } = useForm()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showModalNewOrder, setShowModalNewOrder] = useState(false)
  const [baseAnvisa, setBaseAnvisa] = useState('')
  const [basePrescription, setBasePrescription] = useState('')
  const [showLinkAnvisa, setShowLinkAnvisa] = useState(false)
  const [showLinkPrescription, setShowLinkPrescription] = useState(false)
  const [showModalSelectPhysician, setShowModalSelectPhysician] = useState(
    false,
  )
  const history = useHistory()
  const {
    review_prescription,
    review_anvisa,
    review_document,
    review_address,
  } = watch()

  const getPrescription = async () => {
    setLoading(true)
    const res = await api.get(`/prescriptions/${id}`)
    setPrescription(res.data)
    setValue('review_document', res.data.review_document)
    setValue('review_anvisa', res.data.review_anvisa)
    setValue('review_prescription', res.data.review_prescription)
    setValue('review_address', res.data.review_address)
    setLoading(false)
  }

  useEffect(() => {
    getPrescription()
  }, [])

  useEffect(() => {
    if (!loading) {
      const data = {
        review_address,
        review_prescription,
        review_anvisa,
        review_document,
      }
      api.put(`/prescriptions/${id}`, data)
    }
  }, [review_address, review_prescription, review_anvisa, review_document])

  const createOrder = async () => {
    setShowModalNewOrder(true)
  }

  const deletePrescription = async () => {
    setLoading(true)
    await api.delete(`/prescriptions/${id}`)
    history.push('/admin/prescricoes')
    setLoading(false)
  }

  const saveMedicines = async pivotMedicines => {
    setLoading(true)
    const medicinesData = prescription.medicines.map((medicine, idx) => {
      return { id: medicine.id, ...pivotMedicines.medicines[idx] }
    })
    const prescriptionData = {
      medicines: medicinesData,
    }
    await api.put(`/prescriptions/${prescription.id}`, prescriptionData)
    setLoading(false)
  }

  const closeSelectMedicines = async () => {
    await getPrescription()
    setModalShow(false)
  }

  const closeNewOrder = async () => {
    await getPrescription()
    setShowModalNewOrder(false)
  }

  const closeSelectPhysician = async () => {
    await getPrescription()
    setShowModalSelectPhysician(false)
  }

  useEffect(() => {
    if (prescription) {
      if (prescription.file) {
        let url = `/files/${prescription.file.id}`
        if (url) {
          transform(url).then(r => setBasePrescription(r))
        }
      }
    }
  }, [prescription.file])

  useEffect(() => {
    if (prescription) {
      if (prescription.anvisa) {
        let url = `/files/${prescription.anvisa.id}`
        if (url) {
          transform(url).then(r => setBaseAnvisa(r))
        }
      }
    }
  }, [prescription.anvisa])

  useEffect(() => {
    if (basePrescription.substr(0, 5) === 'data:') {
      setShowLinkPrescription(true)
    }
  }, [basePrescription])

  useEffect(() => {
    if (baseAnvisa.substr(0, 5) === 'data:') {
      setShowLinkAnvisa(true)
    }
  }, [baseAnvisa])

  return (
    <Container>
      <Row>
        <Col>
          <h2>Prescrição #{prescription.id}</h2>
        </Col>
      </Row>
      <Row>
        <Col className="my-3">
          <Alert show={showConfirmDelete} variant="danger">
            <Alert.Heading>Deseja mesmo deletar essa prescrição?</Alert.Heading>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={deletePrescription} className="danger">
                Deletar
              </Button>
            </div>
          </Alert>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={5}>
          <Row>
            <Col className="d-flex flex-column">
              <h4>Paciente:</h4>
              <p className="mt-2">{prescription.patient?.profile.full_name}</p>
              <Link
                className="btn-link mt-2"
                to={`/admin/pacientes/${prescription.patient?.id}`}
              >
                Perfil do paciente
              </Link>
            </Col>
            <Col className="d-flex flex-column">
              <h4>Médico:</h4>
              <p className="mt-2">
                {prescription.physician
                  ? prescription.physician.profile.full_name
                  : prescription.prePhysician
                  ? prescription.prePhysician.full_name
                  : 'Não informado'}
              </p>
              {prescription.physician && (
                <Link
                  className="btn-link mt-2"
                  to={`/admin/medicos/${prescription.physician?.id}`}
                >
                  Perfil do médico
                </Link>
              )}
              <a
                className="btn-link mt-2"
                href="#"
                onClick={() => setShowModalSelectPhysician(true)}
              >
                Selecionar Médico
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>Criado por:</h4>
              <p className="mt-2">
                {prescription.createdBy?.profile.full_name}
              </p>
            </Col>
            {prescription.orders?.length > 0 && (
              <Col className="my-3">
                <h4>Pedidos:</h4>
                {prescription.orders?.map(order => {
                  const orderStatus =
                    order.status === 'pendding'
                      ? 'Novo (em análise)'
                      : order.status === 'payment'
                      ? 'Aguardando pagamento'
                      : order.status === 'transport'
                      ? 'Aguardando produto'
                      : order.status === 'finished'
                      ? 'Em tratamento (finalizado)'
                      : order.status === 'cancelled'
                      ? 'Finalizado perdido (cancelado)'
                      : 'rever'

                  return (
                    <div className="my-2">
                      <Link
                        key={order.id}
                        className="btn-link"
                        to={`/admin/pedidos/${order.id}`}
                      >
                        Pedido {order.id} ({orderStatus})
                      </Link>
                    </div>
                  )
                })}
              </Col>
            )}
          </Row>
        </Col>
        <Col>
          <div className="d-flex">
            {prescription.file && (
              <a
                href={basePrescription}
                target="_blank" rel="noopener"
                className={showLinkPrescription ? '' : 'd-none'}
                download={`${prescription.patient?.profile.full_name}-${prescription.id}`}
              >
                <div className="file">
                  <img src={pdfIcon} />
                  <p>Prescrição</p>
                </div>
              </a>
            )}
            {prescription.anvisa && (
              <a
                href={baseAnvisa}
                target="_blank" rel="noopener"
                className={showLinkAnvisa ? '' : 'd-none'}
                download={`${prescription.patient?.profile.full_name}-${prescription.id}`}
              >
                <div className="file">
                  <img src={pdfIcon} />
                  <p>ANVISA</p>
                </div>
              </a>
            )}
          </div>
        </Col>
        <Col>
          <div className="d-flex flex-column">
            <Button className="primary md mb-3" onClick={createOrder}>
              Criar Pedido
            </Button>
          </div>

          <div>
            <Button
              className="outline-danger md"
              onClick={() => setShowConfirmDelete(true)}
            >
              Deletar
            </Button>
          </div>
        </Col>
      </Row>
      <form>
        <Row>
          <Col lg={3}>
            <Select
              ref={register}
              name="review_prescription"
              label="Prescrição"
              size="md"
              fluid
              icon="icon-chevron-down"
              className="my-3"
            >
              <option value="review">Não revisado</option>
              <option value="ok">Okey</option>
              <option value="fail">Não okey</option>
            </Select>
          </Col>
          <Col lg={3}>
            <Select
              ref={register}
              name="review_anvisa"
              label="Anvisa"
              size="md"
              fluid
              icon="icon-chevron-down"
              className="my-3"
            >
              <option value="review">Não revisado</option>
              <option value="ok">Okey</option>
              <option value="fail">Não okey</option>
            </Select>
          </Col>
          <Col lg={3}>
            <Select
              ref={register}
              name="review_document"
              label="Documento"
              size="md"
              fluid
              icon="icon-chevron-down"
              className="my-3"
            >
              <option value="review">Não revisado</option>
              <option value="ok">Okey</option>
              <option value="fail">Não okey</option>
            </Select>
          </Col>
          <Col lg={3}>
            <Select
              ref={register}
              name="review_address"
              label="Endereço"
              size="md"
              fluid
              icon="icon-chevron-down"
              className="my-3"
            >
              <option value="review">Não revisado</option>
              <option value="ok">Okey</option>
              <option value="fail">Não okey</option>
            </Select>
          </Col>
        </Row>
      </form>
      <form onSubmit={formMethods.handleSubmit(saveMedicines)}>
        <Row className="mt-3">
          <Col lg={12}>
            <h3>Produtos:</h3>
            {prescription.medicines && (
              <FormContext {...formMethods}>
                {prescription.medicines.map((medicine, idx) => (
                  <WindowedRow index={idx} item={medicine} />
                ))}
              </FormContext>
            )}
          </Col>
          <Col lg={12} className="d-flex justify-content-between">
            <Button
              className="primary mt-3"
              type="button"
              onClick={() => setModalShow(true)}
            >
              Selecionar produtos
            </Button>

            {prescription.medicines.length > 0 && (
              <Button className="primary mt-3" type="submit" disabled={loading}>
                Salvar Medicamentos
              </Button>
            )}
          </Col>
        </Row>
      </form>

      <ModalMedicine
        show={modalShow}
        onHide={closeSelectMedicines}
        prescriptionId={prescription.id}
      />
      <ModalNewOrder
        show={showModalNewOrder}
        onHide={closeNewOrder}
        prescriptionId={prescription.id}
      />

      <ModalSelectPhysician
        show={showModalSelectPhysician}
        onHide={closeSelectPhysician}
        prescriptionId={prescription.id}
      />
    </Container>
  )
}

const WindowedRow = React.memo(({ index, item }) => {
  const { register, errors } = useFormContext()

  return (
    <div>
      {index !== 0 && <hr className="separator" />}

      <Medicine key={item.id}>
        <div className="d-flex align-items-center">
          <div className="thumb">
            <Thumb>
              <div className="inner">
                <RenderImg
                  isPrivate={item.photo?.private}
                  photo_uuid={item.photo?.uuidUrl}
                  photo_id={item.photo?.id}
                />
                <img src={item.photo?.url} />
              </div>
            </Thumb>
          </div>
          <div className="name">{item.name}</div>
        </div>
        <div className="d-flex align-items-center">
          <Row>
            <Col md={2}>
              <div>
                <Input
                  className="my-3"
                  label="Qnt"
                  size="md"
                  fluid
                  name={`medicines[${index}].quantity`}
                  ref={register({ required: true })}
                  error={
                    errors.medicines ? errors.medicines[index]?.quantity : false
                  }
                  defaultValue={item.pivot.quantity}
                  type="number"
                />
              </div>
            </Col>
            <Col>
              <div>
                <Input
                  className="my-3"
                  label="Uso"
                  size="md"
                  fluid
                  ref={register({ required: true })}
                  defaultValue={item.pivot.use}
                  name={`medicines[${index}].use`}
                  error={
                    errors.medicines ? errors.medicines[index]?.use : false
                  }
                />
              </div>
            </Col>
            <Col>
              <div>
                <Input
                  className="my-3"
                  label="Duração"
                  size="md"
                  fluid
                  ref={register({ required: true })}
                  defaultValue={item.pivot.duration}
                  name={`medicines[${index}].duration`}
                  error={
                    errors.medicines ? errors.medicines[index]?.duration : false
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </Medicine>
    </div>
  )
})
