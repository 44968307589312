import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

import { BrandStyle } from './styles'
import api from '~/services/api'
import ProductImage from '~/assets/images/product-sample.jpg'
import { Card } from '~/components/Products/styles'
import { formatValue, paginateItens } from '~/shared/utils'
import Sprites from '~/assets/icons/sprites.svg'
import { ReactTinyLink } from 'react-tiny-link'
import { useSelector } from 'react-redux'
import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function Brand({ history }) {
  const { session } = useSelector(state => state.auth)
  const [medicines, setMedicines] = useState([])
  const [routeState] = useState('/produtos/')
  const [listLinks, setListLinks] = useState([])
  const [listLinksArticles, setListArticles] = useState([])
  const [brand, setBrand] = useState({})
  const [page, setPage] = useState(1)
  const [pageVideos, setPageVideos] = useState(1)
  const [pageArticles, setPageArticles] = useState(1)
  const { id } = useParams()

  const formatLinks = (links, set) => {
    if (links) {
      let newlistLinks = links.replace('{', '')
      newlistLinks = newlistLinks.replace('}', '')
      newlistLinks = newlistLinks.replace(/"/g, '')
      set(newlistLinks.split(','))
    }
  }

  const fetchMedicines = async () => {
    const res = await api.get(`/medicines?brand=${id}`)
    setMedicines(res.data.data)
  }

  const fetchBrand = async () => {
    const res = await api.get(`/brands/${id}`)
    formatLinks(res.data.links, setListLinks)
    formatLinks(res.data.articles, setListArticles)
    if (res.data) setBrand(res.data)
    else history.push('/')
  }

  const redirect = () => {
    if (
      localStorage.getItem('roles') !== 'administrator' &&
      localStorage.getItem('roles') !== 'physician'
    ) {
      if (
        id !== 'proprium-my-cannabis-code' &&
        id !== 'pacificool-cbd'
      ) {
        history.push('/')
      }
    }
  }

  useEffect(() => {
    fetchMedicines()
    fetchBrand()
    redirect()
  }, [])

  return (
    <BrandStyle>
      <section class="store-hero">
        <div
          class="bg"
          style={{
            backgroundImage: `url(${
              brand.cover
                ? brand.cover.uuidUrl
                : 'https://api-drcannabis.s3.us-east-2.amazonaws.com/manufacturers/covers/c044ff43-4a7b-4cd4-8cdc-90718a608529.jpg'
            })`,
          }}
        ></div>
        <div class="jumbotron jumbotron-fluid">
          <div class="container">
            <div class="row my-5">
              <div class="col-8">
                <RenderImg
                  photo_id={brand.logo?.id}
                  photo_uuid={brand.logo?.uuidUrl}
                  isPrivate={brand.logo?.private}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="d-flex justify-content-center mb-3">
        <h5 className="font-primary font-weight-bold brand--text font-size-20">
          Produtos
        </h5>
      </div>
      <div
        id="carouselProducts"
        className="carousel slide bg-gray px-5"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active d-flex row ml-1">
            {paginateItens(medicines, page, 3).map(product => (
              <Col
                className="d-flex d-lg-block justify-content-center mt-3 col-sm-6 col-lg-4"
                key={product.id}
              >
                <Card
                  className="d-flex flex-column mb-4 bg-white"
                  style={{
                    'min-height': '570px',
                  }}
                >
                  {product.photo ? (
                    <RenderImg
                      photo_uuid={product.photo.uuidUrl}
                      photo_id={product.photo.id}
                      isPrivate={product.photo.private}
                      style={{ cursor: 'pointer' }}
                      onclickImg={() =>
                        history.push(`${routeState}${product.slug}`)
                      }
                      imgSecond={ProductImage}
                      alt="Produto"
                    />
                  ) : (
                    <img
                      src={ProductImage}
                      alt="Produto"
                      onClick={() =>
                        history.push(`${routeState}${product.slug}`)
                      }
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  <div className="d-flex flex-column p-3 pr-4">
                    <h4
                      className="name text--rm text--black-dark mt-2 mt-md-3 mb-2"
                      onClick={() =>
                        history.push(`${routeState}${product.slug}`)
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      {product.name}
                    </h4>
                    <div className="d-flex justify-content-between">
                      <div className="mt-2">
                        <p className="mb-3">
                          <Link to={`/marca/${product.brand.slug}`}>
                            {product.brand.name}
                          </Link>
                        </p>
                        <div className="d-flex">
                          <p className="text--rr text--black-dark mb-5">
                            {formatValue(product.price, product.currency)}
                          </p>
                          {product.price_previous &&
                            (
                              (product.price * 100) / product.price_previous -
                              100
                            ).toFixed(2) < 0 && (
                              <p className="pl-3 text--black-dark text--rm value text-danger">
                                <strike>
                                  {formatValue(
                                    product.price_previous,
                                    product.currency,
                                  )}
                                </strike>{' '}
                                <span className="value value secondary ml-3 text-success">
                                  {(
                                    (product.price * 100) /
                                      product.price_previous -
                                    100
                                  ).toFixed(2)}
                                  %
                                </span>
                              </p>
                            )}
                        </div>
                        <p className="pl-3 text--black-dark text--rm value">
                          {product.presentation}
                        </p>
                      </div>
                    </div>
                    <Link
                      to={`${routeState}${product.slug}`}
                      className="anchor text--sm text-decoration-none d-flex align-items-center justify-content-end"
                    >
                      Ver mais
                      <span className="ml-1">
                        <svg className="icon">
                          <use xlinkHref={`${Sprites}#icon-angle-right`} />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </Card>
              </Col>
            ))}
          </div>
        </div>
        <a
          className={'carousel-control-prev d-flex justify-content-start ml-3'}
          href="#carouselProducts"
          role="button"
          data-slide="prev"
          onClick={() => page > 1 && setPage(page - 1)}
        >
          <i className="fas fa-chevron-left fa-2x text-black-50" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next d-flex justify-content-end mr-2"
          href="#carouselProducts"
          role="button"
          data-slide="next"
          onClick={() =>
            page < Math.ceil(medicines.length / 3) && setPage(page + 1)
          }
        >
          <i className="fas fa-chevron-right fa-2x text-black-50" />
          <span className="sr-only">Next</span>
        </a>
      </div>

      {listLinks.length > 0 && listLinks[0] !== '' && (
        <>
          <div className="d-flex justify-content-center py-4 bg-gray">
            <h5 className="font-primary font-weight-bold brand--text font-size-20">
              Videos
            </h5>
          </div>

          <div
            id="carouselVideos"
            className="carousel slide bg-gray px-5"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active d-flex row ml-1">
                {paginateItens(listLinks, pageVideos, 3).map(links => (
                  <div className="col-sm-6 col-lg-4 my-3">
                    <ReactTinyLink
                      cardSize="large"
                      showGraphic={true}
                      maxLine={3}
                      minLine={1}
                      url={links}
                    />
                  </div>
                ))}
              </div>
            </div>
            <a
              className={
                'carousel-control-prev d-flex justify-content-start ml-3'
              }
              href="#carouselVideos"
              role="button"
              data-slide="prev"
              onClick={() => pageVideos > 1 && setPageVideos(pageVideos - 1)}
            >
              <i className="fas fa-chevron-left fa-2x text-black-50" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className={
                'carousel-control-next d-flex justify-content-end mr-2'
              }
              href="#carouselVideos"
              role="button"
              data-slide="next"
              onClick={() =>
                pageVideos > Math.ceil(medicines.length / 3) &&
                setPageVideos(pageVideos + 1)
              }
            >
              <i className="fas fa-chevron-right fa-2x text-black-50" />
              <span className="sr-only">Next</span>
            </a>
          </div>
        </>
      )}
      {listLinksArticles.length > 0 && listLinksArticles[0] != '' && (
        <>
          <div className="d-flex justify-content-center py-4 bg-gray">
            <h5 className="font-primary font-weight-bold brand--text font-size-20">
              Artigos
            </h5>
          </div>
          <div
            id="carouselArticles"
            className="carousel slide bg-gray px-5"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active d-flex row ml-1">
                {paginateItens(listLinksArticles, pageArticles, 3).map(
                  links => (
                    <div className="col-sm-6 col-lg-4 my-3">
                      <ReactTinyLink
                        cardSize="large"
                        showGraphic={true}
                        maxLine={3}
                        minLine={1}
                        url={links}
                      />
                    </div>
                  ),
                )}
              </div>
            </div>
            <a
              className={
                'carousel-control-prev d-flex justify-content-start ml-3'
              }
              href="#carouselArticles"
              role="button"
              data-slide="prev"
              onClick={() =>
                pageArticles > 1 && setPageArticles(pageArticles - 1)
              }
            >
              <i className="fas fa-chevron-left fa-2x text-black-50" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next d-flex justify-content-end mr-2"
              href="#carouselArticles"
              role="button"
              data-slide="next"
              onClick={() =>
                pageArticles < Math.ceil(listLinksArticles.length / 3) &&
                setPageArticles(pageArticles + 1)
              }
            >
              <i className="fas fa-chevron-right fa-2x text-black-50" />
              <span className="sr-only">Next</span>
            </a>
          </div>
        </>
      )}
    </BrandStyle>
  )
}
