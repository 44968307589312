import styled from 'styled-components'

import { Variables as V } from '~/styles/global'

export const SectionFaq = styled.section`
  .title {
    @media only screen and (max-width: 575px) {
      font-size: 2.3rem;
      color: ${V.colorBlackDark};
    }
  }

  .text {
    line-height: 1.7;
  }

  .subtitle {
    @media only screen and (max-width: 575px) {
      font-size: 1.6rem;
      color: ${V.colorBlackDark};
    }
  }
`

export const SectionQuestions = styled.section`
  margin: 2rem 0;

  .answer {
    line-height: 1.5;
    font-size: 1.2rem;
  }
`

export const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 30px;
  padding: 2rem;
  border-top: solid 1px #ccc;

  svg {
    width: 20px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
  }
`
