import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'

import api from '~/services/api'
import { Container, Medicine } from './styles'
import Button from '~/components/Button'
import Thumb from '~/components/Thumb'
import ModalSelectMedicines from '~/components/ModalSelectMedicines'

export default function AskMedicine({ next, data, refresh }) {
  const { medicines } = data
  const [modalShow, setModalShow] = useState(false)

  const goAskAnvisa = async () => {
    const prescription = {
      status: 'askAnvisa',
    }
    await api.put(`/prescriptions/${data.id}`, prescription)
    next('askAnvisa')
  }

  const goAskPhysician = async () => {
    const prescription = {
      status: 'askPhysician',
    }
    await api.put(`/prescriptions/${data.id}`, prescription)
    next('askPhysician')
  }

  const closeSelectMedicines = async () => {
    await refresh(data.id)
    setModalShow(false)
  }

  return (
    <Container>
      <Alert variant="success">
        <p>Vamos te ajudar, para isso precisamos de duas informações:</p>
        <ol className="pl-4 m-2">
          <li>Produtos prescritos</li>
          <li>Médico que prescreveu</li>
        </ol>
      </Alert>

      <h4 className={`${medicines.length ? 'mb-2' : 'mb-4'} text-center`}>
        Indique os produtos que constam na sua prescrição médica.
      </h4>
      <p></p>
      {medicines.map(medicine => (
        <Medicine key={medicine.id}>
          <div className="thumb">
            <Thumb>
              <div className="inner">
                <img src={medicine.photo?.url} />
              </div>
            </Thumb>
          </div>
          <div className="name">{medicine.name}</div>
        </Medicine>
      ))}
      <div className="d-flex justify-content-center mt-3 mb-4">
        <Button className="white" onClick={() => setModalShow(true)}>
          Selecionar produtos
        </Button>
      </div>
      <div className="d-flex justify-content-between">
        <Button className="primary" onClick={goAskAnvisa}>
          Voltar
        </Button>

        <Button className="primary" onClick={goAskPhysician}>
          Próximo
        </Button>
      </div>

      <ModalSelectMedicines
        show={modalShow}
        onHide={closeSelectMedicines}
        prescriptionId={data.id}
      />
    </Container>
  )
}
