import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import Button from '~/components/Button'
import api from '~/services/api'
import { Container, ThumbsContainer } from './styles'
import Thumb from '~/components/Thumb'
import PhotoProfile from '~/assets/images/foto_perfil.svg'
import { CropModal } from '~/components/CropModal'
import pdfIcon from '~/assets/icons/pdfIcon.png'

export default function Previews({
  callback,
  height,
  aspect = 1,
  assetType = '',
  widthCrop = 234,
  isPrivate = true,
}) {
  const [files, setFiles] = useState([])
  const [showPdfIcon, setShowPdfIcon] = useState(false)
  const [showCrop, setShowCrop] = useState(false)

  useEffect(() => {
    if (files[0]) {
      if (files[0].id) {
        callback(files[0].id)
      }
    }
  }, [files])

  const sendFile = async (file, isPrivate, assetType) => {
    try {
      var formData = new FormData()
      formData.append('file', file)
      formData.append('assetType', assetType)
      formData.append('isPrivate', isPrivate)
      const res = await api.post('/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return res
    } catch (e) {
      console.error(e)
    }
  }
  const { getRootProps, getInputProps } = useDropzone({
    noKeyboard: true,
    onDrop: async acceptedFiles => {
      const res = await sendFile(acceptedFiles[0], isPrivate, assetType)
      if (res.data) {
        setFiles(
          acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        )
        callback(res.data.id)
        if (res.data.type !== 'application') {
          setShowCrop(true)
        } else {
          setShowPdfIcon(true)
        }
      }
    },
  })

  let thumbs = (
    <Thumb>
      <div className="inner">
        <img src={PhotoProfile} alt="" />
      </div>
    </Thumb>
  )

  if (files.length) {
    thumbs = files.map(file => (
      <Thumb key={file.name}>
        <div className="inner">
          <img src={showPdfIcon ? pdfIcon : file.preview} alt="" />
        </div>
      </Thumb>
    ))
  }

  useEffect(
    () => () => {
      files.forEach(file => URL.revokeObjectURL(file.preview))
    },
    [files],
  )

  return (
    <Container>
      <ThumbsContainer>{thumbs}</ThumbsContainer>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Button
          type="button"
          className="blue sm m-3"
          onClick={e => e.preventDefault()}
        >
          Buscar
        </Button>
      </div>
      {showCrop && (
        <CropModal
          files={files}
          setShowCrop={setShowCrop}
          setFiles={setFiles}
          route="/files"
          height={height}
          aspect={aspect}
          width={widthCrop}
          isPrivate={isPrivate}
        />
      )}
    </Container>
  )
}
