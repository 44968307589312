import React, { useState, useEffect } from 'react'
import { Alert, Spinner } from 'react-bootstrap'

import { Container } from './styles'
import Order from './Order'
import api from '~/services/api'

export default function Orders() {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)

  const getOrders = async () => {
    setLoading(true)
    const res = await api.get('/orders')
    setOrders(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getOrders()
  }, [])

  return (
    <Container>
      <div className="mb-4">
        <h3 className="text--rb mb-2">Pedidos</h3>
        {loading && (
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" />
          </div>
        )}

        {orders.length > 0 &&
          orders.map(order => (
            <Order refresh={getOrders} className="mb-3" data={order} />
          ))}

        {!loading && orders.length === 0 && (
          <Alert variant="warning my-3 p-3">
            {' '}
            Você ainda não tem pedidos ativos. Assim que fizer um pedido ele
            estará listado aqui.
          </Alert>
        )}
      </div>
      <div className="d-none">
        <h3 className="text--rb">Concluídos</h3>
      </div>
    </Container>
  )
}
