import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;

  .file {
    padding: 0 1rem;
    img {
      width: 60px;
    }
    p {
      margin-top: 0.5rem;
      text-align: center;
    }
  }

  h5 {
    font-family: sarabunMedium;
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 0.3rem;
  }
`
