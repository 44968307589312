import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Row, Col, Alert } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import { Creators as ErrorActions } from '~/store/ducks/error'
import api from '~/services/api'
import { Container } from './styles'
import Thumb from '~/components/Thumb'
import Sprites from '~/assets/icons/sprites.svg'
import pdfIcon from '~/assets/icons/pdfIcon.png'
import Button from '~/components/Button'
import { Input } from '~/components/Form'

export default function SendAnvisa({
  data,
  next,
  userMail,
  physicianEmail,
  ...rest
}) {
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(true)
  const dispatch = useDispatch()

  const cancelFile = () => {
    setFiles([])
  }

  const sendFile = async (file, type) => {
    try {
      var formData = new FormData()
      formData.append('assetType', 'anvisa')
      formData.append('file', file)
      formData.append('assetType', type)
      const res = await api.post('/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return res
    } catch (e) {
      dispatch(ErrorActions.setError('Erro ao subir arquivo'))
    }
  }
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, application/pdf',
    noKeyboard: true,
    multiple: false,
    onDrop: async acceptedFiles => {
      const res = await sendFile(acceptedFiles[0], 'anvisa')
      if (res?.data) {
        setFiles(
          acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              id: res.data.id,
            }),
          ),
        )
      }
    },
  })

  const onSubmit = async () => {
    if (files.length > 0) {
      setLoading(true)
      try {
        const prescription = {
          user_email: userMail,
          anvisa_id: files[0].id,
          status: 'quotation',
          physician_email: physicianEmail,
        }
        await api.put(`/prescriptions/${data.id}`, prescription)
        next('quotation')
        setLoading(false)
      } catch (e) {
        dispatch(ErrorActions.setError('Erro ao editar prescrição'))
        setLoading(false)
      }
    } else {
      dispatch(ErrorActions.setError('Selecione um arquivo para upload'))
    }
  }

  const goAskAnvisa = async () => {
    const prescription = {
      status: 'askAnvisa',
    }
    await api.put(`/prescriptions/${data.id}`, prescription)
    next('askAnvisa')
  }

  let thumbs = (
    <Thumb>
      <div className="inner">
        <svg className="m-4">
          <use xlinkHref={`${Sprites}#icon-plus`} />
        </svg>
      </div>
    </Thumb>
  )

  if (files.length) {
    thumbs = files.map(file => (
      <Thumb key={file.name}>
        <div className="inner">
          {file.type.split('/')[0] !== 'image' && (
            <img src={pdfIcon} alt="preview" />
          )}
          {file.type.split('/')[0] === 'image' && (
            <img src={file.preview} alt="preview" />
          )}
        </div>
        <div className="cancel">x</div>
      </Thumb>
    ))
  }

  return (
    visible && (
      <Container {...rest}>
        <Alert variant="success">
          Você está mais próximo do seu tratamento com cannabis medicinal. Anexe
          sua autorização e siga para a próxima etapa.
        </Alert>
        <Row>
          <Col>
            <h4 className="mb-3 text-center">Autorização Anvisa</h4>
          </Col>
        </Row>
        <Row className="w-100">
          <Col lg={2} className="d-flex justify-content-center">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              {thumbs}
            </div>
            {files.length > 0 && (
              <div className="cancel" onClick={cancelFile}>
                x
              </div>
            )}
          </Col>
          <Col lg={9} className="align-self-end">
            <div>
              <small>JPG ou PDF até 2mb</small>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Input
                  size="sm"
                  type="text"
                  name="file"
                  disabled
                  fluid
                  placeholder="Clique para anexar seu documento."
                  value={
                    files[0]
                      ? files[0].name
                      : 'Clique para anexar seu documento.'
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="d-flex justify-content-between mt-4">
            <Button className="primary" onClick={goAskAnvisa}>
              Voltar
            </Button>

            <Button className="primary float-right" onClick={() => onSubmit()}>
              Enviar
            </Button>
          </Col>
        </Row>
      </Container>
    )
  )
}
