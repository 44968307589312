import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Pagination from 'react-js-pagination'

import { SectionFilters } from './style'
import Cards from '~/components/Products'
import Input from '~/components/Form/Input'
import Select from '~/components/Form/Select'
import api from '~/services/api'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { SectionPillars } from '~/pages/About/styles'

export default function Products(props) {
  const { register, watch } = useForm()
  const [medicines, setMedicines] = useState({ data: [] })
  const [loading, setLoading] = useState(false)
  const [brands, setBrands] = useState([])
  const [filters, setFilters] = useState({ cbdmg: [] })
  const filter = watch(['name', 'cbdmg', 'spectrum', 'type', 'brand'])
  const { session } = useSelector(state => state.auth)

  const getMedicines = async page => {
    setLoading(true)
    const res = await api.get(`/medicines`, {
      params: {
        page,
        name: filter.name,
        cbdmg: filter.cbdmg,
        spectrum: filter.spectrum,
        type: filter.type,
        brand: filter.brand,
      },
    })
    setMedicines(res.data)
    setLoading(false)
  }

  const getBrands = async () => {
    setLoading(true)
    const res = await api.get('/brands')
    setBrands(res.data)
    setLoading(false)
  }

  const getFilters = async () => {
    const res = await api.get('/medicines/filters')
    const cbdmg = res.data.cbdmg

    cbdmg.sort((a, b) => {
      if (parseInt(a) < parseInt(b)) return -1
      if (parseInt(a) > parseInt(b)) return 1
    })
    setFilters({ cbdmg })
  }

  useEffect(() => {
    getBrands()
    getFilters()
  }, [])

  useEffect(() => {
    getMedicines()
  }, [filter.name, filter.cbdmg, filter.spectrum, filter.type, filter.brand])

  const title='Dr. Cannabis - Produtos'
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
      </Helmet>
      <SectionFilters className="mt-5 col-12">
        <Container fluid="lg">
          {session &&
            session.roles &&
            (session.roles[0] === 'administrator' ||
              session.roles[0] === 'physician') && (
              <>
                <Row>
                  <Col lg={4}>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Buscar"
                      size="md"
                      fluid
                      icon="icon-search"
                      ref={register}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="my-4">
                    <Select
                      type="text"
                      name="brand"
                      size="md"
                      ref={register}
                      fluid
                    >
                      <option value="">Marcas</option>
                      {brands.map(brand => (
                        <option value={brand.slug}>{brand.name}</option>
                      ))}
                    </Select>
                  </Col>
                  <Col xs={12} lg={3} className="my-4">
                    <Select
                      ref={register}
                      name="cbdmg"
                      size="md"
                      fluid
                      icon="icon-chevron-down"
                      className="w-100"
                    >
                      <option value="">Concentração</option>
                      {filters.cbdmg.map(filter => (
                        <option value={filter}>{filter}</option>
                      ))}
                    </Select>
                  </Col>
                  <Col xs={12} lg={3} className="my-4">
                    <Select
                      ref={register}
                      name="spectrum"
                      size="md"
                      fluid
                      icon="icon-chevron-down"
                      className="w-100"
                    >
                      <option value="">Espectro</option>
                      <option value="Full Spectrum">Full Spectrum</option>
                      <option value="Isolado">Isolado</option>
                      <option value="Broad Spectrum">Broad Spectrum</option>
                    </Select>
                  </Col>
                  <Col xs={12} lg={3} className="my-4">
                    <Select
                      ref={register}
                      name="type"
                      size="md"
                      fluid
                      icon="icon-chevron-down"
                      className="w-100"
                    >
                      <option value="">Apresentação</option>
                      <option value="Óleo">Óleo</option>
                      <option value="Cápsula">Cápsula</option>
                      <option value="Tópico">Tópico</option>
                      <option value="Spray">Spray</option>
                      <option value="Pasta">Pasta</option>
                    </Select>
                  </Col>
                </Row>
              </>
            )}
          {loading ? (
            <Spinner animation="grow" />
          ) : (
            <Row>
              <Cards products={medicines.data} brands={brands} />
            </Row>
          )}
          {!loading && medicines.data.length === 0 && (
            <h3 className="text-center my-4">Nenhum produto encontrado</h3>
          )}
          {session &&
            session.roles &&
            (session.roles[0] === 'administrator' ||
              session.roles[0] === 'physician') && (
              <Row>
                <Col className="d-flex justify-content-center my-3">
                  <Pagination
                    activePage={medicines.page}
                    itemsCountPerPage={medicines.perPage}
                    totalItemsCount={medicines.total}
                    pageRangeDisplayed={5}
                    onChange={getMedicines}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </Col>
              </Row>
            )}
        </Container>
      </SectionFilters>
    </>
  )
}
