import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;
  .count-physicians {
    p {
    color: #5c5c5c;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    font-size: 24px;
    cursor: pointer;
    }
    p:hover {
    color: #72C7B6;
    }
  }
  .search-button {
    background: #1c2960;
    height: 50px;
  }
`

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 140px;
  background: #72c7b6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #fff;

  .title {
    font-size: 24px;
  }

  .count {
    font-size: 50px;
  }
`
