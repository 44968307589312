import styled from 'styled-components'

export const SectionFilters = styled.section`
  h1 {
    font-size: 2rem;
  }
  .brands {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    .brand-logo {
      height: 90px;
      width: 90px;
      margin: 0 15px;
      object-fit: contain;
    }
  }
`
