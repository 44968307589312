import styled from 'styled-components'

import { Variables as V } from '~/styles/global'

export const Container = styled.header`
  position: relative;
  height: 4rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;

  .logo {
    width: 168px;
    height: 48px;
  }

  .avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0.7rem;
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .content {
    margin: 0 3.6rem;
    @media only screen and (min-width: 768px) and (max-width: 930px) {
      margin: 0 1rem;
    }
  }

  .anchor {
    font-size: 1.1rem;
    transition: all 0.2s;
    @media only screen and (min-width: 768px) and (max-width: 930px) {
      display: flex;
      align-items: center;
      text-align: center;
      .header:not(:last-of-type) {
        margin-right: 1rem;
      }
    }

    .header:hover {
      color: ${V.colorPrimary};
      transition: all 0.2s;
    }
  }

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1.9rem;
    fill: #000000;
    cursor: pointer;
    transition: all 0.2s;

    .header:hover {
      fill: ${V.colorPrimary};
      transition: all 0.2s;
    }

    @media only screen and (min-width: 768px) and (max-width: 930px) {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }
  }
`

export const ContainerMobile = styled.header`
  height: 4.5rem;

  .icon {
    width: 2rem;
    height: 2rem;
    fill: #000000;
  }

  .img {
    width: 11.3rem;
  }
`

export const Nav = styled.nav`
  padding: 0;
  ul {
    justify-items: center;
    justify-content: space-evenly;
    flex: 1;
    li {
      display: flex;
      > a {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 21px;
        color: #000;
      }
    }
  }
`

export const MenuMobile = styled.div`
  background-color: ${V.colorWhite};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  z-index: 9;
  transform: translateX(-100%);
  overflow-y: scroll;

  &.open {
    transform: translateX(0%) !important;
  }

  .icon {
    width: 2rem;
    height: 2rem;
    fill: ${V.colorBlackDark};

    &.close {
      transform: rotate(133deg);
    }
  }

  .item {
    margin-bottom: 2.4rem;
  }

  .anchor {
    color: ${V.colorBlackDark};
    font-size: 1.5rem;

    &:hover {
      color: ${V.colorPrimary};
    }

    &.selected {
      border-bottom: 2px solid ${V.colorPrimary};
      padding-bottom: 0.3rem;
    }
  }
`
