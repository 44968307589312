import styled from 'styled-components'

export const Section = styled.section`
  margin: 4rem 0;

  h1 {
    font-weight: bold;
    color: #000000;
    text-align: center;
  }
`
