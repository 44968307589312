import React from 'react'
import NumberFormat from 'react-number-format'
import PhysicianImg from '~/assets/images/physician-default.png'
import { formatDate, formatText } from '~/shared/utils'
import { RenderImg } from '~/components/RenderImg/RenderImg'

const PhysicianCard = ({ data, phone, email, ...rest }) => {
  const profile = data.user?.profile
  const prefix = profile?.gender === 'm' ? 'Dr.' : 'Dra.'

  const getClass = status => {
    switch (status) {
      case 'approved':
        return 'fa fa-check-circle physician-card-icon-vector mt-1 mr-1 text-white'
      case 'disapproved':
        return 'fa fa-times-circle physician-card-icon-vector mt-1 mr-1 text-white'
      case 'pending':
        return 'fa fa-question-circle physician-card-icon-vector mt-1 mr-1 text-white'
      default:
        return ''
    }
  }

  const getContainerClass = status => {
    switch (status) {
      case 'approved':
        return 'physician-card-container-approve physician-card-success'
      case 'disapproved':
        return 'physician-card-container-disapprove physician-card-disapprove'
      case 'pending':
        return 'physician-card-container-pending physician-card-pending'
      default:
        return ''
    }
  }

  return (
    <div
      className={`card physician-card mt-4 ${getContainerClass(data.status)}`}
    >
      <div className="row no-gutters">
        <div className="col-3 d-flex flex-wrap">
          { profile?.avatar?.id ? (
            <RenderImg
              photo_id={profile?.avatar.id}
              photo_uuid={profile?.avatar.uuidUrl}
              isPrivate={profile?.avatar.private}
              classNameImg="mx-auto physician-card-avatar"
              alt="Avatar"
            />
          ) : (
            <img
              className="mx-auto physician-card-avatar"
              src={PhysicianImg}
              alt="Avatar"
            />
          )}
        </div>
        <div className="col-8">
          <div className="card-body pl-0">
            <h4 className="card-title">
              {prefix} {profile?.full_name}{' '}
            </h4>
            <div className="d-flex justify-content-between">
              <div>
                <p className="card-text">
                  {profile?.phone_number && (
                    <>
                      <i className="fa fa-phone mx-2" />
                      <NumberFormat
                        value={profile?.phone_number}
                        displayType={'text'}
                        format="(##) #####-####"
                      />
                    </>
                  )}
                </p>
                <p className="card-text">
                  {profile?.mobile_number && (
                    <div className="d-flex mt-1">
                      <i className="fa fa-mobile-alt mt-2 d-block mx-2" />
                      <NumberFormat
                        value={profile?.mobile_number}
                        displayType={'text'}
                        format="(##) #####-####"
                        className="mt-2"
                      />
                    </div>
                  )}
                </p>
                <p className="card-text">
                  {data.user?.email && (
                    <div className="d-flex mt-1">
                      <i className="fa fa-envelope mt-2 d-block mx-2" />
                      <span className="mt-2">{data.user.email}</span>
                    </div>
                  )}
                </p>
                <p className="card-text">
                  {data.user?.addresses.length > 0 && (
                    <div className="d-flex mt-1">
                      <i className="fa fa-map-marker-alt mt-2 d-block mx-2" />
                      <span className="mt-2">
                        {data.user.addresses[0].city}
                      </span>
                    </div>
                  )}
                </p>
                <p className="card-text">
                  <div className="d-flex mt-1">
                    <i className="fa fa-calendar-alt mt-2 d-block mx-2" />
                    <span className="mt-2">
                      Cadastro: {formatDate(data.created_at)}
                      <br />
                      {formatDate(data.evaluation_date) && (
                        <span>
                          Liberação: {formatDate(data.evaluation_date)}
                        </span>
                      )}
                    </span>
                  </div>
                </p>
                <p className="card-text">
                  {data.price && (
                    <div className="d-flex mt-1">
                      <i className="fas fa-dollar-sign mt-2 mx-2" />
                      <span className="mt-2">{data.price}</span>
                    </div>
                  )}
                </p>
              </div>
              <div>
                <h4>
                  <span
                    className="badge badge-secondary w-100 text--black font-weight-normal"
                    style={{ 'background-color': '#72c7b6' }}
                  >
                    {formatText(data.specialty, 30)}
                  </span>
                </h4>
                <h4>
                  <span
                    className="badge badge-secondary w-100 mt-1 text--black font-weight-normal"
                    style={{ 'background-color': '#72c7b6' }}
                  >
                    {data.prescribes ? 'É prescritor' : 'Não é prescritor'}
                  </span>
                </h4>
                {data.online_consultation && (
                  <h4>
                    <span
                      className="badge badge-secondary w-100 mt-1 text--black font-weight-normal"
                      style={{ 'background-color': '#72c7b6' }}
                    >
                      Faz Telemedicina
                    </span>
                  </h4>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-1 d-flex justify-content-end">
          <i className={getClass(data.status)} />
        </div>
      </div>
    </div>
  )
}

export { PhysicianCard }
