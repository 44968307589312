import React from 'react'
import useAutocomplete from '@material-ui/lab/useAutocomplete'
import NoSsr from '@material-ui/core/NoSsr'
import CheckIcon from '@material-ui/icons/Check'

import { Label, InputWrapper, Listbox, Tag } from './styles'

export default function CustomizedHook({ label, className, ...rest }) {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    ...rest,
  })

  return (
    <NoSsr>
      <div className={className}>
        <div {...getRootProps()}>
          <Label {...getInputLabelProps()}>{label}</Label>
          <InputWrapper
            ref={setAnchorEl}
            className={`${rest.border} ${focused ? 'focused' : ''}`}
          >
            {value.map((option, index) => (
              <Tag label={option} {...getTagProps({ index })} />
            ))}

            <input {...getInputProps()} />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li {...getOptionProps({ option, index })}>
                <span>{option}</span>
                <CheckIcon fontSize="small" />
              </li>
            ))}
          </Listbox>
        ) : null}
      </div>
    </NoSsr>
  )
}
