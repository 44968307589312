import React from 'react'

import { Row, Col, Container } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'

import { Ftr } from './styles'
import Logo from '~/assets/images/logo.jpg'
import Sprites from '~/assets/icons/sprites.svg'
import { useSelector } from 'react-redux'

export default function Footer() {
  const location = useLocation()
  const { session } = useSelector(state => state.auth)
  if (location.pathname.split('/').includes('template')) return null

  return (
    <Ftr className="pb-3">
      <Container fluid="md">
        <Row>
          <Col xs={5} className="justify-content-md-end d-md-block col-md-4">
            <img
              className="d-flex align-items-center logo"
              src={Logo}
              alt="Logo Dr Cannabis"
            />
            <ul className="list-unstyled ml-4 mt-4"/>
          </Col>
          <Col xs={7} className=" d-flex col-md-4 pt-md-5 pl-5">
            <ul className="list-unstyled">
              <li className="mb-4">
                <Link
                  to="/sobre"
                  className="text anchor text--rm text--black-dark text-decoration-none"
                >
                  Sobre nós
                </Link>
              </li>
              {session &&
                session.roles &&
                (session.roles[0] === 'administrator' ||
                  session.roles[0] === 'physician') && (
                  <li className="mb-4">
                    <Link
                      to={
                        session.roles[0] === 'administrator'
                          ? '/admin/lista/produtos'
                          : '/medico/lista/produtos'
                      }
                      className="text anchor text--rm text--black-dark text-decoration-none"
                    >
                      Produtos
                    </Link>
                  </li>
                )}
              <li className="mb-4">
                <Link
                  to="/faq"
                  className="text anchor text--rm text--black-dark text-decoration-none"
                >
                  FAQ
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="/comprar-cbd"
                  className="text anchor text--rm text--black-dark text-decoration-none"
                >
                  Como comprar CBD
                </Link>
              </li>
              <li className="mb-4">
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSe8ZHsuDTQNY3hdJXnurBM_vmzFUgsiXWdvaWGxyhEYEXD4zw/viewform"
                  target="_blank"
                  rel="noopener"
                  className="text anchor text--rm text--black-dark text-decoration-none"
                >
                  Seu produto na Dr. Cannabis
                </a>
              </li>
              <li className="mb-4">
                <p className="text text--rm text--black-dark">Fale conosco:</p>
              </li>
              <li className="mb-4">
                <p className="text text--rr text--black-dark">
                  contato@drcannabis.com.br
                </p>
              </li>
            </ul>
          </Col>
          <Col
            sm={12}
            md={4}
            className="d-flex flex-column align-items-md-center align-items-md-end pt-md-5 mt-4 mb-4 mt-md-0 mb-md-0"
          >
            <p className="text text--rm text--black-dark mb-4 mb-md-2">
              Redes sociais
            </p>
            <div className="d-flex">
              <a
                href="https://www.facebook.com/drcannabs"
                target="_blank"
                rel="noopener"
                className="social d-flex align-items-center justify-content-center p-2"
              >
                <span className={'accessibility'}>facebook</span>
                <svg className="social-icon" role="img">
                  <title>Facebook</title>
                  <use xlinkHref={`${Sprites}#icon-facebook`} />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/drcannabisoficial/"
                target="_blank"
                rel="noopener"
                className="social d-flex align-items-center justify-content-center p-2"
              >
                <span className={'accessibility'}>instagram</span>
                <svg className="social-icon" role="img">
                  <title>instagram</title>
                  <use xlinkHref={`${Sprites}#icon-instagram`} />
                </svg>
              </a>
              <a
                href="https://twitter.com/drcannabisreal"
                target="_blank"
                rel="noopener"
                className="social d-flex align-items-center justify-content-center p-2"
              >
                <span className={'accessibility'}>twitter</span>
                <svg className="social-icon" role="img">
                  <title>Twitter</title>
                  <use xlinkHref={`${Sprites}#icon-twitter`} />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/channel/UCRKSmf2hb8xq2gstf04OxoQ/featured"
                target="_blank"
                rel="noopener"
                className="social d-flex align-items-center justify-content-center"
              >
                <span className={'accessibility'}>youtube</span>
                <svg className="social-icon" role="img">
                  <title>Youtube</title>
                  <use xlinkHref={`${Sprites}#icon-youtube`} />
                </svg>
              </a>
              <a
                href="https://br.linkedin.com/company/dr-cannabis"
                target="_blank"
                rel="noopener"
                className="social d-flex align-items-center justify-content-center p-2"
              >
                <span className={'accessibility'}>linkedin</span>
                <svg className="social-icon" role="img">
                  <title>LinkedIn</title>
                  <use xlinkHref={`${Sprites}#icon-linkedin2`} />
                </svg>
              </a>
            </div>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <p className="text text--rr text--black-dark">&copy; 2010 - 2020</p>
          </Col>
          <Col xs={6}>
            <p className="text text--rr text--black-dark">
              <Link to="/termos">Privacy - Terms</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </Ftr>
  )
}
