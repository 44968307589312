import React, { useState } from 'react'
import moment from 'moment'
import { Alert, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { Container, Progress } from './styles'
import Thumb from '~/components/Thumb'
import Button from '~/components/Button'
import ProdutoImg from '~/assets/images/produto.png'
import api from '~/services/api'
import { formatValue } from '~/shared/utils'

export default function Order({ data, refresh, ...rest }) {
  const [status] = useState(data?.status ?? 'new')
  const [showCancel, setShowCancel] = useState(false)

  let progressPercert
  switch (status) {
    case 'new':
      progressPercert = 14
      break
    case 'authorized_payment':
      progressPercert = 40
      break
    case 'transporting':
      progressPercert = 63
      break
    case 'delivered':
      progressPercert = 100
      break
    default:
      progressPercert = 0
      break
  }

  const modalClose = async () => {
    await refresh()
    setShowCancel(false)
  }

  return (
    <>
      <Container className="card" {...rest}>
        <div className="header-order">
          <span>
            Pedido realizado: {moment(data.created_at).format('DD/MM/YY')}
          </span>
          <span className="float-right">Pedido #{data.id}</span>
        </div>
        {data.status === 'new' && (
          <Alert variant="success m-3">
            Estamos analisando a sua documentação, assim que estiver revisada
            pela equipe Dr. Cannabis você receberá um email com as orientações
            de pagamento.
          </Alert>
        )}
        <div className="body">
          {data.medicines.map(medicine => (
            <div className="item">
              <div className="d-flex justify-content-center">
                <Thumb className="lg">
                  <div className="inner">
                    <img src={ProdutoImg} alt="" />
                  </div>
                </Thumb>
              </div>
              <div className="d-flex flex-column justify-content-between p-3 w-100">
                <div className="row">
                  <div className="col-12">
                    <h4 className="mb-3 text-dark">{medicine?.name}</h4>
                  </div>
                  <div className="col-6">
                    <p className="font-weight-normal text-dark mb-2">
                      Unidade(s): {medicine?.pivot.quantity}
                    </p>
                    <p className="font-weight-normal text-dark mb-2">
                      Preço unitário:{' '}
                      {formatValue(medicine.price, medicine.currency)}
                    </p>
                    <p className="font-weight-normal text-dark mb-2">
                      Frete: {formatValue(medicine.shipping, medicine.currency)}
                    </p>
                    <p className="font-weight-normal text-dark mb-2">
                      Preço Total: {formatValue(
                      medicine.price * medicine.pivot.quantity,
                      medicine.currency,
                    )}
                    </p>
                    <div className="">
                      <p className="font-weight-normal text-dark">
                        Vendido por: {medicine?.brand.name}
                      </p>
                    </div>
                  </div>
                  <div className="cancel col-6">
                    {data.status !== 'canceled' ? (
                      <Button
                        onClick={() => setShowCancel(true)}
                        className="outline-danger md"
                      >
                        Cancelar Pedido
                      </Button>
                    ) : (
                      <p>Pedido Cancelado</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {data.status !== 'canceled' && (
          <div className="footer">
            <Progress now={progressPercert} />
            <div className="d-flex steps">
              <span className="m-auto">Pedido realizado</span>
              <span className="m-auto">Pagamento autorizado</span>
              <span className="m-auto">Em transporte</span>
              <span className="m-auto">Produto entregue</span>
            </div>
          </div>
        )}
      </Container>
      <CancelOrder show={showCancel} handleClose={modalClose} id={data.id} />
    </>
  )
}

function CancelOrder({ show, handleClose, id }) {
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    try {
      const data = {
        status: 'canceled',
      }
      setLoading(true)
      await api.put(`/orders/${id}`, data)
      toast.success('Pedido cancelado')
      await handleClose()
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cancelar Pedido</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Tem certeza que deseja cancelar este pedido?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} className="primary">
          Fechar
        </Button>
        <Button onClick={onSubmit} className="danger" disabled={loading}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
