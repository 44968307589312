export const Patient = [
  {
    url: '/paciente',
    icon: 'fa-home',
    label: 'Home',
  },
  {
    url: '/paciente/medicos',
    icon: 'fa-user-md',
    label: 'Médicos',
  },
  {
    url: '/paciente/prescricao',
    icon: 'fa-file-medical',
    label: 'Prescrições',
  },
  {
    url: '/paciente/pedidos',
    icon: 'fa-file-invoice-dollar',
    label: 'Histórico de pedidos',
  },
  {
    url: '/paciente/perfil',
    icon: 'fa-user-alt',
    label: 'Perfil',
  },
  {
    url: '/logout',
    icon: 'fa-power-off mt-2',
    label: 'Sair',
  },
]

export const Physician = [
  {
    url: '/medico',
    icon: 'fa-home',
    label: 'Home',
  },
  {
    url: '/medico/pacientes',
    icon: 'fa-hospital-user',
    label: 'Pacientes',
  },
  {
    url: '/medico/prescricao',
    icon: 'fa-file-medical',
    label: 'Prescrições',
  },
  {
    url: '/medico/artigos',
    icon: 'fa-books-medical',
    label: 'Artigos Científicos',
  },
  {
    url: '/medico/perfil',
    icon: 'fa-user-alt',
    label: 'Perfil',
  },
  {
    url: '/logout',
    icon: 'fa-power-off mt-2',
    label: 'Sair',
  },
]

export const Admin = [
  {
    url: '/admin/medicos',
    icon: 'fa-user-md',
    label: 'Medicos',
  },
  {
    url: '/admin/pacientes',
    icon: 'fa-hospital-user',
    label: 'Pacientes',
  },
  {
    url: '/admin/produtos',
    icon: 'fa-cube',
    label: 'Produtos',
  },
  {
    url: '/admin/prescricoes',
    icon: 'fa-file-medical',
    label: 'Prescricoes',
  },
  {
    url: '/admin/pedidos',
    icon: 'fa-file-invoice-dollar',
    label: 'Lista de pedidos',
  },
  {
    url: '/admin/artigos',
    icon: 'fa-books-medical',
    label: 'Artigos',
  },
  {
    url: '/logout',
    icon: 'fa-power-off mt-2',
    label: 'Sair',
  },
]
