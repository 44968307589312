import React from 'react'

import { Container } from './styles'

export default function Article({ article = {} }) {
  return (
    <Container>
      <div className="title">
        <a href={`https://www.ncbi.nlm.nih.gov/pubmed/${article.pubmed_id}`}>
          <h2 dangerouslySetInnerHTML={{ __html: article.ArticleTitle }}/>
        </a>
        <div className="d-flex justify-content-between">
          <p className="autors">
            {article.Authors?.map(author => `${author}; `)}
          </p>
          <p className="date">Data de publicação: {article.ArticleDate}</p>
        </div>
      </div>
      <div className="abstract">
        <p dangerouslySetInnerHTML={{ __html: article.AbstractText }}/>
      </div>
      <hr className="separator my-5" />
    </Container>
  )
}
