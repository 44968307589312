import React from 'react'

import { SectionPhysician } from './styles'
import { Container, Row, Col } from 'react-bootstrap'

import SideMenu from '~/components/SideMenu'
import PhysicianRoutes from '~/routes/Physician'
import ScrollToTop from '~/components/ScrollToTop'

export default function Physician() {
  return (
    <SectionPhysician>
      <ScrollToTop />
      <Container fluid className="p-0">
        <Row className="m-0">
          <Col md={3} className="d-none d-md-block p-0">
            <SideMenu />
          </Col>
          <Col xs={12} md={9} className="mb-5 mb-md-0">
            <PhysicianRoutes />
          </Col>
        </Row>
      </Container>
    </SectionPhysician>
  )
}
