import styled from 'styled-components'
import { Button } from 'react-bootstrap'

import { Variables as V } from '~/styles/global'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
`

export const LoginButton = styled(Button)`
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff;
  background-color: ${V.colorPrimary};
  border: 1px solid #ffffff;
  transition: all 0.2s;

  width: 8.8rem;
  font-size: 1.1rem;
  @media only screen and (min-width: 1440px) {
    font-size: 1.3rem;
  }

  &:hover {
    color: #ffffff;
    background-color: #43a995;
    border: 1px solid #ffffff;
    transition: all 0.2s;
  }

  &:focus,
  &:active {
    color: #ffffff;
    background-color: #43a995 !important;
    border: 1px solid #ffffff !important;
    transition: all 0.2s;
    box-shadow: none !important;
  }
`

export const ContainerFormLogin = styled.div`
  left: -159px !important;
  padding: 1rem;
  min-width: 300px;
`
