import styled from 'styled-components'

export const Section = styled.div`
  margin: 2rem 0;

  p,
  li {
    line-height: 1.5;
    text-align: justify;
  }
`
