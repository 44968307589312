import React from 'react'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'

import { Container, Progress } from './styles'
import pdfIcon from '~/assets/icons/pdfIcon.png'

function PrescriptionItem({ prescription }) {
  let progressPercert
  switch (prescription.status) {
    case 'askAnvisa':
      progressPercert = 40
      break
    case 'askMedicine':
      progressPercert = 40
      break
    case 'askPhysician':
      progressPercert = 40
      break
    case 'templateAnvisa':
      progressPercert = 40
      break
    case 'sendAnvisa':
      progressPercert = 40
      break
    case 'quotation':
      progressPercert = 62
      break
    case 'analyzing':
      progressPercert = 85
      break
    default:
      progressPercert = 15
      break
  }

  let checkReviews = false

  if (prescription.review_anvisa !== 'review') checkReviews = true
  if (prescription.review_prescription !== 'review') checkReviews = true
  if (prescription.review_document !== 'review') checkReviews = true
  if (prescription.review_address !== 'review') checkReviews = true

  return (
    <Container>
      <div className="desc">
        <div className="d-flex flex-column justify-content-center w-100 p-3">
          <Row>
            <Col className="d-flex justify-content-between title">
              <h5 className="text--rm">Prescrição #{prescription.id}</h5>
              <span>
                {prescription.notify && (
                  <span className="text--rb notify mr-2">●</span>
                )}
                {checkReviews && (
                  <span className="text--rb checked mr-2">✓</span>
                )}
                <span>
                  {moment(prescription.created_at).format('DD/MM/YYYY')}
                </span>
              </span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex align-items-center">
              <div>
                <b>Paciente:</b>
                <p>{prescription.patient?.profile?.full_name}</p>
              </div>
            </Col>
            <Col className="d-flex align-items-center">
              <div>
                <b>Médico:</b>
                <p>
                  {prescription.physician
                    ? prescription.physician.profile.full_name
                    : prescription.prePhysician
                    ? prescription.prePhysician.full_name
                    : 'Não informado'}
                </p>
              </div>
            </Col>
            <Col className="d-flex">
              {prescription.file && (
                <a href={prescription.file.url} target="_blank" rel="noopener">
                  <div className="file">
                    <img src={pdfIcon} />
                    <p>Prescrição</p>
                  </div>
                </a>
              )}
              {prescription.anvisa && (
                <a href={prescription.anvisa.url} target="_blank" rel="noopener">
                  <div className="file">
                    <img src={pdfIcon} />
                    <p>ANVISA</p>
                  </div>
                </a>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="footer mt-3">
                <Progress now={progressPercert} />
                <div className="d-flex steps">
                  <span className="m-auto">Prescrição</span>
                  <span className="m-auto">Anvisa</span>
                  <span className="m-auto">Cotação</span>
                  <span className="m-auto">Análise</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  )
}

export default PrescriptionItem
