import React, { useState } from 'react'
import { Row, Col, Modal, Alert } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import api from '~/services/api'
import Button from '~/components/Button/index'
import { Input } from '~/components/Form/index'

export default function ModalForgotPassword({ show, handleClose }) {
  const { register, handleSubmit, errors } = useForm()
  const [loading, setLoading] = useState(false)

  const onSubmit = async data => {
    try {
      setLoading(true)
      await api.post('/forgotpassword', data)
      handleClose()
      toast.success('Email com instruções de recuperação da senha enviado.')
      setLoading(false)
    } catch (e) {
      if (e.response?.data?.error) toast.error(e.response?.data.error.message)
      setLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Recuperar a senha</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">
          Preencha seu email de cadastro abaixo e enviaremos um email com
          intruções para você alterar sua senha
        </Alert>
        <form id="formForgotPassword" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Input
                type="text"
                label="Email para recuperação"
                name="email"
                size="md"
                fluid
                className="my-3"
                error={errors.pubmed_id}
                ref={register({ required: true })}
              />
            </Col>
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          form="formForgotPassword"
          type="submit"
          className="primary"
          disabled={loading}
        >
          Enviar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
