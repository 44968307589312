import Immutable from 'seamless-immutable'
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  medicinesRequest: ['post'],
  medicinesSuccess: ['data'],
  medicinesError: [],
})

const INITIAL_STATE = Immutable({
  medicines: [],
  error: false,
  loading: false,
})

const medicinesRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const medicinesSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  itens: action.data.data,
  error: false,
})

const medicinesError = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error,
})

export default createReducer(INITIAL_STATE, {
  [Types.MEDICINES_REQUEST]: medicinesRequest,
  [Types.MEDICINES_SUCCESS]: medicinesSuccess,
  [Types.MEDICINES_ERROR]: medicinesError,
})
