import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
  background-color: #fff;
  background-clip: border-box;

  .header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    &::first-child {
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    }
  }

  .body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    display: flex;
    align-items: flex-end;
  }

  .footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }

  .cancel {
    position: absolute;
    right: 13px;
    top: 0;
    font-weight: bold;
    cursor: pointer;
  }
`
