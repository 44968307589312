import styled from 'styled-components'

import { Variables as V } from '~/styles/global'

export const Ftr = styled.footer`
  position: relative;
  padding-top: 6rem;
  box-shadow: 0 -4px 4px rgba(45, 45, 45, 0.25);
  z-index: 2;
  @media only screen and (max-width: 575px) {
    padding-top: 3rem;
  }

  .logo {
    width: 12.6rem;
    height: 3.6rem;
    @media only screen and (min-width: 1440px) {
      width: 15rem;
    }
  }

  .text {
    font-size: 0.9rem;
  }

  .anchor {
    transition: all 0.2s;

    &:hover {
      color: ${V.colorPrimary};
      transition: all 0.2s;
    }
  }

  .social {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;
    background-color: ${V.colorPrimary};
    transition: all 0.2s;

    @media only screen and (min-width: 1440px) {
      width: 3rem;
      height: 3rem;
    }
    @media only screen and (max-width: 575px) {
      width: 3rem;
      height: 3rem;
    }

    &:hover {
      transform: scale(1.1);
      transition: all 0.2s;
    }

    &:not(:last-of-type) {
      margin-right: 1rem;
    }

    &-icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: ${V.colorWhite};
    }
  }
`
