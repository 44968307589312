import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Pagination from 'react-js-pagination'

import { Container, Order } from './styles'
import api from '~/services/api'
import MedicineImage from '~/assets/images/produto.png'
import { Select } from '~/components/Form'
import { useForm } from 'react-hook-form'
import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function Orders() {
  const [orders, setOrders] = useState({ data: [] })
  const [loading, setLoading] = useState(false)
  const { register, watch } = useForm()
  const status = watch('status')
  const getOrders = async page => {
    setLoading(true)
    const res = await api.get(`/orders?page=${page?page:1}&status=${status}`)
    setOrders(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getOrders()
  }, [status])

  const handlePageChange = page => {
    getOrders(page)
  }

  return (
    <Container>
      <h2>Pedidos</h2>
      <Row className="mt-2">
        <Select
          ref={register}
          name="status"
          size="md"
          icon="icon-chevron-down"
          className="ml-3"
        >
          <option value="">Status</option>
          <option value="transport">Em transporte</option>
          <option value="pendding">Pendente</option>
          <option value="payment">Aguardando pagamento</option>
          <option value="finished">Finalizado</option>
          <option value="cancelled">Cancelado</option>
        </Select>
      </Row>
      <Row>
        {!loading &&
          orders?.data?.map(order => (
            <Col className="my-3" lg={6} key={order.id}>
              <Link to={`/admin/pedidos/${order.id}`}>
                <Order>
                  <div className="image">
                    {order.medicines[0].photo ? (
                      <RenderImg
                        photo_id={order.medicines[0].photo?.id}
                        photo_uuid={order.medicines[0].photo?.uuidUrl}
                        isPrivate={order.medicines[0].photo?.private}
                        alt={order.medicines[0].name}
                      />
                    ) : (
                      <img src={MedicineImage} alt="" />
                    )}
                  </div>
                  <div className="desc">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="title">Pedido #{order.id}</h4>
                      <div className="date">
                        {moment(order.created_at).format('DD/MM/YYYY')}
                      </div>
                    </div>
                    <div className="infos">
                      <div className="my-3">
                        <b>Medico</b>
                        <p>
                          {order.prescription.physician
                            ? order.prescription.physician.profile.full_name
                            : order.prescription.prePhysician?.full_name
                                .length > 0
                            ? order.prescription.prePhysician?.full_name
                            : '---------'}
                        </p>
                      </div>
                      <div className="my-3">
                        <b>Paciente</b>
                        <p>{order.prescription.patient.profile.full_name}</p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <b>Status:</b>
                      <span
                        className={`ml-1 ${
                          order.status === 'canceled' ||
                          order.status === 'cancelled'
                            ? 'text-danger'
                            : order.status === 'pendding'
                            ? 'text-warning'
                            : order.status === 'transport' && 'text-success'
                        }`}
                      >
                        {order.status === 'canceled' ||
                        order.status === 'cancelled'
                          ? 'Finalizado (cancelado)'
                          : order.status === 'pendding'
                          ? 'Novo (em análise)'
                          : order.status === 'transport' && 'Em tratamento'}
                      </span>
                    </div>
                  </div>
                </Order>
              </Link>
            </Col>
          ))}
      </Row>
      <Row>
        <Col className="d-flex justify-content-center mt-3">
          <Pagination
            activePage={orders.page}
            itemsCountPerPage={orders.perPage}
            totalItemsCount={orders.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </Col>
      </Row>
    </Container>
  )
}
