import React from 'react'
import { Row, Col, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { Container, OptionBox } from './styles'
import Button from '~/components/Button'

export default function WhatNow() {
  const redirectBlog = () => {
    return (window.open('https://blog.drcannabis.com.br/', '_blank'))
  }
  return (
    <Container>
      <Row>
        <Col>
          <Alert variant="success">
            <p>
              Vamos começar o seu tratamento com cannabis medicinal, você vai
              precisar de:
            </p>
            <ol className="ml-4 mt-2">
              <li>Prescrição médica</li>
              <li>Autorização Anvisa</li>
              <li>Comprar o produto</li>
            </ol>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="text-center text--rm">O que você quer fazer agora?</h2>
        </Col>
      </Row>
      <Row className="my-4 align-items-center">
        <Col xs={12} lg={4}>
          <OptionBox>
            <div className="title">
              <h4>Quero uma prescrição</h4>
            </div>
            <div className="w-100 d-flex text-center my-3">
              <Link to="/paciente/medicos" className="w-100">
                <Button className="outline-primary m-auto">Ver médicos</Button>
              </Link>
            </div>
            <div className="desc">
              <p>Clique aqui para agendar consultas com médicos prescritores</p>
            </div>
          </OptionBox>
        </Col>
        <Col xs={12} lg={4}>
          <OptionBox>
            <div className="title">
              <h4>Já tenho prescrição</h4>
            </div>
            <div className="w-100 d-flex text-center my-3">
              <Link to="/paciente/prescricao" className="w-100">
                <Button className="primary m-auto">Enviar prescrição</Button>
              </Link>
            </div>
            <div className="desc">
              <p>
                Clique aqui para nos enviar o arquivo da sua prescrição e
                receber a orientação seguinte
              </p>
            </div>
          </OptionBox>
        </Col>
        <Col xs={12} lg={4}>
          <OptionBox>
            <div className="title">
              <h4>Quero mais informação</h4>
            </div>
            <div className="w-100 d-flex text-center my-3">
              <Button
                type="button"
                className="blue m-auto"
                onClick={() => redirectBlog()}
              >
                Saiba mais
              </Button>
            </div>
            <div className="desc">
              <p>Descubra tudo sobre o tratamento com cannabis medicinal</p>
            </div>
          </OptionBox>
        </Col>
      </Row>
    </Container>
  )
}
