import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { Container, Prescription } from './styles'
import api from '~/services/api'
import PrescriptionItem from './PrescriptionItem'
import Pagination from 'react-js-pagination'
import { Input, Select, Checkbox } from '~/components/Form'
import Button from '~/components/Button/index'

export default function Prescriptions() {
  const [prescriptions, setPrescriptions] = useState({ data: [] })
  const [loading, setLoading] = useState(false)
  const { register, watch, reset } = useForm()
  const { search, status, haveAnvisa, havePrescription, haveOrder } = watch()

  const history = useHistory()
  const getPrescription = async (
    page,
    prescriptionSearch,
    prescriptionStatus,
    patientHaveAnvisa,
    patientHavePrescription,
    patientHaveOrder,
  ) => {
    setLoading(true)
    const res = await api.get('/prescriptions', {
      params: {
        page,
        search: prescriptionSearch,
        status: prescriptionStatus,
        haveAnvisa: patientHaveAnvisa,
        havePrescription: patientHavePrescription,
        haveOrder: patientHaveOrder,
      },
    })
    setPrescriptions(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getPrescription(1, search, status, haveAnvisa, havePrescription, haveOrder)
  }, [search, status, haveAnvisa, havePrescription, haveOrder])

  const handlePageChange = page => {
    getPrescription(page, search, status)
  }

  const cleanFilters = () => {
    reset({
      search: '',
      status: '',
      haveAnvisa: null,
      havePrescription: null,
      haveOrder: null,
    })
    getPrescription(1)
  }

  return (
    <Container>
      <Row>
        <Col>
          <h2>Prescrições</h2>
        </Col>
      </Row>
      <Row>
        <Col className="my-3">
          <Input
            type="text"
            name="search"
            placeholder="Buscar"
            size="md"
            fluid
            icon="icon-search"
            ref={register}
          />
        </Col>
        <Col className="my-3">
          <Select
            ref={register}
            name="status"
            size="md"
            icon="icon-chevron-down"
            className="ml-3"
          >
            <option value="">Status</option>
            <option value="askMedicine">Selecionando Medicamento</option>
            <option value="getPrescription">Pegando Prescrição</option>
            <option value="sendPrescription">Enviando Prescrição</option>
            <option value="askAnvisa">Pergunta se tem Anvisa</option>
            <option value="askPhysician">Selecionando Médico</option>
            <option value="templateAnvisa">Template Anvisa</option>
            <option value="sendAnvisa">Enviando Anvisa</option>
            <option value="quotation">Cotação</option>
            <option value="analyzing">Análise</option>
          </Select>
        </Col>
        <Col className="my-3">
          <button
            className="btn btn-outline-success h-100"
            onClick={() => history.push('/admin/nova/prescricao')}
          >
            Nova prescrição
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            className="my-3"
            name="havePrescription"
            label="Tem Prescrição"
            ref={register}
          />
        </Col>
        <Col>
          <Checkbox
            className="my-3"
            name="haveAnvisa"
            label="Tem Anvisa"
            ref={register}
          />
        </Col>
        <Col>
          <Checkbox
            className="my-3"
            name="haveOrder"
            label="Tem Pedido"
            ref={register}
          />
        </Col>
        <Col className="d-flex justify-content-end">
          <div>
            <Button className="white" onClick={cleanFilters}>
              Limpar
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        {!loading &&
          prescriptions.data.map(
            prescription =>
              prescription.id && (
                <Col lg={12} className="mt-3" key={prescription.id}>
                  <Link to={`/admin/prescricoes/${prescription.id}`}>
                    <PrescriptionItem prescription={prescription} />
                  </Link>
                </Col>
              ),
          )}
      </Row>
      <Row>
        <Col className="d-flex justify-content-center my-3">
          <Pagination
            activePage={prescriptions.page}
            itemsCountPerPage={prescriptions.perPage}
            totalItemsCount={prescriptions.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </Col>
      </Row>
    </Container>
  )
}
