import styled from 'styled-components'

import { Variables as V } from '~/styles/global'

export const Container = styled.div`
  border: 1px solid ${V.colorBlack};
  display: inline-flex;
  border-radius: 2px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;

  .inner {
    display: flex;
    min-width: 0px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    svg {
      fill: ${V.colorBlack};
      &.sm {
      }
    }
  }

  &.lg {
    width: 200px;
    height: 200px;
  }
`
