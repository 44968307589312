import styled from 'styled-components'

import { Variables as V } from '~/styles/global'

export const SectionProduct = styled.section`
  margin: 2rem 0;
  .img {
    width: 100%;
  }

  .price {
    @media only screen and (max-width: 575px) {
      font-size: 2.4rem;
    }
  }

  .value {
    font-size: 1.1rem;
    @media only screen and (max-width: 575px) {
      font-size: 1.4rem;
    }

    strike {
      color: #e60a0a;
    }

    &.secondary {
      color: ${V.colorSecondary};
    }
  }

  .text-desc {
    font-size: 0.95rem;
    line-height: 1.4rem;
  }

  .price {
    height: 90px;
    width: 90px;
  }

  .amount {
    .icon {
      padding: 1.1rem;
      width: 3.3rem;
      height: 3.5rem;
      border: 1px solid ${V.colorBlackDark};
      fill: ${V.colorBlackDark};
      cursor: pointer;

      &:hover {
        fill: ${V.colorSecondary};
      }

      &.left {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &.right {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    .number {
      width: 3.3rem;
      height: 3.5rem;
      border-top: 1px solid ${V.colorBlackDark};
      border-bottom: 1px solid ${V.colorBlackDark};
    }
  }

  .fav {
    &.container {
      padding: 0.9rem 1.8rem;
      width: 12.5rem;
      border: 1px solid ${V.colorBlackDark};
      border-radius: 3px;
    }

    .text {
      margin-right: 1.1rem;
      font-size: 0.9rem !important;
    }

    .icon {
      width: 2.1rem;
      height: 2.1rem;
      fill: ${V.colorBlackDark};
      transform: scale(1);
      cursor: pointer;

      &:hover {
        fill: ${V.colorSecondary};
        transform: scale(1.1);
      }

      &.mobile {
        top: 1rem;
        right: 2rem;

        @media only screen and (max-width: 767px) {
          right: 1rem;
        }

        &:hover {
          fill: ${V.colorSecondary};
          transform: scale(1.1);
        }
      }
    }
  }
`
