import React from 'react'
import { Alert } from 'react-bootstrap'

import api from '~/services/api'
import { Container } from './styles'
import Button from '~/components/Button'

export default function AskAnvisa({ next, data }) {
  const goAskMedicine = async () => {
    const prescription = {
      status: 'askMedicine',
    }
    await api.put(`/prescriptions/${data.id}`, prescription)
    next('askMedicine')
  }

  const goSendAnvisa = async () => {
    const prescription = {
      status: 'sendAnvisa',
    }
    await api.put(`/prescriptions/${data.id}`, prescription)
    next('sendAnvisa')
  }

  return (
    <Container>
      <Alert variant="success">
        <p>
          Agradecemos o envio de sua prescrição. Agora, vamos para o próximo
          passo.
        </p>
      </Alert>

      <h4 className="my-5 text-center">Você já tem autorização Anvisa?</h4>
      <div className="d-flex justify-content-around">
        <Button className="primary" onClick={goSendAnvisa}>
          Sim, já tenho
        </Button>
        <Button className="white" onClick={goAskMedicine}>
          Não, como faço?
        </Button>
      </div>
    </Container>
  )
}
