import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Multiselect } from 'react-widgets'

import { Creators as AuthActions } from '~/store/ducks/auth'
import { Creators as ErrorActions } from '~/store/ducks/error'

import Yup from '~/helpers/yup'
import api from '~/services/api'
import brasilapi from '~/services/brasilapi'
import UploadAvatar from '~/components/UploadAvatar'
import { Container } from './styles'
import { Input, Select, Radio } from '~/components/Form'
import Button from '~/components/Button'
import StatesBrasil from '~/helpers/StatesBrasil.json'
import Diseases from '~/helpers/Diseases.json'

let parseFormats = ['DD/MM/YYYY']

const EditProfileSchema = Yup.object().shape({
  isResponsible: Yup.boolean(),
  user: Yup.object().shape({
    email: Yup.string()
      .email('Digite um email válido')
      .required('Digite um email'),
  }),
  profile: Yup.object().shape({
    full_name: Yup.string().required('Digite seu nome.'),
    birthdate: Yup.date()
      .format(parseFormats)
      .max(new Date(), 'Você não pode ter nascido no futuro!')
      .typeError('Insira uma data Válida'),
    gender: Yup.string().required('Selecione seu gênero'),
    phone_number: Yup.string().removeSpecials(),
    mobile_number: Yup.string()
      .removeSpecials()
      .required('Qual seu telefone?'),
    rg: Yup.string().required('Digite seu RG'),
    cpf: Yup.string()
      .removeSpecials()
      .required('Digite seu CPF'),
  }),
  address: Yup.object().shape({
    cep: Yup.string().required('Digite o CEP do seu endereço'),
    state: Yup.string().required('Digite o estado que você mora'),
    city: Yup.string().required('Digite a cidade que você mora'),
    street: Yup.string().required('Qual a rua que você mora?'),
    number: Yup.string(),
    complement: Yup.string(),
    district: Yup.string().required('Digite o bairro que você mora'),
  }),
  ward: Yup.object().shape({
    isResponsible: Yup.boolean(),
    full_name: Yup.string().when('isResponsible', (isResponsible, schema) => {
      return isResponsible ? schema.required() : schema
    }),
    birthdate: Yup.date()
      .format(parseFormats)
      .max(new Date(), 'Você não pode ter nascido no futuro!')
      .typeError('Insira uma data Válida')
      .when('isResponsible', (isResponsible, schema) => {
        return isResponsible ? schema : schema.nullable()
      }),
    gender: Yup.string().when('isResponsible', (isResponsible, schema) => {
      return isResponsible ? schema.required('Escolha seu gênero') : schema
    }),
    phone_number: Yup.string().removeSpecials(),
    mobile_number: Yup.string().removeSpecials(),
    rg: Yup.string().when('isResponsible', (isResponsible, schema) => {
      return isResponsible ? schema.required('Digite seu RG') : schema
    }),
    cpf: Yup.string()
      .removeSpecials()
      .when('isResponsible', (isResponsible, schema) => {
        return isResponsible ? schema.required('Digite seu CPF') : schema
      }),
  }),
})

export default function Edit({ history }) {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    validationSchema: EditProfileSchema,
  })
  const { session } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [isResponsible, setIsResponsible] = useState(false)
  const cep = watch('address.cep')
  const [diseases, setDiseases] = useState([])

  const getAddressByCep = async () => {
    try {
      setLoading(true)
      const res = await brasilapi.get(`/api/cep/v1/${cep}`)
      if (!res.data.errors) {
        setValue('address.state', res.data.state)
        setValue('address.city', res.data.city)
        setValue('address.street', res.data.street)
        setValue('address.district', res.data.neighborhood)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (cep && cep.length === 8) {
      getAddressByCep()
    }
  }, [cep])

  useEffect(() => {
    setIsResponsible(session.roles.includes('responsible'))
    if (session.roles.includes('responsible')) {
      setValue('ward', session.wards[0]?.profile)
      setValue(
        'ward.birthdate',
        moment(session.wards[0]?.profile.birthdate).format('DD/MM/YYYY'),
      )
      if (session.wards[0]?.profile.diseases) {
        setDiseases(session.wards[0]?.profile.diseases)
      }
    } else {
      if (session.profile.diseases) {
        setDiseases(session.profile.diseases)
      }
    }

    setValue('profile', session.profile)
    setValue(
      'profile.birthdate',
      moment(session.profile?.birthdate).format('DD/MM/YYYY'),
    )
    setValue('address', session.addresses[0])
    setValue('user.email', session.email)
  }, [session.profile])

  const onSubmit = async data => {
    setLoading(true)
    try {
      data.role = data.isResponsible ? 'responsible' : 'patient'
      if (data.isResponsible) {
        data.ward.diseases = diseases
      } else {
        data.profile.diseases = diseases
      }
      await api.put(`/users/${session.id}`, data)
      setLoading(false)
      dispatch(AuthActions.getSessionRequest())
      const to = session.profile?.cpf ? '/paciente/perfil' : '/paciente'
      history.push(to)
    } catch (e) {
      dispatch(ErrorActions.setError('Erro ao editar perfil.'))
      setLoading(false)
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="text--rb">Termine seu cadastro</h2>
        </Col>
      </Row>
      <div className="separator" />
      <Row>
        <Col>
          <h2 className="text--rb mb-3">Informações Pessoais</h2>
        </Col>
      </Row>
      <Row className="m-2">
        <Col>
          {session.profile.full_name ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <p className="text--rb">Adicione uma foto de perfil</p>
                  <UploadAvatar />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Input
                    type="text"
                    label="Nome Completo"
                    name="profile.full_name"
                    placeholder="Nome"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.profile?.full_name}
                    ref={register}
                  />
                </Col>
                <Col lg={6}>
                  <Input
                    type="text"
                    label="Data de nascimento"
                    name="profile.birthdate"
                    placeholder="__/__/____"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.profile?.birthdate}
                    ref={register}
                    mask="99/99/9999"
                  />
                </Col>
                <Col lg={6}>
                  <Select
                    type="text"
                    label="Gênero"
                    name="profile.gender"
                    size="md"
                    icon="icon-chevron-down"
                    className="my-3"
                    error={errors.profile?.gender}
                    ref={register}
                    fluid
                  >
                    <option value="m">Masculino</option>
                    <option value="f">Feminino</option>
                    <option value="-">Prefiro não informar</option>
                  </Select>
                </Col>

                <Col lg={6}></Col>

                <Col lg={3}>
                  <Input
                    type="text"
                    label="Celular"
                    name="profile.mobile_number"
                    placeholder="(11) 99999-9999"
                    mask="(99) 99999-9999"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.profile?.mobile_number}
                    ref={register}
                  />
                </Col>

                <Col lg={3}>
                  <Input
                    type="text"
                    label="Telefone Fixo"
                    name="profile.phone_number"
                    placeholder="(11) 99999-9999"
                    mask="(99) 99999-9999"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.profile?.phone_number}
                    ref={register}
                  />
                </Col>

                <Col lg={3}>
                  <Input
                    type="text"
                    label="RG"
                    name="profile.rg"
                    placeholder="9999999"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.profile?.rg}
                    ref={register}
                  />
                </Col>

                <Col lg={3}>
                  <Input
                    type="text"
                    label="CPF"
                    name="profile.cpf"
                    placeholder="123.456.789-10"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.profile?.cpf}
                    ref={register}
                    mask="999.999.999-99"
                  />
                </Col>
              </Row>

              <Row>
                <Col className={`my-3`} xs={12}></Col>
                <Col lg={4}>
                  <Input
                    type="text"
                    label="CEP"
                    name="address.cep"
                    placeholder="00000-000"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.address?.cep}
                    ref={register}
                  />
                </Col>

                <Col lg={4}>
                  <Select
                    type="text"
                    label="UF"
                    name="address.state"
                    size="md"
                    className="my-3"
                    error={errors.address?.state}
                    ref={register}
                    fluid
                  >
                    {StatesBrasil.map(state => (
                      <option value={state.value}>{state.label}</option>
                    ))}
                  </Select>
                </Col>

                <Col lg={4}>
                  <Input
                    type="text"
                    label="Cidade"
                    name="address.city"
                    placeholder=""
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.address?.city}
                    ref={register}
                  />
                </Col>

                <Col lg={4}>
                  <Input
                    type="text"
                    label="Bairro"
                    name="address.district"
                    placeholder=""
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.address?.district}
                    ref={register}
                  />
                </Col>

                <Col lg={6}>
                  <Input
                    type="text"
                    label="Logradouro"
                    name="address.street"
                    placeholder=""
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.address?.street}
                    ref={register}
                  />
                </Col>

                <Col lg={2}>
                  <Input
                    type="text"
                    label="Número"
                    name="address.number"
                    placeholder=""
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.address?.number}
                    ref={register}
                  />
                </Col>

                <Col lg={6}>
                  <Input
                    type="text"
                    label="Complemento"
                    name="address.complement"
                    placeholder=""
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.address?.complement}
                    ref={register}
                  />
                </Col>

                <Col lg={6}></Col>

                <Col lg={6}>
                  <Input
                    type="text"
                    label="Email"
                    name="user.email"
                    placeholder=""
                    size="md"
                    fluid
                    className="mt-5 my-3"
                    error={errors.user?.email}
                    ref={register}
                  />
                </Col>
              </Row>

              <Row className="my-5">
                <Col>
                  <Radio
                    name="isResponsible"
                    ref={register}
                    value={false}
                    onClick={() => setIsResponsible(false)}
                    checked={!isResponsible}
                    label="Busco tratamento para mim"
                  />
                  <Radio
                    name="isResponsible"
                    ref={register}
                    value={true}
                    onClick={() => setIsResponsible(true)}
                    checked={isResponsible}
                    label="Sou responsável por um paciente"
                  />
                </Col>
              </Row>

              <Row className={`${isResponsible ? '' : 'd-none'}`}>
                <Col>
                  <h2 className="text--rb mb-3">
                    Informações do Paciente sob sua responsabilidade
                  </h2>
                </Col>

                <Col className="my-3" xs={12}>
                  <input
                    type="checkbox"
                    name="ward.isResponsible"
                    ref={register}
                    className="d-none"
                    checked={isResponsible}
                  />
                </Col>

                <Col lg={6}>
                  <Input
                    type="text"
                    label="Nome Completo do Paciente"
                    name="ward.full_name"
                    placeholder="Nome"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.ward?.full_name}
                    ref={register}
                  />
                </Col>
                <Col className={`${isResponsible ? '' : 'd-none'}`} lg={6}>
                  <Input
                    type="text"
                    label="Data de nascimento do Paciente"
                    name="ward.birthdate"
                    placeholder="__/__/____"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.ward?.birthdate}
                    ref={register}
                    mask="99/99/9999"
                  />
                </Col>
                <Col className={`${isResponsible ? '' : 'd-none'}`} lg={6}>
                  <Select
                    type="text"
                    label="Gênero do Paciente"
                    name="ward.gender"
                    size="md"
                    icon="icon-chevron-down"
                    className="my-3"
                    error={errors.ward?.gender}
                    ref={register}
                    fluid
                  >
                    <option value="m">Masculino</option>
                    <option value="f">Feminino</option>
                    <option value="-">Prefiro não informar</option>
                  </Select>
                </Col>

                <Col
                  className={`${isResponsible ? '' : 'd-none'}`}
                  lg={6}
                ></Col>

                <Col className={`${isResponsible ? '' : 'd-none'}`} lg={6}>
                  <Input
                    type="text"
                    label="Celular do Paciente"
                    name="ward.mobile_number"
                    placeholder="(11) 99999-9999"
                    mask="(99) 99999-9999"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.ward?.mobile_number}
                    ref={register}
                  />
                </Col>

                <Col className={`${isResponsible ? '' : 'd-none'}`} lg={6}>
                  <Input
                    type="text"
                    label="Telefone Fixo do Paciente"
                    name="ward.phone_number"
                    placeholder="(11) 99999-9999"
                    mask="(99) 99999-9999"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.ward?.phone_number}
                    ref={register}
                  />
                </Col>

                <Col className={`${isResponsible ? '' : 'd-none'}`} lg={6}>
                  <Input
                    type="text"
                    label="RG do Paciente"
                    name="ward.rg"
                    placeholder="9999999"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.ward?.rg}
                    ref={register}
                  />
                </Col>

                <Col className={`${isResponsible ? '' : 'd-none'}`} lg={6}>
                  <Input
                    type="text"
                    label="CPF do Paciente"
                    name="ward.cpf"
                    placeholder="123.456.789-10"
                    mask="999.999.999-99"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.ward?.cpf}
                    ref={register}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <h2 className="text--rb mb-3">Sobre o tratamento</h2>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <label htmlFor="diseases" style={{ 'font-size': '16px' }}>
                    Patologia
                  </label>
                  <Multiselect
                    id="diseases"
                    data={Diseases}
                    defaultValue={ session?.profile?.diseases
                        ? session.profile.diseases
                        : []
                    }
                    onChange={value => setDiseases(value)}
                  />
                  {diseases.length === 0 && (
                    <span className="text-danger">
                      Campo Patologia é obrigatório
                    </span>
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Button
                    type={
                      diseases
                        ? diseases.length > 0
                          ? 'submit'
                          : 'button'
                        : 'button'
                    }
                    disabled={loading}
                    className="primary md my-3"
                  >
                    {loading ? 'Carregando...' : 'Continuar'}
                  </Button>
                </Col>
              </Row>
            </form>
          ) : null}
        </Col>
      </Row>
    </Container>
  )
}
