import styled from 'styled-components'

import { Variables as V } from '~/styles/global'

export const Container = styled.div`
  padding-top: 2.2rem;
  padding-bottom: 7.8rem;
  height: 100%;
  background-color: ${V.colorGreyLighter};

  .img {
    width: 5rem;
    height: 5rem;
    border-radius: 5rem;
  }

  .text {
    font-size: 1.3rem;
  }

  .item {
    cursor: pointer;

    &:hover {
      .icon,
      .anchor {
        color: ${V.colorSecondary};
        fill: ${V.colorSecondary};
        transition: all 0.2s;
      }
    }

    &.selected {
      .icon,
      .anchor {
        color: ${V.colorSecondary};
        fill: ${V.colorSecondary};
        stroke: ${V.colorSecondary};
        transition: all 0.2s;
      }
    }

    &:not(:last-of-type) {
      margin-bottom:1.7rem;
    }

    .icon {
      width: 2.1rem;
      height: 2.1rem;
      fill: transparent;
      stroke: #000;
    }

    &.active {
      color: #5daa60;
      a {
        color: #5daa60;
      }
      .icon {
        stroke: #5daa60;
        fill: #5daa60;
      }
    }
  }
`
