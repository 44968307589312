import React, { useState } from 'react'
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap'

import { SectionFaq, SectionQuestions, Question } from './styles'
import Sprites from '~/assets/icons/sprites.svg'
import Button from '~/components/Button'
import { Helmet } from 'react-helmet'
import { SectionPillars } from '~/pages/About/styles'
import { SectionBanner } from '~/pages/Home/styles'

export default function Faq() {
  const [faq, setFaq] = useState('patient')
  const title = 'Dr. Cannabis - FAQ'
  return (
    <>
      <SectionFaq>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </Helmet>

        <Container>
          <Row>
            <Col xs={12}>
              <h1 className="title text--sm text--black text-center mb-3 mt-5">
                FAQ
              </h1>
            </Col>
            <Col xs={10} className="m-auto">
              <div className="separator"></div>
            </Col>
            <Col xs={12} className=" mb-0 mb-md-5">
              <h2 className="text text--grey">
                Tire todas as suas dúvidas sobre a terapêutica canabinóide e
                entenda como ela pode mudar vidas
              </h2>
            </Col>
          </Row>
        </Container>
      </SectionFaq>
      <section>
        <Container>
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-center">
                <Button
                  className={`btn lg radius left button-font-size-20 ${
                    faq === 'patient' ? 'primary' : 'outline-primary'
                  }`}
                  onClick={() => setFaq('patient')}
                >
                  Para paciente
                </Button>
                <Button
                  className={`btn lg radius right button-font-size-20 ${
                    faq === 'physician' ? 'primary' : 'outline-primary'
                  }`}
                  onClick={() => setFaq('physician')}
                >
                  Para médico
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {faq == 'patient' && (
        <SectionQuestions>
          <Container>
            <Row>
              <Col xs={12}>
                <h3 className="text text--sm">Para Pacientes</h3>
              </Col>
              <Col xs={12} className="my-5">
                <Accordion>
                  <Accordion.Toggle as={Question} eventKey="0">
                    <h4 className="text">O que é a cannabis?</h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="p-3">
                      <p className="answer">
                        A cannabis é uma planta herbácea, de origem asiática e
                        popular no mundo todo. Durante a história, ela foi
                        utilizada para as mais diversas finalidades, como em
                        diferentes religiões, na indústria têxtil e no tema aqui
                        da Dr. Cannabis: na medicina.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="1">
                    <h4 className="text">O que são canabinoides?</h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <div className="p-3">
                      <p className="answer">
                        Canabinoides são substâncias presentes na cannabis. De
                        forma resumida, eles são os componentes medicinais da
                        planta. Os mais conhecidos e estudados hoje são o
                        canabidiol (CBD) - popular por seu efeito anticonvulsivo
                        - e o tetrahidrocanabinol (THC) - principal componente
                        psicoativo da cannabis e responsável por efeitos como
                        alívio de náuseas e dores.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="2">
                    <h4 className="text">
                      A cannabis medicinal é indicada para minha doença?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <div className="p-3">
                      <p className="answer">
                        O tratamento médico com cannabis é possível, de acordo
                        com a legislação vigente. A cannabis medicinal pode ser
                        uma aliada para diferentes patologias e sintomas e não
                        são raros os casos onde ele proporciona uma melhora
                        impressionante na qualidade de vida de milhares de
                        pessoas. Há incontáveis relatos positivos de pacientes
                        diagnosticados com epilepsia, esclerose múltipla, dor
                        crônica e muitas outras condições de saúde. Sendo assim,
                        você pode se perguntar: usar o canabidiol (CBD) e os
                        extratos da cannabis em produtos serve para o que sinto?
                        Fazer um tratamento médico com cannabis é para mim? A
                        resposta inicial é sim, mas é importante e essencial ter
                        o acompanhamento médico de um profissional que avaliará
                        a melhor forma de a cannabis ser aliada do seu
                        tratamento e alívio de sintomas. Aqui neste link você
                        pode ter mais informações sobre a cannabis medicinal e
                        seu tratamento.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="3">
                    <h4 className="text">
                      O uso terapêutico da cannabis é legalizado no Brasil?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3">
                    <div className="p-3">
                      <p className="answer">
                        O que existe hoje é uma regra de exceção, ou seja,
                        devido a falta de regulamentação específica a respeito
                        da cannabis medicinal, os casos são analisados e
                        aprovados um a um pela Anvisa. Para obter a Autorização
                        de Importação Excepcional de produtos com canabinoides -
                        com o respaldo da{' '}
                        <a
                          className="mx-2 text-info"
                          href="https://www.in.gov.br/web/dou/-/resolucao-rdc-n-335-de-24-de-janeiro-de-2020-239866072?_ga=2.62786857.460281296.1603211827-1696232220.1603211827"
                        >
                          RDC 335/2020,
                        </a>
                        você precisa de prescrição médica e realizar o cadastro
                        no Portal do Cidadão. A Dr. Cannabis te auxilia durante
                        todo o processo: desde a busca por um médico prescritor
                        de cannabis medicinal até a cotação dos produtos
                        indicados pelo profissional da saúde.
                        <a
                          className="mx-2 text-info"
                          href="https://drcannabis.com.br/cadastro"
                        >
                          Faça o seu cadastro
                        </a>
                        ou
                        <a
                          className="mx-2 text-info"
                          href="https://drcannabis.com.br/login"
                        >
                          acesse o seu perfil
                        </a>
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="4">
                    <h4 className="text">
                      Quais são os serviços da Dr. Cannabis?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4">
                    <div className="p-3">
                      <p className="answer">
                        Aqui na Dr. Cannabis você tem uma plataforma gratuita
                        que propicia e facilita o acesso de forma legal à
                        cannabis medicinal no Brasil. Você encontra um médico
                        prescritor e pode agendar sua consulta presencial ou
                        online! Se você já tem a prescrição emitida por seu
                        médico: conte conosco para dar andamento no seu processo
                        junto à Anvisa. Se ainda não tem um perfil na
                        plataforma,
                        <a
                          className="mx-2 text-info"
                          href="https://drcannabis.com.br/cadastro"
                        >
                          Cadastre-se
                        </a>
                        gratuitamente ou
                        <a
                          className="mx-2 text-info"
                          href="https://drcannabis.com.br/login"
                        >
                          acesse seu perfil
                        </a>
                        e, se preferir, envie um e-mail para o nosso atendimento
                        e vamos te ajudar! O suporte e apoio ao paciente é
                        gratuito.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="5">
                    <h4 className="text">
                      Como a Dr. Cannabis pode me ajudar a acessar cannabis
                      medicinal?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="5">
                    <div className="p-3">
                      <p className="answer">
                        Queremos te ajudar a cuidar da sua saúde e ter mais
                        qualidade de vida. Por isso, conectamos você a médicos
                        que podem prescrever produtos com canabinoides de forma
                        legal, responsável e comprometida.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="6">
                    <h4 className="text">
                      Para se tratar com cannabis medicinal é preciso fumar?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="6">
                    <div className="p-3">
                      <p className="answer">
                        Não é preciso fumar. Existem dezenas de possibilidades
                        de consumo de medicamentos à base de cannabis. A
                        administração de canabinoides pode ser feita via oral -
                        comprimidos, óleos e sprays - ou aplicação tópica -
                        pomadas e cremes. Há, ainda, um número crescente de
                        pesquisas sobre novas formas de administração, como
                        colírios e patches (adesivos) para dor, sendo estudadas
                        e lançadas. Porém, é importante lembrar que somente um
                        médico especialista poderá te orientar sobre a melhor
                        opção de acordo com a sua condição. Cadastre-se
                        gratuitamente ou Consulte um dos profissionais da nossa
                        rede.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="7">
                    <h4 className="text">
                      Posso usar cannabis sem indicação médica?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="7">
                    <div className="p-3">
                      <p className="answer">
                        A utilização dos derivados da cannabis, assim como de
                        qualquer outro tratamento, deve sempre ser indicada e
                        acompanhada por um profissional da saúde. O uso
                        terapêutico da cannabis precisa ser adequado ao
                        organismo e à condição a ser tratada. Cadastre-se
                        gratuitamente ou Consulte um dos profissionais da nossa
                        rede.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="8">
                    <h4 className="text">
                      Qualquer médico pode receitar medicamentos com cannabis?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="8">
                    <div className="p-3">
                      <p className="answer">
                        Por parte da Anvisa não há restrição a especialidades
                        médicas que podem prescrever canabinoides. Com isso,
                        médicos brasileiros de diferentes especialidades tem
                        entendido e prescrito a cannabis medicinal como agente
                        aliada para tratar diferentes condições e sintomas. Você
                        pode conversar com o seu médico a respeito ou consultar
                        um dos profissionais da nossa rede.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="9">
                    <h4 className="text">
                      Como falar com o meu médico sobre cannabis medicinal?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="9">
                    <div className="p-3">
                      <p className="answer">
                        A terapêutica canábica tem beneficiado pacientes em todo
                        o mundo há milhares de anos. Ter mais uma opção de
                        tratamento de diferentes sintomas e condições é ganho em
                        qualidade de vida e bem-estar tanto do paciente, quanto
                        daqueles que convivem com ele. Assim, converse com seu
                        médico sobre essa possibilidade. Convide-o para
                        participar da Dr. Cannabis e ser pioneiro em uma forma
                        alternativa de aumentar a saúde de milhares de pessoas
                        em todo o Brasil.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="10">
                    <h4 className="text">
                      Posso indicar um médico da minha confiança para participar
                      da rede da Dr. Cannabis?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="10">
                    <div className="p-3">
                      <p className="answer">
                        Sim. Todo tratamento com cannabis medicinal começa com
                        uma prescrição médica. Por isso, é muito importante que
                        tenhamos cada vez mais médicos que conheçam a cannabis,
                        sua ação no corpo e as aplicações terapêuticas da
                        planta. Desta forma, aumentam-se as possibilidades de
                        tratamento e, consequentemente, as chances de maior
                        qualidade de vida.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="11">
                    <h4 className="text">
                      Não sou médico ou paciente, como posso usar a Dr.
                      Cannabis?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="11">
                    <div className="p-3">
                      <p className="answer">
                        Além de conectar médicos e pacientes, nós queremos levar
                        informação a pessoas interessadas em descobrir as
                        possibilidades da cannabis medicinal, como você.
                        Estaremos sempre disponíveis em nossas redes sociais
                        para trocar informações e experiências. Conheça também o
                        nosso blog e compartilhe conhecimento!
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="12">
                    <h4 className="text">
                      Qual o custo para estar na plataforma Dr. Cannabis?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="12">
                    <div className="p-3">
                      <p className="answer">
                        O cadastro de pacientes e médicos na Dr. Cannabis é
                        gratuito.
                      </p>
                      <p className="answer">
                        Para os médicos, há serviços adicionais, como a
                        telemedicina, que podem ser adquiridos à parte com valor
                        adicional cobrado mensalmente.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="13">
                    <h4 className="text">
                      A Dr. Cannabis comercializa cannabis ou derivados?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="13">
                    <div className="p-3">
                      <p className="answer">
                        Não comercializamos nenhum derivado da cannabis ou a
                        planta in natura e não temos contato com a cannabis.
                        <a
                          className="mx-2 text-info"
                          href="https://drcannabis.com.br/sobre"
                        >
                          Conheça mais sobre a Dr. Cannabis
                        </a>
                      </p>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </SectionQuestions>
      )}
      {faq == 'physician' && (
        <SectionQuestions>
          <Container>
            <Row>
              <Col xs={12}>
                <h3 className="text text--sm">Para Médicos</h3>
              </Col>
              <Col xs={12} className="my-5">
                <Accordion>
                  <Accordion.Toggle as={Question} eventKey="0">
                    <h4 className="text">
                      Sou médico, como a Dr. Cannabis me auxilia junto aos
                      pacientes?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="p-3">
                      <p className="answer">
                        Somos uma plataforma que facilita o acesso das pessoas à
                        qualidade de vida e ao bem-estar por meio do uso
                        terapêutico da cannabis. Conectamos médicos a pacientes
                        em um ambiente de uso gratuito. Queremos ajudar os
                        brasileiros a cuidarem da saúde e terem qualidade de
                        vida.
                      </p>
                      <p className="answer">
                        Se você é médico, cadastre-se gratuitamente para que a
                        Dr. Cannabis o coloque em contato com pacientes em sua
                        região e seu consultório tenha maior visibilidade. Conte
                        conosco para o acolhimento dos pacientes no processo
                        Anvisa.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="1">
                    <h4 className="text">
                      Terei custos para participar da plataforma Dr. Cannabis?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <div className="p-3">
                      <p className="answer">
                        Médicos e pacientes não têm custos ao se cadastrarem na
                        plataforma Dr. Cannabis. O suporte de conexão entre
                        médicos e pacientes, assim como o acolhimento para o
                        processo Anvisa é gratuito.
                      </p>
                      <p className="answer">
                        Para médicos, o cadastro é gratuito e você, enquanto
                        profissional, terá acesso a conteúdos e o seu
                        consultório divulgado. Caso queira, poderá contratar
                        serviços adicionais, como a telemedicina, com valores
                        diferenciados e mensais.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="11">
                    <h4 className="text">
                      Não sou médico ou paciente, como posso usar a Dr.
                      Cannabis?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="11">
                    <div className="p-3">
                      <p className="answer">
                        Além de conectar médicos e pacientes, nós queremos levar
                        informação a pessoas interessadas em descobrir as
                        possibilidades da cannabis medicinal, como você.
                        Estaremos sempre disponíveis em nossas redes sociais
                        para trocar informações e experiências. Conheça também o
                        nosso blog e compartilhe conhecimento!
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="12">
                    <h4 className="text">
                      Qual o custo para estar na plataforma Dr. Cannabis?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="12">
                    <div className="p-3">
                      <p className="answer">
                        O cadastro de pacientes e médicos na Dr. Cannabis é
                        gratuito.
                      </p>
                      <p className="answer">
                        Para os médicos, há serviços adicionais, como a
                        telemedicina, que podem ser adquiridos à parte com valor
                        adicional cobrado mensalmente.
                      </p>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Question} eventKey="13">
                    <h4 className="text">
                      A Dr. Cannabis comercializa cannabis ou derivados?
                    </h4>
                    <svg className="icon mr-4">
                      <use xlinkHref={`${Sprites}#icon-plus`} />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="13">
                    <div className="p-3">
                      <p className="answer">
                        Não comercializamos nenhum derivado da cannabis ou a
                        planta in natura e não temos contato com a cannabis.
                        <a
                          className="mx-2 text-info"
                          href="https://drcannabis.com.br/sobre"
                        >
                          Conheça mais sobre a Dr. Cannabis
                        </a>
                      </p>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </SectionQuestions>
      )}
    </>
  )
}
