import React, { useState, useEffect } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import Button from '~/components/Button'
import { Input } from '~/components/Form'
import api from '~/services/api'

export default function AddAddress({ show, onHide, addressId }) {
  const { register, handleSubmit, errors, setValue } = useForm()
  const [loading, setLoading] = useState(false)

  const submitAddress = async data => {
    setLoading(true)
    data.country = 'Brasil'
    if (addressId) await api.put(`/addresses/${addressId}`, data.address)
    else await api.post('/addresses', data.address)
    setLoading(false)
    onHide()
  }

  const getAddress = async () => {
    if (addressId) {
      const res = await api.get(`/addresses/${addressId}`)
      setValue('address', res.data)
    }
  }

  useEffect(() => {
    if (show) {
      getAddress()
    }
  }, [show])

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Adicionar Endereço
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(submitAddress)}>
          <Row>
            <Col lg={6}>
              <Input
                type="text"
                label="CEP"
                name="address.cep"
                placeholder="00000-000"
                size="md"
                fluid
                className="my-2"
                error={errors.adresss?.cep}
                ref={register}
              />
            </Col>

            <Col lg={6}>
              <Input
                type="text"
                label="UF"
                name="address.state"
                placeholder=""
                size="md"
                fluid
                className="my-2"
                error={errors.state}
                ref={register}
              />
            </Col>

            <Col lg={6}>
              <Input
                type="text"
                label="Cidade"
                name="address.city"
                placeholder=""
                size="md"
                fluid
                className="my-2"
                error={errors.city}
                ref={register}
              />
            </Col>

            <Col lg={6}>
              <Input
                type="text"
                label="Bairro"
                name="address.district"
                placeholder=""
                size="md"
                fluid
                className="my-2"
                error={errors.district}
                ref={register}
              />
            </Col>

            <Col lg={8}>
              <Input
                type="text"
                label="Logradouro"
                name="address.street"
                placeholder=""
                size="md"
                fluid
                className="my-2"
                error={errors.street}
                ref={register}
              />
            </Col>

            <Col lg={4}>
              <Input
                type="text"
                label="Número"
                name="address.number"
                placeholder=""
                size="md"
                fluid
                className="my-2"
                error={errors.number}
                ref={register}
              />
            </Col>

            <Col lg={6}>
              <Input
                type="text"
                label="Complemento"
                name="address.complement"
                placeholder=""
                size="md"
                fluid
                className="my-2"
                error={errors.complement}
                ref={register}
              />
            </Col>
            <Col xs={12} className="d-flex justify-content-end">
              <Button className="primary" type="submit" disabled={loading}>
                Salvar
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  )
}
