import styled from 'styled-components'

export const Section = styled.div`
  hr {
    height: 3px;
    background-color: #1976d2;
    margin: 0.5rem 0;
  }
  h4 {
    font-size: 18px;
    color: #1976d2;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  a {
    color: #1976d2;
  }

  p {
    line-height: 1.5;
    text-align: justify;
  }
`
