import React, { useState, useEffect } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'

import { Container } from './styles'
import ParserPubMed from '~/helpers/ParserPubMed'
import api from '~/services/api'
import Button from '~/components/Button/index'
import { Input } from '~/components/Form/index'

function NewArticle({ show, handleClose }) {
  const { register, handleSubmit, errors } = useForm()
  const [loading, setLoading] = useState(false)

  const onSubmit = async data => {
    try {
      setLoading(true)
      api.post('/articles', data)
      handleClose()
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Novo artigo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="formArticle" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Input
                type="text"
                label="ID PubMed"
                name="pubmed_id"
                size="md"
                fluid
                className="my-3"
                error={errors.pubmed_id}
                ref={register}
              />
            </Col>
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          form="formArticle"
          type="submit"
          className="primary"
          disabled={loading}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default function Articles() {
  const [articlesId, setArticlesId] = useState()
  const [loading, setLoading] = useState(true)
  const [articles, setArticles] = useState([])
  const [showModal, setShowModal] = useState(false)

  const getArticlesId = async () => {
    setLoading(true)
    const res = await api.get('/articles')
    setArticlesId(res.data)
  }

  const getArticles = async () => {
    setLoading(true)
    if (articlesId) {
      const parsed = await ParserPubMed(articlesId)
      setArticles(parsed)
    }
    setLoading(false)
  }

  useEffect(() => {
    getArticlesId()
  }, [])

  useEffect(() => {
    getArticles()
  }, [articlesId])

  const modalClose = async () => {
    await getArticlesId()
    setShowModal(false)
  }

  const deleteArticle = async id => {
    setLoading(true)
    await api.delete(`/articles/${id}`)
    await getArticlesId()
    setLoading(false)
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h2>Artigos</h2>
          </Col>
          <Col className="d-flex flex-row-reverse">
            <Button className="primary mr-3" onClick={() => setShowModal(true)}>
              Novo Artigo
            </Button>
          </Col>
        </Row>
        {loading && (
          <div className="skeleton my-3">
            <p>
              <Skeleton count={10} />
            </p>
          </div>
        )}
        <Row className="my-3">
          {articles.length > 0 &&
            !loading &&
            articles.map(article => (
              <Col xl={12} key={article.id}>
                <div className="d-flex justify-content-between align-items-center item">
                  <p
                    dangerouslySetInnerHTML={{ __html: article.ArticleTitle }}
                  ></p>
                  <Button onClick={() => deleteArticle(article.id)}>
                    excluir
                  </Button>
                </div>
              </Col>
            ))}
        </Row>
      </Container>

      <NewArticle show={showModal} handleClose={modalClose} />
    </>
  )
}
