import check from './Checkbox'
import input from './Input'
import select from './Select'
import textarea from './TextArea'
import autocomplete from './AutoComplete'
import radio from './Radio'

export const Checkbox = check
export const Input = input
export const Select = select
export const TextArea = textarea
export const AutoComplete = autocomplete
export const Radio = radio
