import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

import api from '~/services/api'
import ParserPubMed from '~/helpers/ParserPubMed'
import { Container } from './styles'
import Article from '~/components/Article'

export default function Articles() {
  const [loading, setLoading] = useState(true)
  const [articlesId, setArticlesId] = useState()
  const [articles, setArticles] = useState([])

  const getArticlesId = async () => {
    setLoading(true)
    const res = await api.get('/articles')
    setArticlesId(res.data)
  }

  useEffect(() => {
    getArticlesId()
  }, [])

  const getArticles = async () => {
    setLoading(true)
    if (articlesId) {
      const parsed = await ParserPubMed(articlesId)
      setArticles(parsed)
    }
    setLoading(false)
  }

  useEffect(() => {
    getArticles()
  }, [articlesId])

  return (
    <Container>
      {loading && (
        <div className="skeleton">
          <p>
            <Skeleton count={10} />
          </p>
        </div>
      )}
      {articles.length > 0 &&
        !loading &&
        articles.map(article => <Article article={article} key={article.id} />)}
    </Container>
  )
}
