import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

import { Creators as ErrorActions } from '~/store/ducks/error'

import api from '~/services/api'
import Button from '~/components/Button'
import { MedicineList } from './styles'
import Thumb from '~/components/Thumb'

export default function ModalNewOrder({ prescriptionId, onHide, show }) {
  const dispatch = useDispatch()
  const [prescription, setPrescription] = useState({})
  const [brandsOrdered, setBrandsOrdered] = useState([])
  const [brands, setBrands] = useState([])
  const [medicines, setMedicines] = useState([])

  const fetchPrescriptions = async () => {
    if (prescriptionId !== undefined) {
      const res = await api.get(`/prescriptions/${prescriptionId}`)
      setPrescription(res.data)
      setMedicines(res.data.medicines)

      setBrands(
        res.data.medicines
          .map(medicine => medicine.brand)
          .filter(
            (brand, index, self) =>
              index === self.findIndex(b => b.id === brand.id),
          ),
      )

      setBrandsOrdered(
        res.data.orders.map(order => {
          const ids = order.medicines.map(medicine => medicine.brand_id)
          return ids[0]
        }),
      )
    }
  }

  useEffect(() => {
    fetchPrescriptions()
  }, [show])

  const submitOrder = async brandId => {
    const medicinesOrder = medicines
      .filter(medicine => medicine.brand_id === brandId)
      .map(medicine => ({
        id: medicine.id,
        quantity: medicine.quantity ?? 1,
      }))

    const dataOrder = {
      prescription_id: prescription.id,
      address_id: prescription.patient.addresses[0]?.id,
      medicines: medicinesOrder,
    }

    try {
      await api.post('/orders', dataOrder)
      await fetchPrescriptions()
      toast.success('Pedido criado.')
    } catch (err) {
      if (err.response.status < 500)
        dispatch(ErrorActions.setError(err.response?.data.error.message))
      else dispatch(ErrorActions.setError('Erro ao criar pedido.'))
    }
  }

  const addQuantity = index => {
    const newMedicines = [...medicines]
    const max = newMedicines[index].pivot.quantity
      ? newMedicines[index].pivot.quantity
      : 99
    if (
      typeof newMedicines[index].quantity === 'undefined' ||
      newMedicines[index].quantity < max
    ) {
      newMedicines[index] = {
        ...newMedicines[index],
        quantity:
          typeof newMedicines[index].quantity != 'undefined'
            ? newMedicines[index].quantity + 1
            : 2,
      }
      setMedicines(newMedicines)
    }
  }

  const subQuantity = index => {
    const newMedicines = [...medicines]
    if (
      typeof newMedicines[index].quantity === 'undefined' ||
      newMedicines[index].quantity > 1
    ) {
      newMedicines[index] = {
        ...newMedicines[index],
        quantity:
          typeof newMedicines[index].quantity != 'undefined'
            ? newMedicines[index].quantity - 1
            : 1,
      }
      setMedicines(newMedicines)
    }
  }

  let total = []

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Criar pedido
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {medicines?.length === 0 && (
          <>
            <p className="mb-3 mt-1">Selecione os produtos primeiro.</p>
          </>
        )}
        {brands?.length > 0 &&
          brands.map((brand, brandIdx) => {
            total[brandIdx] = 0
            return (
              <>
                <h5>{brand.name}</h5>
                <MedicineList className="my-3">
                  <div>
                    <div className="d-flex">
                      <div className="thumb" style={{ width: '100px' }}></div>
                      <div className="infos">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="name info"></div>
                          <div>
                            <b>Quantidade</b>
                          </div>
                          <div className="d-flex flex-column align-items-center mr-3">
                            <b className="price info">Preço</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {medicines.map((medicine, medIdx) => {
                    if (medicine.brand.id === brand.id) {
                      total[brandIdx] =
                        parseInt(total[brandIdx] + medicine.price * (medicine.quantity ? medicine.quantity : 1))
                      if (brand.shipping)
                        total[brandIdx] += parseInt(brand.shipping)
                      total[brandIdx] = total[brandIdx].toFixed(2)
                    }
                    return (
                      medicine.brand.id === brand.id && (
                        <div key={medicine.id}>
                          <div className="medicine">
                            <div className="thumb">
                              <Thumb>
                                <div className="inner">
                                  <img
                                    src={medicine.photo?.url}
                                    alt="produto"
                                  />
                                </div>
                              </Thumb>
                            </div>
                            <div className="infos">
                              <div className="d-flex justify-content-between align-items-center w-100">
                                <div className="name info">
                                  <p>{medicine.name}</p>
                                </div>
                                <div className="d-flex align-items-center info">
                                  <div className="quantity">
                                    <Button
                                      className="sub"
                                      onClick={() => subQuantity(medIdx)}
                                    >
                                      -
                                    </Button>
                                    <span>{medicine.quantity ?? 1}</span>
                                    <Button
                                      className="add"
                                      onClick={() => addQuantity(medIdx)}
                                    >
                                      +
                                    </Button>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center mr-3">
                                  <p className="info price">
                                    {medicine.currency}{' '}
                                    {medicine.price *
                                      (medicine.quantity
                                        ? medicine.quantity
                                        : 1)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  })}
                  <hr className="divisor" />
                  <div className="total">
                    <div className="label">
                      <b>Frete*:</b>
                    </div>
                    <div className="value">
                      <p>
                        {brand.currency} {brand.shipping || '-'}
                      </p>
                    </div>
                  </div>
                  <div className="total">
                    <div className="label">
                      <b>Total: </b>
                    </div>
                    <div className="value">
                      <p>
                        {brand.currency} {total[brandIdx]}
                      </p>
                    </div>
                  </div>
                </MedicineList>
                <Row>
                  <Col className="d-flex align-items-end">
                    <small>
                      *O frete pode variar de acordo com a quantidade comprada.
                    </small>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      className="primary"
                      onClick={() => submitOrder(brand.id)}
                      disabled={brandsOrdered.includes(brand.id)}
                    >
                      {brandsOrdered.includes(brand.id)
                        ? 'Pedido criado'
                        : 'Fazer pedido'}
                    </Button>
                  </Col>
                </Row>
                <hr className="divisor" />
              </>
            )
          })}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  )
}
