import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'

import { Container } from './styles'
import Patient from './PatientCard'
import api from '~/services/api'

export default function Patients() {
  const [patients, setPatients] = useState([])
  const [loading, setLoading] = useState(false)

  const getPatients = async () => {
    setLoading(true)
    const res = await api.get('/patients/list')
    setPatients(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getPatients()
  }, [])

  const handlePageChange = page => {
    getPatients()
  }

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="text--rm">Pacientes</h2>
        </Col>
      </Row>
      <Row>
        {loading && (
          <Col className="d-flex justify-content-center">
            <Spinner animation="grow" />
          </Col>
        )}
        {!loading &&
          (patients.length ? (
            patients.map(patient => (
              <Col md={4} sm={12} className="my-3" key={patient.id}>
                <Link to={`/medico/pacientes/${patient.id}`}>
                  <Patient data={patient} />
                </Link>
              </Col>
            ))
          ) : (
            <Col className="d-flex w-100 justify-content-center m-5">
              <Alert variant="warning p-3">
                {' '}
                Você ainda não tem pacientes ativos. Assim que fizer uma
                prescrição ele estará listado aqui.
              </Alert>
            </Col>
          ))}
      </Row>
      {patients.length > 0 && (
        <Row>
          <Col className="d-flex justify-content-center mt-3">
            <Pagination
              activePage={patients.page}
              itemsCountPerPage={patients.perPage}
              totalItemsCount={patients.total ? patients.total : 0}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          </Col>
        </Row>
      )}
    </Container>
  )
}
