import { call, put } from 'redux-saga/effects'
import api from '~/services/api'

import { Creators as MedicinesActions } from '~/store/ducks/medicines'
import { Creators as ErrorActions } from '~/store/ducks/error'

export function* medicines(action) {
  try {
    const response = yield call(api.get, `/medicines?name=${action.name}`)
    yield put(MedicinesActions.medicinesSuccess(response.data))
  } catch (error) {
    if (error.response) {
      if (error.response.data.error) {
        yield put(ErrorActions.setError(error.response.data.error))
      } else if (error.response.data) {
        yield put(ErrorActions.setError(error.response.data))
      }
    } else {
      yield put(ErrorActions.setError(error))
    }
    yield put(MedicinesActions.medicinesError())
  }
}
