import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import api from '~/services/api'
import { MedicineList } from './styles'
import Button from '~/components/Button'
import { Input } from '~/components/Form'

export default function ModalMedicine({ prescriptionId, onHide, show }) {
  const { register, watch } = useForm()
  const [prescription, setPrescription] = useState({})
  const [medicines, setMedicines] = useState([])
  const search = watch('search')

  const fetchPrescriptions = async () => {
    const res = await api.get(`/prescriptions/${prescriptionId}`)
    setPrescription(res.data)
  }

  // useEffect(() => {
  //   fetchPrescriptions()
  // }, [show])

  const fetchMedicines = async () => {
    const res = await api.get(`/medicines?name=${search}`)
    setMedicines(res.data)
  }

  useEffect(() => {
    fetchMedicines()
  }, [search])

  const addMedicine = async id => {
    const medicinesId = prescription.medicines?.map(presc => presc.id) ?? []
    medicinesId.push(id)
    const data = {
      medicines: medicinesId.map(meds => ({ id: meds })),
    }
    await api.put(`/prescriptions/${prescriptionId}`, data)
    fetchPrescriptions()
  }

  const removeMedicine = async id => {
    let medicinesId = prescription.medicines?.map(presc => presc.id) ?? []
    medicinesId = medicinesId.filter(med => med !== id)
    const data = {
      medicines: medicinesId.map(meds => ({ id: meds })),
    }
    await api.put(`/prescriptions/${prescriptionId}`, data)
    fetchPrescriptions()
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Selecionar produtos
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Input
              type="text"
              name="search"
              placeholder="Buscar"
              size="md"
              icon="icon-search"
              ref={register}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <MedicineList className="my-3">
              {prescription.medicines?.map(medicine => (
                <div className="medicine">
                  <div className="name">{medicine.name}</div>
                  <div>
                    <Button
                      className="danger"
                      onClick={() => removeMedicine(medicine.id)}
                    >
                      Remover
                    </Button>
                  </div>
                </div>
              ))}
            </MedicineList>
          </Col>
        </Row>
        <hr className="separator" />
        <Row>
          <Col>
            <MedicineList className="my-3">
              {medicines.data?.map(medicine => (
                <div className="medicine">
                  <div className="name">{medicine.name}</div>
                  <div>
                    <Button
                      className="primary"
                      onClick={() => addMedicine(medicine.id)}
                    >
                      Adicionar
                    </Button>
                  </div>
                </div>
              ))}
            </MedicineList>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  )
}
