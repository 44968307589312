import styled from 'styled-components'
import { ProgressBar } from 'react-bootstrap'

import { Variables as V } from '~/styles/global'

export const Container = styled.div`
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  .title {
    .notify {
      color: #ba1a00;
    }

    .checked {
      color: #71AA74;
    }
  }

  .avatar {
    width: 135px;
    height: 135px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  .desc {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .file {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    img {
      width: 60px;
    }
    p {
      margin-top: 0.5rem;
      text-align: center;
    }
  }

  .footer {
    border-top: 1px solid rgba(0, 0, 0, 0.125);

    .steps {
      padding: 0.75rem 1.25rem;
    }
  }
`

export const Progress = styled(ProgressBar)`
  height: 0.5rem;
  background-color: transparent;
  border-radius: 0;

  .progress-bar {
    background-color: ${V.colorPrimary};
  }
`
