import React from 'react'
import NumberFormat from 'react-number-format'
import PhysicianImg from '~/assets/images/physician-default.png'
import { formatDate, formatText } from '~/shared/utils'
import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function Physician({ data, phone, email, width, ...rest }) {
  const profile = data.user?.profile
  const prefix = profile?.gender === 'm' ? 'Dr.' : 'Dra.'

  const getClass = status => {
    switch (status) {
      case 'approved':
        return 'fa fa-check-circle physician-card-icon-vector mt-1 mr-1 text-white'
      case 'disapproved':
        return 'fa fa-times-circle physician-card-icon-vector mt-1 mr-1 text-white'
      case 'pending':
        return 'fa fa-question-circle physician-card-icon-vector mt-1 mr-1 text-white'
      default:
        return ''
    }
  }

  const getContainerClass = status => {
    switch (status) {
      case 'approved':
        return 'physician-card-container-approve physician-card-success'
      case 'disapproved':
        return 'physician-card-container-disapprove physician-card-disapprove'
      case 'pending':
        return 'physician-card-container-pending physician-card-pending'
      default:
        return ''
    }
  }
  console.log(data)
  return (
    <div
      className={`card physician-card mt-4 ${getContainerClass(data.status)}`}
    >
      <div
        className={`${
          width > 575 ? 'row physician-card-avatar_div no-gutters' : ''
        }`}
      >
        <div className={`${width > 575 ? 'col-3 d-flex flex-wrap' : ''}`}>
          {profile.avatar ? (
            <RenderImg
              photo_id={profile?.avatar?.id}
              photo_uuid={profile?.avatar?.uuidUrl}
              isPrivate={profile?.avatar.private}
              classNameImg={`${
                width > 575 ? 'mx-auto physician-card-avatar' : 'img-fluid'
              }`}
              alt="Avatar"
            />
          ) : (
            <img
              className={`${
                width > 575 ? 'mx-auto physician-card-avatar' : 'w-100'
              }`}
              src={PhysicianImg}
              alt="Avatar"
            />
          )}
        </div>
        <div className={width > 575 && 'col-8'}>
          <div className={`card-body ${width > 575 && 'pl-0'}`}>
            <h4 className="card-title">
              {prefix} {profile?.full_name}{' '}
            </h4>
            <div
              className={`${width > 575 && 'd-flex justify-content-between'}`}
            >
              <div>
                <p className="card-text">
                  {profile.phone_number && (
                    <>
                      <i className="fa fa-phone mx-2" />
                      <NumberFormat
                        value={profile?.phone_number}
                        displayType={'text'}
                        format="(##) #####-####"
                      />
                    </>
                  )}
                </p>
                <p className="card-text">
                  {profile.mobile_number && (
                    <div className="d-flex mt-1">
                      <i className="fa fa-mobile-alt mt-2 d-block mx-2" />
                      <NumberFormat
                        value={profile?.mobile_number}
                        displayType={'text'}
                        format="(##) #####-####"
                        className="mt-2"
                      />
                    </div>
                  )}
                </p>
                <p className="card-text">
                  {data.user.email && (
                    <div className="d-flex mt-1">
                      <i className="fa fa-envelope mt-2 d-block mx-2" />
                      <span className="mt-2">{data.user.email}</span>
                    </div>
                  )}
                </p>
                <p className="card-text">
                  {data.user.addresses.length > 0 && (
                    <div className="d-flex mt-1">
                      <i className="fa fa-map-marker-alt mt-2 d-block mx-2" />
                      <span className="mt-2">
                        {data.user.addresses[0].city}
                      </span>
                    </div>
                  )}
                </p>
                <p className="card-text">
                  {data.crm && (
                    <div className="d-flex mt-1">
                      <i className="fas fa-id-badge mt-2  d-block mx-2" />
                      <span className="mt-2">CRM: {data.crm}</span>
                    </div>
                  )}
                </p>
                <p className="card-text">
                  {data.price && (
                    <div className="d-flex mt-1">
                      <i className="fas fa-dollar-sign mt-2 mx-2" />
                      <span className="mt-2">{data.price}</span>
                    </div>
                  )}
                </p>
              </div>
              <div className={width <= 575 ? 'mt-2' : ''}>
                <h4>
                  <span
                    className="badge badge-secondary w-100 text--black font-weight-normal"
                    style={{ 'background-color': '#72c7b6' }}
                  >
                    {formatText(data.specialty, 30)}
                  </span>
                </h4>
                {data.online_consultation && (
                  <h4>
                    <span
                      className="badge badge-secondary w-100 mt-1 text--black font-weight-normal"
                      style={{ 'background-color': '#72c7b6' }}
                    >
                      Faz Telemedicina
                    </span>
                  </h4>
                )}
              </div>
            </div>
          </div>
        </div>
        {width > 575 && (
          <div className="col-1 d-flex justify-content-end">
            <i className={getClass(data.status)} />
          </div>
        )}
      </div>
    </div>
  )
}
