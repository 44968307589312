import React from 'react'
import { Alert } from 'react-bootstrap'

import api from '~/services/api'
import { Container } from './styles'
import Button from '~/components/Button'

export default function GetPrescription({ next, data }) {
  const goSendPrescription = async () => {
    const prescription = {
      status: 'sendPrescription',
    }
    await api.put(`/prescriptions/${data.id}`, prescription)
    next('sendPrescription')
  }

  const goAskMedicine = async () => {
    const prescription = {
      status: 'askMedicine',
    }
    await api.put(`/prescriptions/${data.id}`, prescription)
    next('askMedicine')
  }

  return (
    <Container>
      <Alert variant="success">
        <p>
          Agora só falta você imprimir a prescrição, assinar e nos enviar de
          volta.
        </p>
      </Alert>
      <h4 className="mb-3 text-center">
        Veja aqui a prescrição de {data.patient?.profile?.full_name}
      </h4>
      <div className="d-flex justify-content-center mt-5 mb-3">
        <a href={`/template/prescricao/${data.id}`} target="_blank" rel="noopener">
          <Button className="white">Visualizar/Imprimir</Button>
        </a>
      </div>
      <div className="d-flex flex-column align-items-center mt-3 mb-5">
        <div>
          <Button className="primary disabled" disabled>
            Assinatura Digital
          </Button>
        </div>
        <small className="text--rm mt-2">Em breve</small>
      </div>
      <div className="d-flex justify-content-between">
        <Button className="primary" onClick={goAskMedicine}>
          Voltar
        </Button>
        <Button className="primary" onClick={goSendPrescription}>
          Subir Prescrição
        </Button>
      </div>
    </Container>
  )
}
