import styled from 'styled-components'

export const Container = styled.div`
  .title {
    h2 {
      font-family: sarabunMedium;
      font-size: 30px;
      line-height: 45px;
      color: #5daa60;
    }
    .autors {
      color: #9b9b9b;
    }

    .date {
      color: #000000;
    }
  }

  .abstract {
    margin-top: 2rem;
    color: #000000;
    p {
      display: block;
      display: -webkit-box;
      margin: 0 auto;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`
