import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import Button from '~/components/Button'
import api from '~/services/api'
import { Container, ThumbsContainer } from './styles'
import Thumb from '~/components/Thumb'
import PhotoProfile from '~/assets/images/foto_perfil.svg'

export default function Previews({ callback }) {
  const [files, setFiles] = useState([])
  const sendFile = async file => {
    try {
      var formData = new FormData()
      formData.append('file', file)
      const res = await api.post('/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return res
    } catch (e) {
      console.error(e)
    }
  }
  const { getRootProps, getInputProps } = useDropzone({
    noKeyboard: true,
    multiple: false,
    onDrop: async acceptedFiles => {
      const res = await sendFile(acceptedFiles[0])
      if (res.data) {
        setFiles(
          acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        )
        callback(res.data.id)
      }
    },
  })

  let thumbs = (
    <Thumb>
      <div className="inner">
        <img src={PhotoProfile} alt="" />
      </div>
    </Thumb>
  )

  if (files.length) {
    thumbs = files.map(file => (
      <Thumb key={file.name}>
        <div className="inner">
          <img src={file.preview} alt="" />
        </div>
      </Thumb>
    ))
  }

  useEffect(() => {
    files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files])

  return (
    <Container>
      <ThumbsContainer>{thumbs}</ThumbsContainer>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Button
          type="button"
          className="blue sm m-3"
          onClick={e => e.preventDefault()}
        >
          Buscar
        </Button>
      </div>
    </Container>
  )
}
