import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;

  h4 {
    font-family: sarabunMedium;
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 0.3rem;
  }

  .comment {
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #ccc;

    .delete {
      color: #e60a0a;
    }
  }
`
