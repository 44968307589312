import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import { useForm } from 'react-hook-form'

import { Container } from './styles'
import Patient from './PatientCard'
import api from '~/services/api'
import { Input } from '~/components/Form'

export default function Patients() {
  const [patients, setPatients] = useState({ data: [] })
  const [loading, setLoading] = useState(false)
  const [searchParam, setSearchParam] = useState('')
  const { register, watch } = useForm()
  const search = watch('search')

  useEffect(()=>{setSearchParam(search)},[search])

  const getPatients = async (page, searchParam=null) => {
    setLoading(true)
    const res = await api.get(
      `/patients?page=${page || 1}&search=${searchParam || ''}`,
    )
    setPatients(res.data)
    setLoading(false)
  }

  const handlePageChange = page => {
    getPatients(page)
  }
  return (
    <Container>
      <Row>
        <Col>
          <h2 className="text--rm">Pacientes</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="my-3 d-flex">
          <Input
            type="text"
            name="search"
            placeholder="Nome ou Email ou CPF"
            size="md"
            icon="icon-search"
            ref={register}
          />
          <button
            className="btn btn-success ml-3"
            onClick={() => getPatients('', search)}
          >
            Buscar
          </button>
        </Col>
      </Row>
      <Row>
        {loading && (
          <Col className="d-flex justify-content-center">
            <Spinner animation="grow" />
          </Col>
        )}
        {!loading &&
          patients.data.map(patient => (
            <Col lg={6} className="my-3" key={patient.id}>
              <Link to={`/admin/pacientes/${patient.id}`}>
                <Patient data={patient} />
              </Link>
            </Col>
          ))}
      </Row>
      <Row>
        <Col className="d-flex justify-content-center mt-3">
          <Pagination
            activePage={patients.page}
            itemsCountPerPage={patients.perPage}
            totalItemsCount={patients.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </Col>
      </Row>
    </Container>
  )
}
